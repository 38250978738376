module.exports = {
    asc2dec: function (s) {
        var charSet = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
      
        let n = '';
        for (let i=0; i<s.length; i++) {
          let newN = charSet.indexOf(s[i]).toString().padStart(2, '0');
          // console.log();
          n += newN;
        }
      
        return n;
    },   
    dec2asc: function (n) {
        var charSet = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    
        let s = '';
        for (let i=0; i<n.length; i+=2) {
            let thisN = n.substr(i, 2);
            // console.log('index('+ i +') '+ thisN +' ('+ parseInt(thisN) +') - left over : '+ n);
            let newS = charSet[parseInt(thisN)];
            s += newS;
            // console.log(s);
        }
    
        return s;
    },
    status: function (status) {
        var result = 'Unknown';

        if (status === undefined) {
            result = 'Unknown'
        } else if (status < 10) {
            result = 'Created'
        } else if (status === 10) {
            result = 'Activated'
        } else if (status < 20) {
            result = 'Initial scan'
        } else if (status === 20) {
            result = 'Final scan'
        } else if (status === 22) {
            result = 'Recipient Notified'
        } else if (status === 24) {
            result = 'Attempted'
        } else if (status === 28) {
            result = 'Not Available (recipient)'
        } else if (status === 38) {
            result = 'Confirmed (recipient)'
        } else if (status === 42) {
            result = 'Not Mine (recipient)'
        } else if (status === 44) {
            result = 'Declined (recipient)'
        }
        
        return result;
      },
    isActive: function (status) {
        if (status >= 30) {
            return false;
        } else {
            return true;
        }
    }
}
