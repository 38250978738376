// import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react';

// import COLORS from '../utils/colors'
// import { card as cardStyle } from '../utils/styles'

import { Formik } from "formik";
import * as yup from "yup";
import { Form, Button, Row, Col, Alert, Tooltip, OverlayTrigger } from 'react-bootstrap';

import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CheckIcon from '@material-ui/icons/Check';
import PhoneIcon from '@material-ui/icons/Phone';

import { colorPens } from '../utils/colors';

const schema = yup.object({
    username: yup
        .string()
        .email("Username must be a valid email address")
        .required("E-mail address is a required field"),
    confirmationCode: yup
        .string()
        .matches(/^[0-9]+$/, "Must be only digits")
        .min(6, "Must be 6 or more characters")
        .max(12, "Must be 12 or less characters")
        .required("Please enter the verification code"),
    password: yup
        .string()
        .min(12)
        .required("Please enter your password")
        .matches(
            // /^(?=.{8,}$)(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[@$!%*#?&^]).*$/,
            // "Must Contain 8+ Characters, One Uppercase, One Lowercase, One Number and one special case Character"
            /^(?=.{12,}$)(?=.*?[a-z])(?=.*?[0-9]).*$/,
            "Must Contain 12+ Characters, One Lowercase Letter, and One Number"
        ),
    password_confirm: yup
        .string()
        .min(12)
        .required("Please enter your password again")
        .oneOf([yup.ref('password'), null], "Password does not match!"),

})

const ConfirmResetPasswordForm = (props) => {
    let { wipFormData } = props;

    return (
        <Formik
            validationSchema={schema}
            onSubmit={(values, actions) => {
                props.onSubmit(values);
            }}
            initialValues={{
                username: wipFormData.username !== undefined ? wipFormData.username : '',
                confirmationCode: '',
                password: wipFormData.password !== undefined ? wipFormData.password : '', 
                password_confirm: wipFormData.password_confirm !== undefined ? wipFormData.password_confirm : ''
            }}>
            {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                isValid,
                errors,
            }) => (
                <Row className="justify-content-center">
                    <Col md={8} style={{ backgroundColor: colorPens.light, border: '1px solid ' + colorPens.main, borderRadius: '5px' }}>
                        <Form noValidate onSubmit={handleSubmit}>
                            <Row className="justify-content-center">
                                <Col md={11}>
                                    <br />
                                    <h4>Reset your password</h4>
                                </Col>
                            </Row>
                            <Row className="justify-content-center">
                                <Col md={11}>
                                    <h5>Step 2/2 - Please enter the verification code we just sent to you and the new password.</h5><br />
                                </Col>
                            </Row>
                            {/* <Row className="justify-content-center">
                        <Col md={8}><Alert variant="primary">Step 1/3 - Please fill out the contact info and click <i>Sign Up</i> at the bottom</Alert></Col>
                    </Row> */}
                            <Form.Row className="justify-content-center">
                                <Form.Group as={Col} md="11" controlId="validationFormik101">
                                    <Form.Label>E-mail address</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="username"
                                        value={values.username}
                                        onChange={handleChange}
                                        isValid={touched.username && !errors.username}
                                        isInvalid={touched.username && errors.username}
                                        placeholder="Enter your email address"
                                        // readOnly
                                        plaintext
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.username}</Form.Control.Feedback>
                                </Form.Group>
                            </Form.Row>
                            <Form.Row className="justify-content-center">
                                <Form.Group as={Col} md="11" controlId="validationFormik102">
                                    <Form.Label>Verification code *</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="confirmationCode"
                                        value={values.confirmationCode}
                                        onChange={handleChange}
                                        isValid={touched.confirmationCode && !errors.confirmationCode}
                                        isInvalid={touched.confirmationCode && errors.confirmationCode}
                                        placeholder="Enter the verification code"
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.confirmationCode}</Form.Control.Feedback>
                                </Form.Group>
                            </Form.Row>
                            <OverlayTrigger placement="top" overlay={<Tooltip id="password-tooltip">Must Contain 12+ Characters, One Lowercase Letter, and One Number</Tooltip>}>
                                <Form.Row className="justify-content-center">
                                    <Form.Group as={Col} md="5" controlId="validationFormik103">
                                        <Form.Label>Password *</Form.Label>
                                        <Form.Control
                                            type="password"
                                            name="password"
                                            value={values.password}
                                            onChange={handleChange}
                                            isValid={touched.password && !errors.password}
                                            isInvalid={touched.password && errors.password}
                                            placeholder="Enter a new password"

                                        />
                                        <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md={1}></Form.Group>
                                    <Form.Group as={Col} md="5" controlId="validationFormik104">
                                        <Form.Label>Password (confirm) *</Form.Label>
                                        <Form.Control
                                            type="password"
                                            name="password_confirm"
                                            value={values.password_confirm}
                                            onChange={handleChange}
                                            isValid={touched.password_confirm && !errors.password_confirm}
                                            isInvalid={touched.password_confirm && errors.password_confirm}
                                            placeholder="Enter the password again"
                                        />
                                        <Form.Control.Feedback type="invalid">{errors.password_confirm}</Form.Control.Feedback>
                                    </Form.Group>
                                </Form.Row>
                            </OverlayTrigger>
                            <br />
                            <Row className="justify-content-center">
                                <Col md={8}><Button variant="link" size="sm" onClick={() => {window.location.href='/account/resetPassword'}}>Go Back to request a new code</Button></Col>
                                <Col md={2}><Button variant="primary" type="submit">Confirm</Button></Col>
                            </Row>
                            <br />
                        </Form>

                    </Col>
                </Row>
            )}
        </Formik>
    );
}

export default ConfirmResetPasswordForm;