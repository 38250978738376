// import PropTypes from 'prop-types'
import React, { useState } from 'react';

// import COLORS from '../utils/colors'
// import { card as cardStyle } from '../utils/styles'

import { Formik } from "formik";
import * as yup from "yup";
import { Form, Button, Row, Col, Alert, Tooltip, OverlayTrigger } from 'react-bootstrap';
// import { useAlert } from 'react-alert'

import TinyFlagReact from "tiny-flag-react";
// import PhoneFormatter from "react-headless-phone-input";
import PhoneFormatter from "react-headless-phone-input/lazy";

import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CheckIcon from '@material-ui/icons/Check';
import PhoneIcon from '@material-ui/icons/Phone';

import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';


// import { Auth } from 'aws-amplify';

// yup.addMethod(yup.string, "phone", function (messageError = 'Phone number is not valid') {
//     const phoneRegExp = /^\+(?:[0-9] ?){6,14}[0-9]$/;
//     return this.test('phone', messageError, value => {
//         if (value && value.length > 0) {
//             return phoneRegExp.test(value)
//         }
//         return true
//     })
// })

import { colorPens } from '../utils/colors';

const schema = yup.object({
    username: yup
        .string()
        .email("Username must be a valid email address")
        .required("E-mail address is a required field"),
    password: yup
        .string()
        .min(12)
        .required("Please enter your password")
        .matches(
            // /^(?=.{8,}$)(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[@$!%*#?&^]).*$/,
            // "Must Contain 8+ Characters, One Uppercase, One Lowercase, One Number and one special case Character"
            /^(?=.{12,}$)(?=.*?[a-z])(?=.*?[0-9]).*$/,
            "Must Contain 12+ Characters, One Lowercase Letter, and One Number"
        ),
    password_confirm: yup
        .string()
        .min(12)
        .required("Please enter your password again")
        .oneOf([yup.ref('password'), null], "Password does not match!"),
    first_name: yup
        .string()
        .max(64)
        .required("First name is a required field"),
    last_name: yup
        .string()
        .max(64)
        .required("Last name is a required field"),
    company_name: yup
        .string()
        .max(128),
    consent: yup
        .bool()
        .oneOf([true], 'To use 11Klear, the customer must consent to our privacy policy and terms of use. To opt out, please request to delete your account.'),
    // phone_number: yup
    //     .string()
    //     .phone()
})

const CreateAccountForm = (props) => {
    let { campaignName, campaignIdentifier, wipFormData } = props;

    const [e164, setE164] = useState(wipFormData.phone_number !== undefined ? wipFormData.phone_number : "");
    const [e164Valid, setE164Valid] = useState(true);
    const [partnerConsented, setPartnerConsented] = useState(null);

    const [country, setCountry] = useState(null);
    const [region, setRegion] = useState(null);

    const [available, setAvailable] = useState(false);

    const handleConfirmAvailability = () => {
        // console.log(`country= ${country}, region= ${region}`);

        let euMemberStates = [
            'AT', 'BE', 'BG', 'HR', 'CY', 'CZ', 'DK', 'EE', 'FI', 'FR', 'DE', 'GR', 'HU', 'IE', 'IT', 'LV', 'LT', 'LU', 'MT', 'NL', 'PL', 'PT', 'RO', 'SK', 'SI', 'ES', 'SE'
        ]

        if (country === null || region === null) {
            alert('Please select both the country and the region to proceed.');
            return false;
        }

        if (country === 'CA' || country === 'US' || country === 'GB' || euMemberStates.includes(country)) {
            // Supported fully or partially, let's do it!
            setAvailable(true);
        } else {
            window.location.href = `/account/preorder/${campaignIdentifier}?c=${country}&r=${region}`;
        }
    }

    return (
        <Formik
            validationSchema={schema}
            onSubmit={(values, actions) => {
                if (campaignName === 'partners' && values["consent_partner"] !== true) {
                    setPartnerConsented(false);
                    return false;
                } else {
                    setPartnerConsented(true);
                }

                // window.alert('All GOOD! This is a drill, so we are not gonna do the actual sign up now. Thanks!');
                // return false;

                values["phone_number"] = e164;
                if (e164Valid) {
                    props.onSubmit(values);
                } else {
                    return false;
                }
            }}
            initialValues={{
                username: wipFormData.username !== undefined ? wipFormData.username : '',
                password: wipFormData.password !== undefined ? wipFormData.password : '',
                password_confirm: wipFormData.password_confirm !== undefined ? wipFormData.password_confirm : '',
                first_name: wipFormData.first_name !== undefined ? wipFormData.first_name : '',
                last_name: wipFormData.last_name !== undefined ? wipFormData.last_name : '',
                company_name: wipFormData.company_name !== undefined ? wipFormData.company_name : '',
                phone_number: wipFormData.phone_number !== undefined ? wipFormData.phone_number : '',
                consent_partner: wipFormData.consent_partner !== undefined ? wipFormData.consent_partner : false,
                consent_marketing: wipFormData.consent_marketing !== undefined ? wipFormData.consent_marketing : true,
                consent: wipFormData.consent !== undefined ? wipFormData.consent : false
            }}>
            {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                isValid,
                errors,
            }) => (
                <Row className="justify-content-center">
                    <Col md={8} style={{ backgroundColor: colorPens.light, border: '1px solid ' + colorPens.main, borderRadius: '5px' }}>
                        <Form noValidate onSubmit={handleSubmit}>
                            <Row className="justify-content-center">
                                <Col md={11}>
                                    <br />
                                    {campaignName === 'partners' ? (
                                        <h4>New Partner Account Registration</h4>
                                    ) : (
                                        <h4>New Account Registration</h4>
                                    )}
                                </Col>
                            </Row>
                            <Row className="justify-content-center">
                                <Col md={11}>
                                    <h5>Step 1/2 - Please fill out the contact info and click <i>Sign Up</i> at the bottom</h5><br />
                                </Col>
                            </Row>


                            {(available === false) ? (
                                <>
                                    <Row className="justify-content-center">
                                        <Col md={11}>
                                            Let's check the availability by location:<br /><br />
                                        </Col>
                                    </Row>
                                    <Row className="justify-content-center">
                                        <Col md={2} align="right">
                                            Country
                                        </Col>

                                        <Col md={6} align="left">
                                            <CountryDropdown
                                                value={country}
                                                priorityOptions={[
                                                    'CA', 'US',
                                                    'GB',
                                                    // EU member states
                                                    'AT', 'BE', 'BG', 'HR', 'CY', 'CZ', 'DK', 'EE', 'FI', 'FR', 'DE', 'GR', 'HU', 'IE', 'IT', 'LV', 'LT', 'LU', 'MT', 'NL', 'PL', 'PT', 'RO', 'SK', 'SI', 'ES', 'SE'
                                                ]}
                                                valueType="short"
                                                blacklist={[
                                                    'AQ'
                                                ]}
                                                onChange={(val) => {
                                                    setCountry(val);
                                                    setRegion(null);
                                                }} /><br /><br />
                                        </Col>
                                    </Row>
                                    <Row className="justify-content-center">
                                        <Col md={2} align="right">
                                            Region
                                        </Col>
                                        <Col md={6} align="left">
                                            <RegionDropdown
                                                country={country}
                                                value={region}
                                                countryValueType="short"
                                                onChange={(val) => setRegion(val)} />
                                        </Col>
                                    </Row>
                                    <Row className="justify-content-center">
                                        <Col md={9}>&nbsp;</Col>
                                        <Col md={2}><Button variant="success" onClick={handleConfirmAvailability}>Next</Button></Col>
                                    </Row>
                                    <br />

                                </>
                            ) : (
                                <>
                                    <Form.Row className="justify-content-center">
                                        <Form.Group as={Col} md="11">
                                            <Form.Label>Invitation Reference</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="campaign_identifier"
                                                value={campaignIdentifier}
                                                readOnly
                                            />
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row className="justify-content-center">
                                        <Form.Group as={Col} md="11" controlId="validationFormik101">
                                            <Form.Label>E-mail address *</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="username"
                                                value={values.username}
                                                onChange={handleChange}
                                                isValid={touched.username && !errors.username}
                                                isInvalid={touched.username && errors.username}
                                                placeholder="Enter your email address"
                                            />
                                            <Form.Control.Feedback type="invalid">{errors.username}</Form.Control.Feedback>
                                        </Form.Group>

                                    </Form.Row>

                                    <OverlayTrigger placement="top" overlay={<Tooltip id="password-tooltip">Must Contain 12+ Characters, One Lowercase Letter, and One Number</Tooltip>}>
                                        <Form.Row className="justify-content-center">
                                            <Form.Group as={Col} md="5" controlId="validationFormik102">
                                                <Form.Label>Password *</Form.Label>
                                                <Form.Control
                                                    type="password"
                                                    name="password"
                                                    value={values.password}
                                                    onChange={handleChange}
                                                    isValid={touched.password && !errors.password}
                                                    isInvalid={touched.password && errors.password}
                                                    placeholder="Enter a new password"

                                                />
                                                <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group as={Col} md={1}></Form.Group>
                                            <Form.Group as={Col} md="5" controlId="validationFormik103">
                                                <Form.Label>Password (confirm) *</Form.Label>
                                                <Form.Control
                                                    type="password"
                                                    name="password_confirm"
                                                    value={values.password_confirm}
                                                    onChange={handleChange}
                                                    isValid={touched.password_confirm && !errors.password_confirm}
                                                    isInvalid={touched.password_confirm && errors.password_confirm}
                                                    placeholder="Enter the password again"
                                                />
                                                <Form.Control.Feedback type="invalid">{errors.password_confirm}</Form.Control.Feedback>
                                            </Form.Group>
                                        </Form.Row>
                                    </OverlayTrigger>

                                    <Form.Row className="justify-content-center">
                                        <Form.Group as={Col} md="5" controlId="validationFormik104">
                                            <Form.Label>First name *</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="first_name"
                                                value={values.first_name}
                                                onChange={handleChange}
                                                isValid={touched.first_name && !errors.first_name}
                                                isInvalid={touched.first_name && errors.first_name}
                                                placeholder="First name"
                                            />
                                            <Form.Control.Feedback type="invalid">{errors.first_name}</Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md={1}></Form.Group>
                                        <Form.Group as={Col} md="5" controlId="validationFormik105">
                                            <Form.Label>Last name *</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="last_name"
                                                value={values.last_name}
                                                onChange={handleChange}
                                                isValid={touched.last_name && !errors.last_name}
                                                isInvalid={touched.last_name && errors.last_name}
                                                placeholder="Last name"
                                            />
                                            <Form.Control.Feedback type="invalid">{errors.last_name}</Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row className="justify-content-center">
                                        <Form.Group as={Col} md="11" controlId="validationFormik106">
                                            <Form.Label>Phone number</Form.Label>
                                            <PhoneFormatter defaultCountry="CA" value={e164} onChange={setE164}>
                                                {({ country, impossible, onBlur, onInputChange, inputValue }) => {
                                                    if (impossible) {
                                                        setE164Valid(false);
                                                    } else {
                                                        setE164Valid(true);
                                                    }

                                                    return (
                                                        <>
                                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                                <span
                                                                    style={{
                                                                        fontSize: "24px",
                                                                    }}>
                                                                    {country ? (
                                                                        <TinyFlagReact
                                                                            country={country}
                                                                            alt={country + " flag"}
                                                                            fallbackImageURL={`https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/${country}.svg`}
                                                                        />
                                                                    ) : (
                                                                        <PhoneIcon fontSize="inherit" />
                                                                    )}
                                                                </span>&nbsp;
                                                                <input
                                                                    type="tel"
                                                                    name="phone_number"
                                                                    value={inputValue}
                                                                    onBlur={(e) => {
                                                                        onBlur(e);
                                                                    }}
                                                                    onChange={(e) => {
                                                                        onInputChange(e.target.value);
                                                                    }}
                                                                />
                                                            </div>
                                                            {inputValue !== null && impossible ? (
                                                                <Col style={{ color: "red", fontSize: 13 }}>Must be a valid phone number <ErrorOutlineIcon fontSize="small" /></Col>
                                                            ) : null}
                                                        </>
                                                    )
                                                }}
                                            </PhoneFormatter>
                                            <Form.Control.Feedback type="invalid">{errors.phone_number}</Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row className="justify-content-center">
                                        <Form.Group as={Col} md="11" controlId="validationFormik107">
                                            <Form.Label>Company Name</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="company_name"
                                                value={values.company_name}
                                                onChange={handleChange}
                                                isValid={touched.company_name && !errors.company_name}
                                                isInvalid={touched.company_name && errors.company_name}
                                            />
                                            <Form.Control.Feedback type="invalid">{errors.company_name}</Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row className="justify-content-center">
                                        <Form.Group as={Col} md="11">
                                            <Form.Check
                                                required
                                                name="consent_marketing"
                                                // value={values.consent}
                                                checked={values.consent_marketing}
                                                label="Send me updates with tailored offers and productivity tips."
                                                onChange={handleChange}
                                                isInvalid={!!errors.consent_marketing}
                                                feedback={errors.consent_marketing}
                                                id="validationFormik106"
                                                feedbackTooltip
                                            />
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row className="justify-content-center">
                                        <Form.Group as={Col} md="11">
                                            <Form.Check
                                                required
                                                name="consent"
                                                // value={values.consent}
                                                checked={values.consent}
                                                label={(<>I read and consent to <a href="/terms" target="_blank">terms</a> and <a href='/policy' target="_blank">policies</a> of 11Klear. *</>)}
                                                onChange={handleChange}
                                                isInvalid={!!errors.consent}
                                                feedback={errors.consent}
                                                id="validationFormik107"
                                                feedbackTooltip
                                            />
                                        </Form.Group>
                                    </Form.Row>
                                    {campaignName === 'partners' ? (
                                        <Form.Row className="justify-content-center">
                                            <Form.Group as={Col} md="11">
                                                <Form.Check
                                                    required
                                                    name="consent_partner"
                                                    // value={values.consent}
                                                    checked={values.consent_partner}
                                                    label={(<>I read and consent to 11Klear <a href="/partners/terms" target="_blank">Partner Program Agreement</a>. *</>)}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.consent_partner}
                                                    feedback={errors.consent_partner}
                                                    id="validationFormik108"
                                                    feedbackTooltip
                                                />
                                            </Form.Group>
                                        </Form.Row>
                                    ) : null}
                                    {(campaignName === 'partners' && partnerConsented === false) ? (
                                        <Row className="justify-content-center">
                                            <Col md={11}><Alert variant="danger">To enroll in the Partner Program, you must consent to our 11Klear Partner Program Agreement.</Alert></Col>
                                        </Row>
                                    ) : null}
                                    <br />
                                    <Row className="justify-content-center">
                                        <Col md={9}><i>- required (*)</i></Col>
                                        <Col md={2}><Button variant="primary" type="submit">Sign Up</Button></Col>
                                    </Row>
                                    <br />
                                </>
                            )}
                        </Form>
                    </Col>
                </Row>
            )}
        </Formik>
    );
}

export default CreateAccountForm;