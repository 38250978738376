import React, { Component } from 'react';
// import logo from './logo.svg';
import './App.css';
// import { compileClientWithDependenciesTracked } from 'pug';

import Main from "./Main";
import { UserContext } from './components/userContext';
import { CookiesProvider } from 'react-cookie';

import { CookieBanner } from '@palmabit/react-cookie-law';
import { colorPens } from './utils/colors';

// import TagManager from 'react-gtm-module';

// const tagManagerArgs = {
//     gtmId: 'AW-10862967003'
//     // gtmId: 'GTM-599D7XB'
// }

// TagManager.initialize(tagManagerArgs)

class App extends Component {
    constructor(props) {
        super(props);

        this.toggleLoginState = () => {
            this.setState(state => ({
                isLoggedIn:
                    state.isLoggedIn === false ? true : false,
            }));
        }

        this.setLoginState = (isLoggedIn, asPartner) => {
            if (this.state.isLoggedIn !== isLoggedIn || this.state.asPartner !== asPartner) {
                this.setState({ isLoggedIn: isLoggedIn, asPartner: asPartner });
            }
        }

        this.setConsent = (value) => {
            if (this.state.withConsent !== value) {
                this.setState({ withConsent: value });
            }
        }

        this.setPartnerState = (value) => {
            if (this.state.asPartner !== value) {
                this.setState({ asPartner: value });
            }
        }

        // this.setCookieConsent = (value) => {
        //     if (this.state.withCookieConsent !== value) {
        //         this.setState({ withCookieConsent: value });
        //         console.log(`cookieConsent usercontext is set!`);
        //     }
        // }

        // this.setCookiePreferencesConsent = (value) => {
        //     if (this.state.withCookiePreferencesConsent !== value) {
        //         this.setState({ withCookiePreferencesConsent: value });
        //         console.log(`cookiePreferencesConsent usercontext is set!`);
        //     }
        // }

        // this.setCookieStatisticsConsent = (value) => {
        //     if (this.state.withCookieStatisticsConsent !== value) {
        //         this.setState({ withCookieStatisticsConsent: value });
        //         console.log(`cookieStatisticsConsent usercontext is set!`);
        //     }
        // }

        // this.setCookieMarketingConsent = (value) => {
        //     if (this.state.withCookieMarketingConsent !== value) {
        //         this.setState({ withCookieMarketingConsent: value });
        //         console.log(`cookieMarketingConsent usercontext is set!`);
        //     }
        // }

        this.state = {
            user: {},
            // task: null,
            isLoggedIn: false,
            withConsent: false,
            asPartner: false,
            // withCookieConsent: false,
            // withCookiePreferencesConsent: false,
            // withCookieStatisticsConsent: false,
            // withCookieMarketingConsent: false,
            toggleLoginState: this.toggleLoginState,
            setLoginState: this.setLoginState,
            setConsent: this.setConsent,
            setPartnerState: this.setPartnerState
            // setCookieConsent: this.setCookieConsent,
            // setCookiePreferencesConsent: this.setCookiePreferencesConsent,
            // setCookieStatisticsConsent: this.setCookieStatisticsConsent,
            // setCookieMarketingConsent: this.setCookieMarketingConsent,
        };

        this.login = this.login.bind(this);
        this.logout = this.logout.bind(this);
    }

    login(user) {
        this.setState({ user: user });
    }

    logout() {
        this.setState({ user: {} });
    }

    componentDidMount() {
        // Call our fetch function below once the component mounts
        // this.callBackendAPI()
        //   .then(res => {this.setState({ task: res.task }); console.log('received the response data = '+ JSON.stringify(res));})
        //   .catch(err => console.log(err));

        // get and set currently logged in user to state
    }

    // Fetches our GET route from the Express server. (Note the route we are fetching matches the GET route from server.js
    callBackendAPI = async () => {
        var data = { data: 'wow you made it!' }
        const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/v1/cooldown`, {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json'
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify(data)
        });
        const body = await response.json();

        if (response.status !== 200) {
            throw Error(body.message)
        }
        return body;
    };


    render() {
        // ref: update the style as needed
        // https://github.com/Palmabit-IT/react-cookie-law/blob/master/src/components/bannerStyle.js
        return (
            <CookiesProvider>
                <UserContext.Provider value={this.state}>
                    <Main />
                    {/* <CookieBanner
                        // disableStyle={true}
                        styles={{
                            dialog: {
                                backgroundColor: 'rgba(60, 60, 60, 0.85)',
                                position: 'fixed',
                                bottom: '0',
                                // height: '45px',
                                width: '100%',
                                padding: '10px',
                                zIndex: '10000'
                            },
                            message: { 
                                // fontWeight: 500,
                                color: colorPens.light
                            },
                            policy: {
                                color: colorPens.warning
                            },
                            optionLabel: {
                                color: colorPens.light,
                                padding: '1px 0 0 20px'
                            }
                        }}
                        message="This site or third-party tools used by this site make use of cookies necessary for the operation and useful for the purposes outlined in the cookie policy. To learn more, see the privacy policy. By accepting, you consent to the use of cookies."
                        policyLink="/policy/privacy"
                        wholeDomain={true}
                        preferencesDefaultChecked={false}
                        statisticsDefaultChecked={false}
                        marketingDefaultChecked={false}
                        // by default, these need to be deselected except the necessary cookies
                        // https://www.cookiebot.com/en/cookie-texts/#:~:text=A%20cookie%20text%20or%20cookie,you%20have%20a%20small%20website.
                    // onAccept={() => { this.state.setCookieConsent(true); }}
                    // onAcceptPreferences = {() => { this.state.setCookiePreferencesConsent(true); }}
                    // onAcceptStatistics = {() => { this.state.setCookieStatisticsConsent(true); }}
                    // onAcceptMarketing = {() => { this.state.setCookieMarketingConsent(true); }}
                    /> */}
                </UserContext.Provider>
            </CookiesProvider>
        );
    }
}

export default App;

// clientId 493451224388-uutj0aorqdgkhrgkf7roaih4cb2i31iu.apps.googleusercontent.com
// clientSecret OrS2tERpu4tGdKxZFX9AydWE