import React from 'react';
import ReactDOM from 'react-dom';
// import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
// import Main from './Main';

import WebFont from 'webfontloader';

import App from './App';
import * as serviceWorker from './serviceWorker';

import cognitoConfigs from './utils/cognitoConfigs';
import { Amplify } from 'aws-amplify';

import TagManager from 'react-gtm-module';

var Parse = require('parse');
Parse.initialize(process.env.REACT_APP_PARSE_APP_ID, {});  // APP_ID, JAVASCRIPT_KEY
Parse.serverURL = process.env.REACT_APP_PARSE_ENDPOINT;

// let tagManagerArgsGTM = {
//     gtmId: 'GTM-599D7XB',
// }
// TagManager.initialize(tagManagerArgsGTM)

// let tagManagerArgsAW = {
//     gtmId: 'AW-10862967003',
// }
// TagManager.initialize(tagManagerArgsAW)

const config = cognitoConfigs.getAmplifyConfig();
Amplify.configure({
    Auth: {
        mandatorySignIn: true,
        region: config.cognito.REGION,
        userPoolId: config.cognito.USER_POOL_ID,
        // identityPoolId: config.cognito.IDENTITY_POOL_ID,
        userPoolWebClientId: config.cognito.APP_CLIENT_ID
    },
    // Storage: {
    //     region: config.s3.REGION,
    //     bucket: config.s3.BUCKET,
    //     identityPoolId: config.cognito.IDENTITY_POOL_ID
    // },
    // API: {
    //     endpoints: [
    //         {
    //             name: "notes",
    //             endpoint: config.apiGateway.URL,
    //             region: config.apiGateway.REGION
    //         },
    //     ]
    // }
});

WebFont.load({
    google: {
        families: ['Lato:400,700,900', 'Exo:400,700', 'san-serif']
    }
})


ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
