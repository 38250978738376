import React, { Component } from 'react';
import './Main.css';
import { Nav, Navbar, NavDropdown } from 'react-bootstrap';

import {
    Route,
    // NavLink,
    // HashRouter,
    BrowserRouter,
    Switch
} from "react-router-dom";
import Home from "./Home";
import Policy from "./Policy";
import Terms from "./Terms";
// import FAQ from "./FAQ";
import Account from "./Account";
import Dashboard from "./Dashboard";

import Carbon from "./Carbon";
import Oxygen from "./Oxygen";
import { UserContext } from './components/userContext';

import Promotion from './Promotion';
import Usage from './Usage';
import Payment from './Payment';
import APIAccess from './APIAccess';
import Invite from './Invite';

import Products from "./Products";
import Pricing from "./Pricing";
import Developer from "./Developer";
import UserGuide from "./UserGuide";

// import Documents from "./Documents";
import Whitepaper from "./Whitepaper";

import Partner from "./Partner";
import PartnerDashboard from "./PartnerDashboard";

// import { CookieBanner } from '@palmabit/react-cookie-law';
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';

// import Logo192Transparent from './images/11Klear-Logo-192.png';

// const reload = () => window.location.reload();
// var Parse = require('parse');
import { Auth } from 'aws-amplify';
// import { colorPens } from './utils/colors';

// import TagManager from 'react-gtm-module';

// const tagManagerArgs = {
//     gtmId: 'AW-10862967003'
//     // gtmId: 'GTM-599D7XB'
// }

// TagManager.initialize(tagManagerArgs)

class Main extends Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    }

    // state = {
    //     currentMenuKey: null
    //     // logged: null
    // };

    constructor(props) {
        super(props);

        const { cookies } = props;
        // console.log(`props = ${JSON.stringify(props)}`);
        this.state = {
            currentMenuKey: null,
            rclConsentGiven: cookies.get('rcl_consent_given') || false,
            rclPreferencesGiven: cookies.get('rcl_preferences_consent') || false,
            rclStatisticsGiven: cookies.get('rcl_statistics_consent') || false,
            rclMarketingGiven: cookies.get('rcl_marketing_consent') || false,
        }
    }

    handleSelect(key) {
        this.setState({ currentMenuKey: key });

        // console.log('loggedIn? '+ Account.isLoggedIn);
    };

    async componentDidMount() {
        //   const alert = useAlert();
        //   alert.show('mounted!');
        // let currentUser = Parse.User.current();
        try {
            if (process.env.REACT_APP_CUSTOM_NODE_DEV === 'development' || process.env.REACT_APP_CUSTOM_NODE_DEV === 'testing') {
                // for debugging
                this.props.cookies.remove('rcl_consent_given');
                this.props.cookies.remove('rcl_preferences_consent');
                this.props.cookies.remove('rcl_statistics_consent');
                this.props.cookies.remove('rcl_marketing_consent');
            }

            let currentSession = await Auth.currentSession();
            if (currentSession) {
                // this.setState({ logged: true });
                this.context.setLoginState(true, parseInt(currentSession.idToken.payload['custom:consent_partner']) === 1);
                // this.context.setPartnerState();
                this.context.setConsent(parseInt(currentSession.idToken.payload['custom:consent']) === 1);
            }
        } catch (error) {
            this.context.setLoginState(false, false);
            // console.log(`No existing session found; ${error}`);
        }
    }

    render() {
        return (
            <BrowserRouter>
                <Switch>
                    <Route path="/carbon/*" component={Carbon} />
                    <Route path="/oxygen/:action/:id?/:ref1?/:ref2?" component={Oxygen} />
                    <div className="Main">
                        <Nav fill defaultActiveKey="/" activeKey={this.state.currentMenuKey} className="menu-bar" onSelect={(selectedKey) => this.handleSelect(selectedKey)}>
                            <Nav.Item>
                                <Nav.Link href="/" eventKey="h"><img alt="11Klear" src={process.env.PUBLIC_URL + '/images/11Klear-Logo-192.png'} style={{ height: 36 }} /></Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link href="/products" eventKey="o">Products</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link href="/pricing" eventKey="x">Pricing</Nav.Link>
                            </Nav.Item>
                            {/* <Nav.Item>
                            <Nav.Link disabled>Developers</Nav.Link>
                        </Nav.Item> */}
                            <Nav.Item>
                                <Nav.Link disabled></Nav.Link>
                            </Nav.Item>
                            <UserContext.Consumer>
                                {(context) => {
                                    if (!context.isLoggedIn) {
                                        return (<>
                                            <Nav.Item>
                                                <Nav.Link href="/partners" eventKey="n">Partners</Nav.Link>
                                            </Nav.Item>
                                            <NavDropdown title="Resources" id="resources-nav-dropdown">
                                                <NavDropdown.Item href="/developer">Developers</NavDropdown.Item>
                                                {/* <NavDropdown.Item href="/partners">Partner Program</NavDropdown.Item> */}
                                                {/* <NavDropdown.Item href="/whitepaper">Whitepaper</NavDropdown.Item> */}
                                                <NavDropdown.Item href="https://11klear.freshdesk.com">Support</NavDropdown.Item>
                                            </NavDropdown>
                                        </>)
                                    } else {
                                        return (<>
                                            <Nav.Item>
                                                <Nav.Link href="/partners" eventKey="n">Partners</Nav.Link>
                                            </Nav.Item>
                                            <NavDropdown title="Resources" id="resources-nav-dropdown">
                                                <NavDropdown.Item href="/developer">Developers</NavDropdown.Item>
                                                {/* <NavDropdown.Item href="/partners">Partner Program</NavDropdown.Item> */}
                                                {/* <NavDropdown.Item href="/whitepaper">Whitepaper</NavDropdown.Item> */}
                                                <NavDropdown.Item href="/userguide">User Guide</NavDropdown.Item>
                                                <NavDropdown.Item href="https://11klear.freshdesk.com">Support</NavDropdown.Item>
                                            </NavDropdown>
                                        </>)
                                    }
                                }}
                            </UserContext.Consumer>
                            {/* <UserContext.Consumer>
                                {(context) => {
                                    if (!context.isLoggedIn) {
                                        // if (!this.state.logged) {
                                        return (<>
                                            <NavDropdown title="Dashboard" id="account-nav-dropdown">
                                                <NavDropdown.Item href="/dashboard/" eventKey="u">For Customers</NavDropdown.Item>
                                                <NavDropdown.Item href="/partners/dashboard/" eventKey="n">For Partners</NavDropdown.Item>
                                            </NavDropdown>
                                        </>)
                                    } else {
                                        return (
                                            <NavDropdown title="My Account" id="account-nav-dropdown">
                                                <NavDropdown.Item href="/dashboard/" eventKey="d">Dashboard</NavDropdown.Item>
                                                <NavDropdown.Item href="/partners/dashboard/" eventKey="n">Partner Dashboard</NavDropdown.Item>                                                <NavDropdown.Item href="/account/profile" eventKey="a">Account Profile</NavDropdown.Item>
                                                <NavDropdown.Divider />
                                                <NavDropdown.Item href="/access" eventKey="s">API Access</NavDropdown.Item>
                                                <NavDropdown.Item href="/usage" eventKey="a">Transaction History</NavDropdown.Item>
                                                <NavDropdown.Divider />
                                                <NavDropdown.Item href="/account/logout">Sign Out</NavDropdown.Item>
                                            </NavDropdown>
                                        )
                                    }
                                }}
                            </UserContext.Consumer> */}
                        </Nav>
                        <div className="content">
                            <Route exact path="/" component={Home} />
                            <Route path="/dashboard" component={Dashboard} />
                            <Route path="/policy/:name?" component={Policy} />
                            <Route path="/terms" component={Terms} />
                            <Route path="/support" render={() => (window.location.href = "https://11klear.freshdesk.com")} />
                            <Route path="/products" component={Products} />
                            <Route path="/pricing" component={Pricing} />
                            <Route path="/developer" component={Developer} />
                            <Route path="/userguide/:type?" component={UserGuide} />
                            {/* <Route path="/docs/:type" component={Documents} /> */}
                            <Route path="/whitepaper" component={Whitepaper} />
                            <Route path="/account/:action/:campaign?/:ref1?/:ref2?" component={Account} />
                            <Route path="/usage" component={Usage} />
                            <Route path="/access" component={APIAccess} />
                            <Route path="/payment/:action?/:type?" component={Payment} />
                            <Route path="/promotion/:campaign/:ref1?/:ref2?" component={Promotion} />
                            <Route path="/invite" component={Invite} />
                            <Switch>
                                <Route path="/partners/dashboard" component={PartnerDashboard} />
                                <Route path="/partners/:name?" component={Partner} />
                            </Switch>
                        </div>
                        {/* <Navbar sticky="bottom"> */}
                        <Nav fill activeKey={this.state.currentMenuKey} onSelect={(selectedKey) => this.handleSelect(selectedKey)} className="justify-content-center">
                            <Nav.Item>
                                <Nav.Link href="/policy/privacy" style={{ color: '#111' }} eventKey="p">Privacy Policy</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link href="/terms" style={{ color: '#111' }} eventKey="t">Terms of Service</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link disabled></Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link disabled></Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link disabled></Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link disabled style={{ color: '#111' }}>11Klear&reg;, 11Edge&trade; and their logos are trademarks of &#169; 2020-2023, 11Edge Corporation</Nav.Link>
                            </Nav.Item>
                        </Nav>
                        {/* </Navbar> */}
                    </div>
                </Switch>
            </BrowserRouter>
        );
    }
}

Main.contextType = UserContext;
export default withCookies(Main);
