import React from "react";

function Policy(props) {
    const { name } = props.match.params;

    // if (name === undefined) {
    //     name = 'privacy';
    // }
    let divStyle = {
        paddingTop: "1vh",
        paddingBottom: "1vh",
        paddingRight: "3vw",
        paddingLeft: "3vw"
    }

    if (name === 'restrictions') {
        return (
            <div style={divStyle}>
                <h2>Use Restriction Policy</h2>
                <i>Last updated: April 10, 2021</i><br />
                <br />
                <p>Millions of people can use our Service every day. We are proud to give them a better way to work. We also recognize that however good our intentions, technology can amplify the ability to cause significant harm. That's why we've established this policy.</p>
                <p>We feel an ethical obligation to counter such harm: dealing with instances where 11Klear  is used (and abused) to inflict harm and state unequivocally that the Services we offer are not for people who wish to commit any offense.</p>
                <p>If you have an account and use any of our products, you can’t use them for illegal activity under Federal or Provincial Canadian Law.</p>
                <p>If we find out you are, we will take action and suspend your account.</p>
            </div>
        )
    } else if (name === 'cancellation') {
        return (
            <div style={divStyle}>
                <h2>Cancellation Policy</h2>
                <i>Last updated: May 7, 2021</i><br />
                <br />
                <p>The account holder can cancel an 11Klear account by contacting 11Klear support. Please include the following information with your account cancellation request. <i><u>Your (account holder's) name, company name, address, phone number and reason for cancellation.</u></i> The information provided must match with the registered account details, and we reserve the rights to confirm your identity.</p>
                <p>We will automatically cancel any account that remains dormant for 180 days. If there is no activity in an account for 180 days, it will be scheduled for auto-cancellation.</p>
            </div>
        )
    // } else if (name === 'refund') {
    //     return (
    //         <div style={divStyle}>
    //             <h2>Fair Refund Policy</h2>
    //             <i>Last updated: May 4, 2021</i><br />
    //             <br />
    //             <p>We want to be fair to our customers while at the same time protecting our company from abuse. You will get a refund only if you cancel your account. When canceling your account, you will effectively forgo any Bonus Service Credit your prepayment may have entitled you to.</p>
    //             <p>Our refund policy is simple.</p>
    //             <ul>
    //                 <li>If the Balance is $3000 or more, we will refund 60% of the Balance</li>
    //                 <li>If the Balance $500 or more, but less than $3000, we will refund 70% of the Balance</li>
    //                 <li>If the Balance is less than $500, we will refund 80% of the Balance. </li>
    //             </ul>
    //             <p>The account balance used for calculating the amount of refund is the balance on the date when the refund is processed.</p>
    //             <p>Transaction cost and sales tax adjustments will be subtracted from the refund amount as required.</p>
    //         </div>
    //     )
    } else if (name === 'privacy') {
        return (
            <div style={divStyle}>
                <h2>Privacy Policy</h2>
                <i>Last updated: May 4, 2021</i><br />
                <br />
                {/* <p>At 11Klear, accessible from https://www.11klear.com, one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that is collected and recorded by 11Klear and how we use it.</p> */}

                {/* <p>If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us. Our Privacy Policy was generated with the help of <a href="https://www.gdprprivacynotice.com/">GDPR Privacy Policy Generator from GDPRPrivacyNotice.com</a></p> */}

                <p>11Klear is a software service created and maintained by 11Edge Corporation, accessible from <a href="https://www.11klear.com">https://www.11klear.com</a>.</p>
                <p>The privacy of users of all our services is a top priority for 11Edge. This Privacy Policy document describes what information is collected via 11Klear and how we use it.</p>

                <h3>1. General Data Protection Regulation (GDPR)</h3>
                <p>We are a Data Controller of your information.</p>

                <p>11Edge Corporation legal basis for collecting and using the personal information described in this Privacy Policy depends on the Personal Information we collect and the specific context in which we collect the information:</p>
                <ul>
                    <li>11Edge Corporation needs to perform a contract with you</li>
                    <li>You have given 11Edge Corporation permission to do so</li>
                    <li>Processing your personal information is in 11Edge Corporation legitimate interests</li>
                    <li>11Edge Corporation needs to comply with the law</li>
                </ul>

                <p>11Edge Corporation will retain your personal information only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use your information to the extent necessary to comply with our legal obligations, resolve disputes, and enforce our policies.</p>

                <p>If you are a resident of the European Economic Area (EEA), you have certain data protection rights. If you wish to be informed what Personal Information we hold about you and if you want it to be removed from our systems, please contact us.</p>
                <p>In certain circumstances, you have the following data protection rights:</p>
                <ul>
                    <li>The right to access, update or to delete the information we have on you.</li>
                    <li>The right of rectification.</li>
                    <li>The right to object.</li>
                    <li>The right of restriction.</li>
                    <li>The right to data portability</li>
                    <li>The right to withdraw consent</li>
                </ul>

                <h3>2. What we collect and why</h3>

                <p>Our guiding principle is to collect only what we need. Here’s what that means in practice:</p>
                <h4>Identity & access</h4>
                <p>When you sign up for an 11Klear product, we typically ask for identifying information such as your name, email address, and maybe a company name. That’s just so you can personalize your new account, and we can send you invoices, updates, or other essential information. We sometimes also give you the option to add a profile picture that displays in our products, but we do not normally look at or access that picture. We’ll never sell your personal info to third parties, and we won’t use your name or company in marketing statements without your permission.</p>
                <h4>Billing information</h4>
                <p>When you pay for an 11Klear product, we ask for your credit card and billing address. That’s so we can charge you for service, calculate taxes due, and send you invoices. Your credit card is passed directly to our payment processor and doesn’t ever go through our servers. We may store a record of the payment transaction, including the last 4 digits of the credit card number and billing address, to maintain account history, invoicing, and billing support. We may store your billing address to calculate any sales tax due in Canada, the United States or VAT in the EU, to detect fraudulent credit card transactions, and to print on your invoices.</p>
                <h4>Geolocation data</h4>

                <p>
                We log access to all accounts by full IP address to prevent unauthorized access. We keep this login data for as long as your account is active. We also log complete IP addresses used to sign up for a product account. These records a saved forever because they are used to mitigate spammy signups.<br />
                <br />
                Web analytics data — described further in the Website/App Interactions section — are temporarily linked to IP addresses to assist with troubleshooting. We utilize Google's Firebase Analytics to better understand and improve the user experience, which is known to link IP addresses temporarily to generate the relevant analytics.
                </p>

                {/* <p>We log all access to all accounts by full IP address so that we can always verify no unauthorized access has happened. We keep this login data for as long as your product account is active. We also log full IP addresses used to sign up a product account. We keep this record forever because they are used to mitigate spammy signups.<br />
                    <br />
    Web analytics data — described further in the Website/App Interactions section — are also tied temporarily to IP addresses to assist with troubleshooting cases. We also utilize Google's Firebase Anayltics to understand better and improve our user experiences, and this is known to tie IP addresses temporarily to generate the relevant analytics.<br />
                </p> */}


                <h4>Website/App interactions</h4>
                <p>When you browse our marketing pages or applications, your browser automatically shares certain information such as which operating system and browser version you are using. We track that information, along with the pages you are visiting, page load timing, and which website referred you for statistical purposes like conversion rates and to test new designs. We sometimes track specific link clicks to help some design decisions. The web analytics data are linked to your IP address and user account if applicable and you are signed into our Services.</p>

                <h3>3. Log Files</h3>

                <p>11Klear follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this; it is a part of hosting services' analytics. The information collected by log files includes internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks.<br />
                This data is not linked to any personally identifiable information. The purpose of the information is to analyze trends, administer the site and track users' engagement.</p>
                {/* <p>11Klear follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this and a part of hosting services' analytics. The information collected by log files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for analyzing trends, administering the site, tracking users' movement on the website, and gathering demographic information.</p> */}

                <h3>4. Cookies and Web Beacons</h3>

                <p>We do use persistent first-party cookies to store certain preferences, make it easier for you to use our applications, and support in-house analytics. A cookie is a piece of text stored by your browser to help it remember your login information, site preferences, and more. You can adjust cookie retention settings in your own browser. To learn more about cookies, including how to view which cookies have been set and how to manage and delete them, please visit: <a href="www.allaboutcookies.org">www.allaboutcookies.org</a>.</p>

                <p>For more general information on cookies, please read <a href="https://www.privacypolicyonline.com/what-are-cookies/">"What Are Cookies"</a>.</p>

                <h3>5. Privacy Policies</h3>

                {/* <p>You may consult this list to find the Privacy Policy for each of the advertising partners of 11Klear.</p> */}

                <p>Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or Web Beacons that are used in their respective advertisements and links that appear on 11Klear, which are sent directly to users' browser. They automatically receive your IP address when this occurs. These technologies are used to measure the effectiveness of their advertising campaigns and/or to personalize the advertising content that you see on websites that you visit.</p>

                <p>Note that 11Klear has no access to or control over these cookies that are used by third-party advertisers.</p>

                <h3>6. Third Party Privacy Policies</h3>

                <p>11Klear's Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and instructions about how to opt-out of certain options. </p>

                <p>You can choose to disable cookies through your individual browser options. To know more detailed information about cookie management with specific web browsers, it can be found at the browsers' respective websites.</p>

                <h3>7. Children's Information</h3>

                <p>Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity.</p>

                <p>11Klear does not knowingly collect any Personal Identifiable Information from children under the age of 13. If you think that your child provided this kind of information on our website, we strongly encourage you to contact us immediately and we will do our best efforts to promptly remove such information from our records.</p>

                <h3>8. Online Privacy Policy Only</h3>

                <p>Our Privacy Policy applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and/or collect by 11Klear. This policy is not applicable to any information collected offline or via channels other than this website.</p>

                <h3>9. Consent</h3>

                <p>By using our website, you hereby consent to our Privacy Policy and agree to its terms.</p>
                {/* <h2>Privacy Policy</h2>
    <br />
    <p>Your privacy is important to us. It is 11Edge Corporation's policy to respect your privacy regarding any information we may collect from you across our website, <a href="https://11klear.com">https://11klear.com</a>, and other sites we own and operate.</p>
    <p>We only ask for personal information when we truly need it to provide a service to you. We collect it by fair and lawful means, with your knowledge and consent. We also let you know why we’re collecting it and how it will be used.</p>
    <p>We only retain collected information for as long as necessary to provide you with your requested service. What data we store, we’ll protect within commercially acceptable means to prevent loss and theft, as well as unauthorised access, disclosure, copying, use or modification.</p>
    <p>We don’t share any personally identifying information publicly or with third-parties, except when required to by law.</p>
    <p>Our website may link to external sites that are not operated by us. Please be aware that we have no control over the content and practices of these sites, and cannot accept responsibility or liability for their respective privacy policies.</p>
    <p>You are free to refuse our request for your personal information, with the understanding that we may be unable to provide you with some of your desired services.</p>
    <p>Your continued use of our website will be regarded as acceptance of our practices around privacy and personal information. If you have any questions about how we handle user data and personal information, feel free to contact us.</p>
    <p>This policy is effective as of 20 August 2020.</p>  */}
            </div>
        );
    } else {
        return (
            <div style={divStyle}>
                <h2>Policies to our Terms of Service</h2>
                <br />
                <ul>
                    <li><a href="/policy/privacy">Privacy Policy</a></li>
                    <li><a href="/policy/restrictions">Use Restrictions Policy</a></li>
                    <li><a href="/policy/cancellation">Cancellation Policy</a></li>
                    {/* <li><a href="/policy/refund">Fair Refund Policy</a></li> */}
                </ul>
            </div>
        )
    }
}


export default Policy;