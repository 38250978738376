const COLORS = {
  white: '#fff',
  background: '#eceff1',
  color: '#123',
  lightColor: '#567',
  red: '#d62d20'
}

const colorPens = {
  background: '#faf2db',
  main: '#007bff',
  primary: '#007bff',
  secondary: '#6c757d',
  success: '#28a745',
  danger: '#dc3545',
  warning: '#ffc107',
  info: '#17a2b8',
  light: '#f8f9fa',
  dark: '#343a40'
}

export {
  COLORS,
  colorPens
}

// export default COLORS
