import React, { Component } from "react";
import { Container, Row, Col, Button, Spinner, Card } from "react-bootstrap";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import * as DPOD from './components/DPOD.js';

var Parse = require('parse');

class APIAccess extends Component {
    constructor(props) {
        super(props);

        this.state = {
            api_secret: "loading...",
            transactions: [],
            loadingSpinner: <>
                <Spinner animation="border" variant="secondary" size="sm" role="status">
                    <span className="sr-only">Loading...</span>
                </Spinner> </>,
            isCopied: false,
            isCopiedButtonText: 'Copy',
            demoUser: true
        }
    }

    async retrieveAPIKeys() {
        // Update the displayed API secret key
        let data = {
            it: this.currentUser.get("sessionToken")
        }

        const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/v1/retrieve-keys`, {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json'
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify(data)
        });

        if (response.status !== 200) {
            // console.log('hmm => '+ JSON.stringify(response));
            alert(`Unknown error occurred. Please try again later. (${response.status}-${response.statusText})`);
            // throw Error({ status: response.status, message: response.statusText });
        }

        const result = await response.json();
        console.log(`result=${JSON.stringify(result)}`);

        this.setState({
            api_secret: result.secret,
            loadingSpinner: null
            // isCopiedButtonText: 'Copy'
        });
    }

    async retrieveAPIKeysOLD() {
        // Update the displayed API secret key
        let verlies = Parse.Object.extend("Verlies");
        let queryAPIKeys = new Parse.Query(verlies);
        queryAPIKeys.descending("createdAt");
        queryAPIKeys.equalTo("active", true);

        try {
            let apiKeysResult = await queryAPIKeys.first();
            let api_secret = apiKeysResult.get("secret");
            if (api_secret === undefined || api_secret === null) {
                api_secret = 'Not Assigned';
            }
            this.setState({ api_secret: api_secret, loadingSpinner: null });
        } catch (error) {
            console.log('error while retrieveing the API keys; ' + JSON.stringify(error));
            if (error.code === 209) {
                await Parse.User.logOut();
            }
            this.setState({ api_secret: "NA" });
        }
    }

    async retrieveTransactions() {
        // TODO: fully implement this feature
        // Update the current balance

        let kontostand = Parse.Object.extend("Kontostand");
        let queryBalance = new Parse.Query(kontostand);
        queryBalance.notEqualTo("hidden", true);
        queryBalance.limit(100);

        try {
            // let endBalance = 0;
            let retrievedKontostands = [];

            let results = await queryBalance.find();
            for (let i = 0; i < results.length; i++) {
                let object = results[i];
                // endBalance += object.get("amount");

                // console.log(` +++ processing #${i} +++`);

                let createdAtDate = object.get("createdAt");
                let createdAtString = createdAtDate.getFullYear() + '-' + (createdAtDate.getMonth() + 1) + '-' + createdAtDate.getDate() + ' ' + createdAtDate.toLocaleTimeString('en-US');

                let miniKontostand = {
                    tid: `pt_${DPOD.asc2dec(object.id)}`,
                    createdAt: createdAtString,
                    amount: object.get("amount"),
                    note: object.get("note"),
                }
                // did: object.get("dpod"),
                let did = object.get("dpod");
                if (did === undefined) {
                    miniKontostand['nid'] = 'NA';
                } else {
                    miniKontostand['nid'] = DPOD.asc2dec(did.id.toString());
                }
                // console.log(` ... retrievedMiniKontostand= ${JSON.stringify(miniKontostand)}`);
                retrievedKontostands.push(miniKontostand);
            }
            // this.setState({ balance: endBalance });
            // let currentBalance = await this.retrieveBalance();
            // console.log(`currentBalance=${currentBalance}`);

            // this.setState({ balance: currentBalance, transactions: retrievedKontostands });
            this.setState({ transactions: retrievedKontostands });
        } catch (error) {
            console.log('error while retrieveing the balance; ' + JSON.stringify(error));
            console.log(error);
            this.setState({ balance: "NA" })
        }
    }

    async copyTextToClipboard(text) {
        if ('clipboard' in navigator) {
            return await navigator.clipboard.writeText(text);
        } else {
            return document.execCommand('copy', true, text);
        }
    }

    async handleCopyClicked() {
        this.copyTextToClipboard(this.state.api_secret).then(() => {
            this.setState({ isCopied: true, isCopiedButtonText: 'Copied!' });
            setTimeout(() => {
                this.setState({ isCopied: false, isCopiedButtonText: 'Copy' });
            }, 1500);
        }).catch((error) => {
            alert(error);
        })
    }

    async componentDidMount() {
        // let currentUser = Parse.User.current();
        await Parse.Session.current();
        this.currentUser = await Parse.User.currentAsync();

        if (this.currentUser != null) {
            // TODO: retrieve the access records
            await this.retrieveAPIKeys();
        } else {
            window.location.href = '/';
        }
    }

    render() {
        let thisTransactionColumns = [
            // { dataField: 'action', text: 'Action', headerStyle: { width: '90px' } },
            // { dataField: 'pr', text: 'Type', headerStyle: { width: '60px' } },
            // { dataField: 'bid', text: 'BId' },
            // { dataField: 'nid', text: 'NId' },
            // { dataField: 'sid', text: 'SId' },
            // { dataField: 'recipient', text: 'Recipient', style: { wordWrap: 'break-word' } },
            {
                dataField: 'createdAt', text: 'Created', style: { wordWrap: 'break-word' }, sort: true,
                sortFunc: (a, b, order, dataField, rowA, rowB) => {
                    if (order === 'asc') {
                        return Date.parse(a) - Date.parse(b)
                    } else if (order === 'desc') {
                        return Date.parse(b) - Date.parse(a)
                    }
                }
            },
            { dataField: 'tid', text: 'API Call ID', headerStyle: { width: 'auto' } },
            { dataField: 'command', text: 'Call Summary', style: { wordWrap: 'break-word' } },
            { dataField: 'nid', text: '11Tag #', style: { wordWrap: 'break-word' } },
            { dataField: 'note', text: 'Note', style: { wordWrap: 'break-word' } }
            // { dataField: 'status', text: 'Status', style: { wordWrap: 'break-word' }, sort: true }
        ];

        return (
            <Container fluid>
                <Row className="justify-content-center align-items-center">
                    <Col md={2} align="right">
                        <Button variant="outline-primary" size="sm" onClick={() => { alert('This function is not generally available, yet. Please contact us to manually initiate this process.'); }}>Renew this</Button>
                    </Col>
                    <Col md={8}>
                        <Card>
                        <Card.Header>API secret key</Card.Header>
                            <Card.Body>
                                <Card.Text>
                                    {this.state.api_secret}&nbsp;&nbsp;{this.state.loadingSpinner ? this.state.loadingSpinner : (<><Button variant="outline-primary" size="sm" onClick={() => { this.handleCopyClicked(); }}>{this.state.isCopiedButtonText}</Button></>)}
                                </Card.Text>
                                {/* <Card.Footer className="text-muted">
                                {this.state.loadingSpinner}
                                </Card.Footer> */}
                            </Card.Body>
                        </Card>
                        {/* <h4>API secret key</h4> <h6><i>{this.state.api_secret}</i></h6> {this.state.loadingSpinner} */}
                    </Col>
                    <Col md={2}>
                        &nbsp;
                    </Col>
                </Row>
                <Row className="justify-content-center align-items-center">
                    <Col md={12} align="right">
                        <i>*Latest 100 records</i>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <BootstrapTable bootstrap4 keyField='tid' data={this.state.transactions} defaultSorted={[{ dataField: 'createdAt', order: 'desc' }]} striped columns={thisTransactionColumns} pagination={paginationFactory()} />
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default APIAccess;