import React, { Component } from "react";
import { Container, Row, Col } from 'react-bootstrap';
import CheckIcon from '@material-ui/icons/Check';
// import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';import { Button } from '@material-ui/core';
// import DeveloperModeIcon from '@material-ui/icons/DeveloperMode';
// import DeveloperBoardIcon from '@material-ui/icons/DeveloperBoard';
// import MoneyOffIcon from '@material-ui/icons/MoneyOff';
// import BusinessIcon from '@material-ui/icons/Business';
import BusinessOutlinedIcon from '@material-ui/icons/BusinessOutlined';
import MoneyOffOutlinedIcon from '@material-ui/icons/MoneyOffOutlined';
import DeveloperBoardOutlinedIcon from '@material-ui/icons/DeveloperBoardOutlined';

import { colorPens } from './utils/colors';
import TryNow from './components/tryNow';

class Developer extends Component {
    render() {
        if (this.props.match.params.action === 'api') {
            <Container fluid>
                <p></p>
                <Row className="justify-content-center">
                    <Col md={2} align="center">
                        <span style={{ color: colorPens.dark, fontWeight: 'bold' }}>Introduction</span>
                    </Col>
                    <Col md={10}>
                        <h5><b>Introduction</b></h5>
                        11Klear API is built around REST, and it returns JSON-encoded responses with standard HTTP response codes.<br />
                    </Col>
                </Row>
                <br />
            </Container>
        } else {
            return (
                <Container fluid>
                    <p></p>
                    <Row className="justify-content-center">
                        <Col md={2} align="center">
                            <BusinessOutlinedIcon style={{ color: colorPens.info, fontSize: 55 }} />
                        </Col>
                        <Col md={8}>
                            <h5><b>Why should I choose 11Klear as my proof of delivery backend?</b></h5>
                            <CheckIcon /> Roll out cutting-edge recipient verification in minutes, not years!<br />
                            <CheckIcon /> No development/maintenance for Mobile companion apps (iOS/Android)<br />
                            <CheckIcon /> Industry-first turn-key contactless POD solution<br />
                        </Col>
                    </Row>
                    <br />
                    <Row className="justify-content-center">
                        <Col md={2} align="center">
                            <MoneyOffOutlinedIcon style={{ color: colorPens.info, fontSize: 55 }} />
                        </Col>
                        <Col md={8}>
                            <h5><b>How much does it cost?</b></h5>
                            During the alpha stage, it is on the house! Feel free to experiment to grow your business.
                        </Col>
                    </Row>
                    <br />
                    <Row className="justify-content-center">
                        <Col md={2} align="center">
                            <DeveloperBoardOutlinedIcon style={{ color: colorPens.info, fontSize: 55 }} />
                            {/* <Image src={process.env.PUBLIC_URL+'/images/details/icon_faq_phone.svg'} style={{ width: 100 }} fluid /> */}
                        </Col>
                        <Col md={8}>
                            <h5><b>How can I integrate 11Klear to my software platform/online store?</b></h5>
                            <p>There are two ways:<br />
                                <ol>
                                    <li>Create a 11Klear tag on our dashboard and store the generated barcode or the tag number in your system.</li>
                                    <li>Use API to create and retrieve the 11Klear barcode seamlessly from your system when the order is being processed. This will also allow you to trace the status updates or setup the webhook when the delivery is complete. (For full features, please check out <a href={process.env.PUBLIC_URL + '/userguide/api'}>the API reference</a>)</li>
                                </ol>
                            </p>
                            <b>That is it!</b>
                        </Col>
                    </Row>
                    <br />
                    {/* <br />
        <Row className="justify-content-center">
          <Col md={2} align="center">
            <Image src={process.env.PUBLIC_URL+'/images/details/icon_faq_pie.svg'} style={{ width: 100 }} fluid />
          </Col>
          <Col md={8}>
            <h4><b>E-merchants and their customers need and want better contactless solutions!</b></h4>
            <CheckBoxOutlineBlankIcon /> 70% of consumers want a contactless digital alternative to proof-of-delivery signatures for their higher-value packages<br />
            <CheckBoxOutlineBlankIcon /> 49% of adults are uncomfortable with personal interaction even after Covid vaccination <br />
            <br />
            <h5>Get the tools you need to provide excellent contactless delivery service.</h5>
          </Col>
        </Row> */}
                    <br />
                    <TryNow />
                    <br />
                </Container>
            );
        }
    }
}

export default Developer;