import React, { Component } from "react";
import './Home.css';

// import banner from './Special_Delivery__Bird_preview.jpg';
import { Container, Row, Col, Image, Collapse, Tooltip, OverlayTrigger } from 'react-bootstrap';
// import birdImage from './images/Special_Delivery__Bird_preview.jpg';
import CheckIcon from '@material-ui/icons/Check';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { Button } from '@material-ui/core';
// import * as Scroll from 'react-scroll';
import { Events, scrollSpy, scroller } from 'react-scroll';
// import { Redirect, Route } from 'react-router-dom';

import { colorPens } from './utils/colors';
import TryNow from './components/tryNow';
import { red } from "@material-ui/core/colors";

class Home extends Component {
  state = {
    cover: true,
    coverMouseOver: true,
    readMore: false
  };

  componentDidMount() {
    Events.scrollEvent.register('begin', function (to, element) {
      console.log('begin', arguments);
    });

    Events.scrollEvent.register('end', function (to, element) {
      console.log('end', arguments);
    });

    scrollSpy.update();
  }

  componentWillUnmount() {
    Events.scrollEvent.remove('begin');
    Events.scrollEvent.remove('end');
  }

  handleSetActive(to) {
    console.log(`handleSetActive ${to}`);
  }

  handleInvite() {
    window.location.href = '/invite';
  }

  // renderCoverTooltip(props) {
  //   return (
  //   <Tooltip id="button-tooltip" {...props}>
  //     Click to find out more
  //   </Tooltip>
  //   )
  // }

  render() {
    var coverStyle, coverButtonStyle;
    if (this.state.coverMouseOver) {
      coverStyle = {
        opacity: 0.4
      }
      coverButtonStyle = {
        transition: '.5s ease',
        opacity: 1,
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        '-ms-transform': 'translate(-50%, -50%)',
        'text-align': 'center',
        'background-color': colorPens.primary,
        color: 'white',
        'font-size': '1em',
        'font-weight': 'bold',
        padding: '16px 32px'
      }
    } else {
      coverStyle = {
        opacity: 1
      }
      coverButtonStyle = {
        opacity: 0
      }
    }

    return (
      <Container fluid>
        {/* <Row className="justify-content-center">
          <Col md={6} align="center">
            <font color="#228B22">
              <h4><b><i>Contactless + Fast + Affordable</i></b></h4>
            </font></Col>
        </Row> */}
        <>
          <Row className="justify-content-center align-items-center">
            {/* <Col>&nbsp;</Col> */}
            {/* <Col md={6}>
              <Image
                src={process.env.PUBLIC_URL + '/images/details/Phones1.svg'}
                fluid
                // onClick={() => {
                //   this.setState({ cover: false });
                // }}
               style={coverStyle}
              // onMouseEnter={() => {
              //   this.setState({ coverMouseOver: true });
              // }}
              // onMouseLeave={() => {
              //   this.setState({ coverMouseOver: false });
              // }}
              />
              <div style={coverButtonStyle}>Click anywhere to continue</div>
            </Col> */}
            {/* <Col>&nbsp;</Col> */}
            {/* <Col md={6} align="center">
              <Row className="justify-content-center align-items-center">
                <Col md={12} align="center" style={{
                  // fontFamily: 'Exo',
                  fontSize: '2.5em',
                  fontWeight: 600
                }}>
                  Universal Delivery Confirmations
                </Col>
                <Col md={12} align="center" style={{
                  // fontFamily: 'Exo',
                  fontSize: '1.8em',
                  fontWeight: 300
                }}>
                  Bring Your Own Delivery Service
                </Col>
                <Col>&nbsp;</Col>
                <Col md={12} align="center" style={{
                  // fontFamily: 'Exo',
                  fontSize: '1.5em',
                  fontWeight: 300
                }}>
                  <u>NO up-front long-term commitment</u><br />
                  your professional delivery notifications.
                </Col>
              </Row>
            </Col> */}

            <Col md={6}>
              <Image
                src={process.env.PUBLIC_URL + '/images/background/4-piece-use-scenes.png'}
                fluid
                // onClick={() => {
                //   this.setState({ cover: false });
                // }}
              //  style={coverStyle}
               className="cover"
              // onMouseEnter={() => {
              //   this.setState({ coverMouseOver: true });
              // }}
              // onMouseLeave={() => {
              //   this.setState({ coverMouseOver: false });
              // }}
              />
              <div className="coverButton">
                <Col md={12} align="center" style={{
                    // fontFamily: 'Exo',
                    fontSize: '1.8em',
                    fontWeight: 300
                  }}>
                    Real-Time Document Security<br /> & Delivery Verification
                  </Col>
                  {/* <Col>&nbsp;</Col> */}
                  <Col md={12} align="center" style={{
                    // fontFamily: 'Exo',
                    fontSize: '1.5em',
                    fontWeight: 300
                  }}>
                    {/* <u>NO long-term commitment</u><br /> */}
                  </Col>

              </div>
            </Col>

            </Row>

            {/* <Col>
                  <Row><Col align="center">

                    <Button
                      disabled={false}
                      onClick={() => this.setState({ readMore: !this.state.readMore })}
                      // style={{
                      //   fontSize: 18,
                      //   color: colorPens.light,
                      //   backgroundColor: colorPens.primary
                      // }}
                      size={'large'}
                      color={'primary'}
                      variant={'outlined'}
                      startIcon={<ExpandMoreIcon />}
                    >find out more</Button>
                  </Col>
                  </Row>
                </Col> */}
                <br />

            <Col>
                  <Row className="justify-content-center align-items-center">
                    <TryNow />

                  </Row>
                </Col>

        </>
        <>

          {/*
          <Row className="justify-content-center align-items-center">
            <Col>&nbsp;</Col>
            <Col md={2}>
              <Image src={process.env.PUBLIC_URL + '/images/home/usecase.PNG'} fluid />
            </Col>
            <Col md={8}>
              <Row>
                <Col>
                  <h4><b>Use Cases: Single Delivery Or High Volume, 11Klear benefits any delivery</b></h4>
                </Col>
              </Row>
              <Row>
                <Col onClick={() => {
                  this.setState({ readMore: true });
                  setImmediate(() => {
                    scroller.scrollTo("scroll-stores", {
                      spy: true,
                      smooth: true
                    });
                  });
                }}><CheckIcon /> Online and Local Stores</Col>
              </Row>
              <Row>
                <Col onClick={() => {
                  this.setState({ readMore: true });
                  setImmediate(() => {
                    scroller.scrollTo("scroll-3pl", {
                      spy: true,
                      smooth: true
                    });
                  });
                }}><CheckIcon /> Delivery Companies</Col>
              </Row>
              <Row>
                <Col onClick={() => {
                  this.setState({ readMore: true });
                  setImmediate(() => {
                    scroller.scrollTo("scroll-couriers", {
                      spy: true,
                      smooth: true
                    });
                  });
                }}><CheckIcon /> Couriers and Food Delivery</Col>
              </Row>
            </Col>
            <Col>&nbsp;</Col>
          </Row>
          <br />
          <br />

              */}


          <Collapse in={this.state.readMore}>
            <div id="readmore-collapse-text">

              {/* <br /><span id="scroll-recipients"></span><br />
            <br /> */}

              <br />
              <span id="scroll-recipients"></span>

              <br /><span id="scroll-stores"></span><br />
              <Row>
                <Col align="center"><Image src={process.env.PUBLIC_URL + '/images/details/icon_onlineStores.svg'} style={{ width: 100 }} fluid /></Col>
              </Row>
              <Row>
                <Col>&nbsp;</Col>
                {/* <Col align="center" md={1}><Image src={process.env.PUBLIC_URL + '/images/details/icon_greenBox_reverted.svg'} style={{ width: 100 }} fluid /></Col> */}
                <Col md={10}>
                  <h4><b>Online and Local Stores</b></h4>
                  <CheckIcon /> Get customer verified POD at a great price, significantly less than existing signature options.<br />
                  <CheckIcon /> Provide your customers with contactless delivery confirmation and build trust and credibility<br />
                  <CheckIcon /> Clear and verified POD goes a long way. Reduce the hassle and times spent following up delivery errors and mistakes.<br />
                </Col>
                <Col>&nbsp;</Col>
              </Row>
              <br />
              <Row>
                <Col>&nbsp;</Col>
                <Col md={10}>
                  <h5><b>How do I make this work?</b></h5>
                  <p>Three simple steps!
                    <ol>
                      <li>Use the 11Klear Dashboard to create and print the 11Klear tag you need.</li>
                      <li>Attach the tag to your package like any other label.</li>
                      <li>Instruct your delivery people to scan the 11Klear tag at the delivery location! Scanning is quick; it only takes a second.</li>
                    </ol>
                  </p>
                  <h5><b>What if my delivery service is not up to speed on 11Klear?</b></h5>
                  Just send them this link ( <u><a href="https://11klear.com">https://11klear.com</a></u> ) to get the free 11Klear app!
                </Col>
                <Col>&nbsp;</Col>
              </Row>

              <br /><span id="scroll-3pl"></span><br />
              <Row>
                <Col align="center"><Image src={process.env.PUBLIC_URL + '/images/details/icon_3PL.svg'} style={{ width: 100 }} fluid /></Col>
              </Row>
              <Row>
                <Col>&nbsp;</Col>
                {/* <Col align="center" md={1}><Image src={process.env.PUBLIC_URL + '/images/details/icon_lightbulb.svg'} style={{ width: 100 }} fluid /></Col> */}

                <Col md={10}>
                  <h4><b>3PL and Delivery Companies</b></h4>
                  With an 11Klear account, <b>3PL and Delivery Companies</b> can offer value-added proof-of-delivery options surpassing those marketed by industry-leading Fortune 500 companies. No infrastructure investment or specialized equipment is required!<br /><br />
                  Set up is easy! Open an account and have your drivers install the <b>free</b> 11Klear app on their mobile devices.<br /><br />
                  <CheckIcon /> Offer enterprise grade POD options at very competitive prices<br />
                  <CheckIcon /> Contactless recipient verification<br />
                  <CheckIcon /> Value-added revenue source for fulfillment and delivery services<br />
                  <CheckIcon /> Faster and much cheaper than existing proof-of-delivery methods<br />
                </Col>
                <Col>&nbsp;</Col>
              </Row>
              <br />
              <Row>
                <Col>&nbsp;</Col>
                {/* <Col align="center" md={1}><Image src={process.env.PUBLIC_URL + '/images/details/icon_fastforward.svg'} style={{ width: 100 }} fluid /></Col> */}
                <Col md={10}>
                  <h4>It takes less than 30 seconds to create an 11Klear RTV or RT delivery tag on your Dashboard.</h4>
                  <b>RTV tags</b> enable a digital handshake for contactless recipient verification - a clever way of providing signature-equivalent proof-of-delivery at a super competitive price.<br />
                  <b>RT tags</b> trigger real-time delivery alerts; Automated push notifications to the recipient's mobile device. Real-time feedback if a package has somehow reached the wrong address.<br />
                </Col>
                <Col>&nbsp;</Col>
              </Row>

              <br /><span id="scroll-couriers"></span><br />
              <Row>
                <Col align="center"><Image src={process.env.PUBLIC_URL + '/images/details/icon_couriers.svg'} style={{ width: 100 }} fluid /><Image src={process.env.PUBLIC_URL + '/images/details/icon_foodDelivery.svg'} style={{ width: 100 }} fluid /></Col>
              </Row>
              <Row>
                <Col>&nbsp;</Col>
                {/* <Col align="center" md={1}></Col> */}
                <Col md={10}>
                  <h4><b>Couriers and Food Delivery</b></h4>
                  All you need is the 11Klear app available for both Android and iOS. It is <b>free</b> to download from the Apple App Store or Google Play.<br />
                  <br />
                  With the app installed, you are ready to offer value-added POD services to your clients.<br /><br />
                  <p><b>It works like this:</b>
                    <ol>
                      <li>Simply text or email this link ( <u><a href="https://11klear.com">https://11klear.com</a></u> ) to help your client create an 11Klear tag for your pick-up.</li>
                      <li>At the drop-off location, scan the tag with your 11Klear mobile app; the rest is automated.
                        <ul>
                          <li>If your delivery has an 11Klear RT-tag attached, you are done!</li>
                          <li>If your delivery has an 11Klear RTV tag attached, the app will display a confirmation message, 11Kleared, verifying the delivery has been received and accepted by the correct recipient.</li>
                        </ul>
                      </li>
                    </ol>
                  </p>

                </Col>
                <Col>&nbsp;</Col>
              </Row>

              <br /><br />
              <Row className="justify-content-center align-items-center">
                <TryNow />
              </Row>
            </div>
          </Collapse>
          <br />

        </>
        {/* <Row>
          <Col><Image src={process.env.PUBLIC_URL+'/images/banner_RTV_advantages.png'} fluid /></Col>
        </Row> */}
      </Container >
    );
  }
}

export default Home;