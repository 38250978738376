import React, { useEffect, useState } from "react";
// import { Redirect } from 'react-router-dom';
import { Card, Alert, Image } from 'react-bootstrap';
import { Button } from '@material-ui/core';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
// import WarningIcon from '@material-ui/icons/Warning';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';

const colorPens = {
    background: '#faf2db',
    main: '#007bff',
    primary: '#007bff',
    secondary: '#6c757d',
    success: '#28a745',
    danger: '#dc3545',
    warning: '#ffc107',
    info: '#17a2b8',
    light: '#f8f9fa',
    dark: '#343a40'
}

function Oxygen(props) {
    // const [state, setState] = useState({ isValidAction: false });
    const [loading, setLoading] = useState(true);
    const [validAction, setValidAction] = useState(false);
    const [validActionDS, setValidActionDS] = useState(false);
    const [cardBorderColor, setCardBorderColor] = useState('primary');
    const [errorMessage, setErrorMessage] = useState(null);
    const [guideMessage, setGuideMessage] = useState(null);
    const [resultIssuer, setResultIssuer] = useState(null);
    const [resultMessage, setResultMessage] = useState(null);
    const [buttonStyle, setButtonStyle] = useState({
        fontSize: 18,
        color: colorPens.dark,
        backgroundColor: colorPens.light
    });
    const [buttonIcon, setButtonIcon] = useState(<HourglassEmptyIcon />);
    const [buttonText, setButtonText] = useState('Processing...');
    const [isComplete, setIsComplete] = useState(false);

    const { action, id, ref1, ref2 } = props.match.params;
    // var requestedParams = props.match.params['0'].split('/');
    // let action = requestedParams[0];
    // let dpodId = requestedParams[1];
    // let reference = requestedParams[2];
    // let reference = requestedParams[3];

    // let isValidAction = false;
    let title = '';
    if (action === 'ConfirmDelivery') {
        title = 'Delivery Feedback';
    } else if (action === 'Demo') {
        title = 'Demo';
        if (ref1 === '11kleared') {
            // http://localhost:3000/oxygen/Demo/84265134579764583215/11kleared
            title = 'Delivery Confirmed';
        }
    } else if (action === 'DemoDS') {
        // if (ref1 === '11kleared') {
            title = 'Document ScamCheck™';
        // } else {
        //     title = 'Invalid Request';
        // }
    } else {
        title = 'Invalid Request';
    }

    // let resultMessage = null;

    useEffect(() => {
        if (isComplete) {
            return true;
        }

        async function processConfirmDelivery() {
            let data = {
                ddi: id,
                rv: ref2
            }

            let response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/v1/confirm-delivery`, {
                method: 'POST',
                mode: 'cors',
                cache: 'no-cache',
                credentials: 'same-origin',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${ref1}`
                },
                redirect: 'follow',
                referrerPolicy: 'no-referrer',
                body: JSON.stringify(data)
            })

            let responseJSON = await response.json();

            if (!response.ok) {
                setErrorMessage(`${responseJSON.message}`);
                setButtonStyle({ fontSize: 18, color: '#fff', backgroundColor: '#dc3545' });
                setButtonIcon(<ReportProblemOutlinedIcon />);
                setButtonText('Error');

                setValidAction(true);
                setLoading(false);

                // throw Error(errorMessage);
            } else {
                if (responseJSON['it']) {
                    setButtonStyle({ fontSize: 18, color: '#fff', backgroundColor: colorPens.success });
                    setButtonIcon(<ThumbUpIcon />);
                    setButtonText('11Kleared');
                } else {
                    setButtonStyle({ fontSize: 18, color: '#fff', backgroundColor: colorPens.danger, textDecorationLine: 'line-through' });
                    setButtonIcon(<ThumbDownIcon />);
                    setButtonText('11Kleared');
                    let complaintMessage = 'Not Mine';
                    setErrorMessage(complaintMessage);

                    let dataND = {
                        ddi: id,
                        rr: complaintMessage
                    }
        
                    let responseND = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/v1/notify-delivery`, {
                        method: 'POST',
                        mode: 'cors',
                        cache: 'no-cache',
                        credentials: 'same-origin',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${ref1}`
                        },
                        redirect: 'follow',
                        referrerPolicy: 'no-referrer',
                        body: JSON.stringify(dataND)
                    })

                    let responseNDJSON = await responseND.json();
                    if (!responseND.ok) {
                        // TODO: log this to the crash analytics
                        console.log(`error caught while sending back the push notification to the sender; ${responseNDJSON.message}`);
                    } else {
                        // all okay!
                    }
                }

                setValidAction(true);
                setLoading(false);
            }

            setIsComplete(true);
        }

        async function processDemo() {
            if (ref1 === '11kleared') {
                setResultMessage('"Your logo" shows here. [This is a sample]');

                setButtonStyle({ fontSize: 18, color: '#fff', backgroundColor: colorPens.success });
                setButtonIcon(<ThumbUpIcon />);
                setButtonText('11Kleared');

                setValidAction(true);
                setLoading(false);
            } else {
                setErrorMessage('Invalid Request');
                setButtonStyle({ fontSize: 18, color: '#fff', backgroundColor: '#dc3545' });
                setButtonIcon(<ReportProblemOutlinedIcon />);
                setButtonText('Error');

                setValidAction(true);
                setLoading(false);
            }

            setIsComplete(true);
        }

        async function processDemoDS() {
            if (id === 'hsbcus2lcdx') {
                setResultIssuer(<>
                <span style={{ color: colorPens.dark }}>Issued by&nbsp;&nbsp;</span><Image src={process.env.PUBLIC_URL + '/images/demo/HSBC-logo.png'} height={60} /><br />
                </>)
            } else if (id === 'interac2drake') {
                setResultIssuer(<>
                    <span style={{ color: colorPens.dark }}>Issued by&nbsp;&nbsp;</span><Image src={process.env.PUBLIC_URL + '/images/demo/InteracLogo.svg.png'} height={60} /><br />
                    <br />
                    </>)    
            } else if (id === 'goc2white') {
                setResultIssuer(<>
                    <span style={{ color: colorPens.dark }}>Issued by&nbsp;&nbsp;</span><Image src={process.env.PUBLIC_URL + '/images/demo/government-of-canada-vector-logo-768x427.png'} height={60} /><br />
                    <br />
                    </>)
            }
            if (ref1 === '11kleared') {
                setCardBorderColor('primary');

                if (id === 'hsbcus2lcdx') {
                    setResultMessage(<>Bank Statement<br /><br />
                        Account Type: Current<br />
                        Account Name: AMANDA DELOS<br />Account Number: 009923435<br />
                        Date: 7th Aug 2021<br />
                        <br />
                        Contact Tel: 1-888-238-6433<br />
                        https://www.interac.ca/</>);
                } else if (id === 'interac2drake') {
                    setResultMessage(<>Interac e-Transfer<br /><br />
                        From: Micahel Drake<br />
                        Amount: $535.66 (CAD)<br />
                        Expires: November 2, 2023<br />
                        <br />
                        Contact Tel: 800.975.4722<br />
                        www.us.hsbc.com</>);
                } else if (id === 'goc2white') {
                    setResultMessage(<>Government Cheque<br /><br />
                        Type: GST/HST Tax<br />
                        Replacement: 2707-25076524-1<br />
                        Date: 2018-06-08<br />
                        To/A: Walter White<br />
                        Amount: $2,552.17 (CAD)<br />
                        Expires: November 2, 2023<br />
                        <br />
                        Contact Tel: 1-800-277-9914<br />
                        https://www.canada.ca/</>);
                } else {
                    setResultMessage('"Your logo" shows here. [This is a sample]');
                }

                setGuideMessage(<><span style={{ color: colorPens.danger, fontWeight: 'bold' }}>Compare the above information with the document at hand thoroughly. If it doesn't match, it could be fabricated and unreliable.</span><br /><br />
                    </>);

                setButtonStyle({ fontSize: 18, color: '#fff', backgroundColor: colorPens.success });
                setButtonIcon(<ThumbUpIcon />);
                setButtonText('11Kleared');

                setValidActionDS(true);
                setLoading(false);
            } else if (ref1 === 'expired') {
                setCardBorderColor('warning');

                if (id === 'hsbcus2lcdx') {
                    setErrorMessage(<>This document has been expired by the issuer.
                        {/* <br /><br />
                        Contact Tel: 800.975.4722<br />
                        www.us.hsbc.com */}
                        </>);
                } else {
                    setErrorMessage('This document has been expired by the issuer.');
                }

                setButtonStyle({ fontSize: 18, color: '#fff', backgroundColor: '#dc3545' });
                setButtonIcon(<ReportProblemOutlinedIcon />);
                setButtonText('Expired');

                setValidActionDS(true);
                setLoading(false);
            } else {
                setCardBorderColor('danger');

                setErrorMessage('Unrecognized Request. Please contact the document issuer for the further verification.');
                setButtonStyle({ fontSize: 18, color: '#fff', backgroundColor: '#dc3545' });
                setButtonIcon(<ReportProblemOutlinedIcon />);
                setButtonText('Error');

                setValidActionDS(true);
                setLoading(false);
            }

            setIsComplete(true);
        }

        try {
            if (action === 'ConfirmDelivery' && id !== undefined && ref1 !== undefined && ref2 !== undefined) {
                processConfirmDelivery();
            } else if (action === 'Demo' && id !== undefined && ref1 !== undefined) {
                processDemo();
            } else if (action === 'DemoDS' && id !== undefined && ref1 !== undefined) {
                processDemoDS();
            } else {
                // Invalid requests
                setValidAction(false);
                setLoading(false);
            }
        } catch (error) {
            // Something went wrong
            setValidAction(false);
            setLoading(false);
        }

        return function cleanup() {
            // 
        };
    }, []); // Runs this only ONCE
    // }, [props, action, id, ref1, ref2, isComplete]); // Runs once at the initial, and at every rendering WHEN `prop` or `state` changes

    if (loading) {
        return (
            <div>
                {/* <h3><img alt="11Klear" src={process.env.PUBLIC_URL + '/images/11Klear-Logo-192.png'} style={{ height: 36 }} />11Klear</h3> */}
                <Card border="secondary" bg="light" className="text-center">
                    <Card.Header as="h5">
                        <img alt="11Klear" src={process.env.PUBLIC_URL + '/images/11Klear-Logo-192.png'} style={{ height: 32 }} /> <i>{title}</i>
                    </Card.Header>
                    <Card.Body>
                        <Button
                            // color={colorPens.secondary} 
                            disabled={true}
                            variant="outlined"
                            // size="small"
                            // labelStyle={{ fontSize: 28 }} 
                            style={buttonStyle}
                            startIcon={buttonIcon}
                            // onClick={() => { setLoading(false); }}
                        >{buttonText}</Button>
                    </Card.Body>
                    <h6 style={{ color: colorPens.secondary }}><span class="copyright-notice"><span>&copy; </span><span property="dc:date" datatype="xsd:gYear">2020-2023,</span><span> 11Edge Corporation</span></span></h6>
                </Card>
                {/* <h3>External Link:{JSON.stringify(props)}</h3> */}
            </div>
        );
    } else if (validActionDS) {
        return (
            <div>
                {/* <h3><img alt="11Klear" src={process.env.PUBLIC_URL + '/images/11Klear-Logo-192.png'} style={{ height: 36 }} />11Klear</h3> */}
                <Card border={cardBorderColor} bg="light" 
                    className="text-center" style={{ border: '8px solid', outline: '2px solid white' }}>
                    <Card.Header as="h5">
                        <img alt="11Klear" src={process.env.PUBLIC_URL + '/images/11Klear-Logo-192.png'} style={{ height: 32 }} /> <b><i>{title}</i></b>
                    </Card.Header>
                    <Card.Body>
                        {resultIssuer ?
                            resultIssuer
                            :
                            null}
                        {resultMessage ?
                            <Alert variant="info">
                                {resultMessage}
                            </Alert>
                            :
                            null}
                        {guideMessage ?
                            <>{guideMessage}</>
                            :
                            null}
                        {errorMessage ?
                            <Alert variant="danger">
                                {errorMessage}
                            </Alert>
                            :
                            null}
                        <span style={{ color: colorPens.dark }}>Document ID: &nbsp;&nbsp;</span>{id}<br /><br />
                        <Button
                            // color={colorPens.secondary} 
                            disabled={true}
                            variant="outlined"
                            // size="small"
                            // labelStyle={{ fontSize: 28 }} 
                            style={buttonStyle}
                            startIcon={buttonIcon}
                            // onClick={() => { setButtonStyle({ fontSize: 18, color: colorPens.dark, backgroundColor: colorPens.light }); setValidAction(false); setLoading(true); }}
                        >{buttonText}</Button>
                    </Card.Body>
                    <br />
                    <h6 style={{ color: colorPens.secondary }}><span class="copyright-notice"><span>&copy; </span><span property="dc:date" datatype="xsd:gYear">2020-2023,</span><span> 11Edge Corporation</span></span></h6>
                </Card>
                {/* <h3>External Link:{JSON.stringify(props)}</h3> */}
            </div>
        );
    } else if (validAction) {
        return (
            <div>
                {/* <h3><img alt="11Klear" src={process.env.PUBLIC_URL + '/images/11Klear-Logo-192.png'} style={{ height: 36 }} />11Klear</h3> */}
                <Card border="primary" bg="light" className="text-center">
                    <Card.Header as="h5">
                        <img alt="11Klear" src={process.env.PUBLIC_URL + '/images/11Klear-Logo-192.png'} style={{ height: 32 }} /> <i>{title}</i>
                    </Card.Header>
                    <Card.Body>
                        {resultMessage ?
                            <Alert variant="info">
                                {resultMessage}
                            </Alert>
                            :
                            null}
                        {errorMessage ?
                            <Alert variant="danger">
                                {errorMessage}
                            </Alert>
                            :
                            null}
                        <Button
                            // color={colorPens.secondary} 
                            disabled={true}
                            variant="outlined"
                            // size="small"
                            // labelStyle={{ fontSize: 28 }} 
                            style={buttonStyle}
                            startIcon={buttonIcon}
                            // onClick={() => { setButtonStyle({ fontSize: 18, color: colorPens.dark, backgroundColor: colorPens.light }); setValidAction(false); setLoading(true); }}
                        >{buttonText}</Button>
                    </Card.Body>
                    <h6 style={{ color: colorPens.secondary }}><span class="copyright-notice"><span>&copy; </span><span property="dc:date" datatype="xsd:gYear">2020-2023,</span><span> 11Edge Corporation</span></span></h6>
                </Card>
                {/* <h3>External Link:{JSON.stringify(props)}</h3> */}
            </div>
        );
    } else {
        // return <Redirect to={"/"} />;
        return (
            <div>
                <Card border="danger" bg="light" text={'black'} className="text-center">
                    <Card.Header as="h5">
                        <img alt="11Klear" src={process.env.PUBLIC_URL + '/images/11Klear-Logo-192.png'} style={{ height: 32 }} /> <i>{title}</i>
                    </Card.Header>
                    <Card.Body>
                        <h5>Error-501: <i>Invalid Operation</i></h5>
                        <div align="left" width="80%">
                            <h6>[VA] <i>{id}</i></h6>
                            <h6>[VB] <i>{ref1}</i></h6>
                            <h6>[VC] <i>{ref2}</i></h6>
                        </div>
                        <br />
                        <Button
                            // color={colorPens.danger} 
                            disabled={true}
                            variant="contained"
                            style={{ fontSize: 18, color: '#fff', backgroundColor: '#dc3545' }}
                            // labelStyle={{ fontSize: 18 }} 
                            startIcon={<ReportProblemOutlinedIcon />}
                            // onClick={() => { setButtonStyle({ fontSize: 18, color: '#fff', backgroundColor: colorPens.success }); setValidAction(true); }}
                        >Oops!</Button>

                    </Card.Body>
                    <h6 style={{ color: colorPens.secondary }}><span class="copyright-notice"><span>&copy; </span><span property="dc:date" datatype="xsd:gYear">2020,</span><span> 11Edge Corporation</span></span></h6>
                </Card>
                {/* <h3>External Link:{JSON.stringify(props)}</h3> */}
            </div>
        );
    }
}

export default Oxygen;