// import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react';

// import COLORS from '../utils/colors'
// import { card as cardStyle } from '../utils/styles'

import { Formik } from "formik";
import * as yup from "yup";
import { Form, Button, Row, Col, Alert, Tooltip, OverlayTrigger } from 'react-bootstrap';
// import { useAlert } from 'react-alert'

// import TinyFlagReact from "tiny-flag-react";
// import PhoneFormatter from "react-headless-phone-input/lazy";

import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CheckIcon from '@material-ui/icons/Check';
// import PhoneIcon from '@material-ui/icons/Phone';

// import { Auth } from 'aws-amplify';

// yup.addMethod(yup.string, "phone", function (messageError = 'Phone number is not valid') {
//     const phoneRegExp = /^\+(?:[0-9] ?){6,14}[0-9]$/;
//     return this.test('phone', messageError, value => {
//         if (value && value.length > 0) {
//             return phoneRegExp.test(value)
//         }
//         return true
//     })
// })

import { colorPens } from '../utils/colors';

const schema = yup.object({
    country: yup
        .string()
        // .oneOf(['Canada', 'United States'], "Country is a required field"),
        .required("Please choose one"),
        // .object()
        // .shape({
        //     value: yup.string().required("Country is a required field")
        // }),
    state: yup
        .string()
        // .min(8)
        .required("Please choose one"),
        // .oneOf([yup.ref('password'), null], "Password does not match!"),
    address_line1: yup
        .string()
        .max(64)
        .required("Address - line1 is a required field"),
    postal_code: yup
        .string()
        .max(16)
        .required("Postal Code is a required field"),
    city: yup
        .string()
        .max(16)
        .required("City is a required field"),
    // consent: yup
    //     .bool()
    //     .oneOf([true], 'To use 11Klear, the customer must consent to our privacy policy and terms of use. To opt out, please request to delete your account.'),
    // phone_number: yup
    //     .string()
    //     .phone()
})

const CreatePaymentCustomerForm = (props) => {
    // let { campaignIdentifier, wipFormData } = props;
    const [isInteractive, setIsInteractive] = useState(true);
    let { customer } = props;
    // const [country, setCountry] = useState(null);
    // const [address_line1, setAddressLine1] = useState(null);
    // const [address_line2, setAddressLine2] = useState(null);
    // const [postal_code, setPostalCode] = useState(null);
    // const [city, setCity] = useState(null);
    // const [province, setProvince] = useState(null);

    // useEffect(() => {
    //     let address = customer.address;
    //     console.log(`props= ${JSON.stringify(props)}`);
    //     console.log(`address= ${JSON.stringify(address)}`);
    //     if (address !== null) {
    //         setCountry(address.country);
    //         setAddressLine1(address.line1);
    //         setAddressLine2(address.line2);
    //         setPostalCode(address.postal_code);
    //         setCity(address.city);
    //         setProvince(address.state);
    //     }
    //     console.log(`country= ${country}`);
    // }, [props.customer]);

    return (
        <Formik 
            validationSchema={schema} 
            onSubmit={(values, actions) => {
                setIsInteractive(false);
                props.onSubmit(values);
            }} 
            initialValues={{ 
                country: props.customer.address !== undefined && props.customer.address !== null ? props.customer.address.country : "",
                address_line1: props.customer.address !== undefined && props.customer.address !== null ? props.customer.address.line1 : "",
                address_line2: props.customer.address !== undefined && props.customer.address !== null ? props.customer.address.line2 : "",
                postal_code: props.customer.address !== undefined && props.customer.address !== null ? props.customer.address.postal_code : "",
                city: props.customer.address !== undefined && props.customer.address !== null ? props.customer.address.city : "",
                state: props.customer.address !== undefined && props.customer.address !== null ? props.customer.address.state : "" }}>
            {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                isValid,
                errors,
            }) => (
                <Row className="justify-content-center">
                    {/* <Col md={8} style={{ backgroundColor: colorPens.light, border: '1px solid '+ colorPens.success, borderRadius: '5px' }}> */}
                    <Col md={8}>
                    <Form noValidate onSubmit={handleSubmit}>
                    <Row className="justify-content-center">
                        <Col md={11}>
                            <h4>1. Confirm the billing address</h4>
                        </Col>
                    </Row>
                    {/* <Row className="justify-content-center">
                        <Col md={11}>
                            <h5>Step 1/2 - Please fill out the contact info and click <i>Sign Up</i> at the bottom</h5><br />
                        </Col>
                    </Row> */}
                    <Form.Row className="justify-content-center">
                        <Form.Group as={Col} md="5" controlId="validationFormik101">
                            <Form.Label>Country *</Form.Label>
                            <Form.Control
                                as="select"
                                name="country"
                                value={values.country}
                                onChange={handleChange}
                                isValid={touched.country && !errors.country}
                                isInvalid={touched.country && errors.country}
                                // placeholder="Choose your country"
                            >
                                <option value="">Select One</option>
                                <option value="CA">Canada</option>
                                <option value="US">United States</option>
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">{errors.country}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md={1}></Form.Group>
                        <Form.Group as={Col} md="5" controlId="validationFormik111">
                            <Form.Label>State/Province *</Form.Label>
                            <Form.Control
                                as="select"
                                name="state"
                                value={values.state}
                                onChange={handleChange}
                                // onChange={(option, e) => {
                                //     handleChange('state', option);
                                // }}
                                isValid={touched.state && !errors.state}
                                isInvalid={touched.state && errors.state}
                                // placeholder="First name"
                            >
                                <option value="">Select One</option>
                                <optgroup label="Canadian Provinces">
                                    <option value="AB">Alberta</option>
                                    <option value="BC">British Columbia</option>
                                    <option value="MB">Manitoba</option>
                                    <option value="NB">New Brunswick</option>
                                    <option value="NF">Newfoundland</option>
                                    <option value="NT">Northwest Territories</option>
                                    <option value="NS">Nova Scotia</option>
                                    <option value="NU">Nunavut</option>
                                    <option value="ON">Ontario</option>
                                    <option value="PE">Prince Edward Island</option>
                                    <option value="QC">Quebec</option>
                                    <option value="SK">Saskatchewan</option>
                                    <option value="YT">Yukon Territory</option>
                                </optgroup>
                                <optgroup label="U.S. States/Territories">
                                    <option value="AK">Alaska</option>
                                    <option value="AL">Alabama</option>
                                    <option value="AR">Arkansas</option>
                                    <option value="AZ">Arizona</option>
                                    <option value="CA">California</option>
                                    <option value="CO">Colorado</option>
                                    <option value="CT">Connecticut</option>
                                    <option value="DC">District of Columbia</option>
                                    <option value="DE">Delaware</option>
                                    <option value="FL">Florida</option>
                                    <option value="GA">Georgia</option>
                                    <option value="HI">Hawaii</option>
                                    <option value="IA">Iowa</option>
                                    <option value="ID">Idaho</option>
                                    <option value="IL">Illinois</option>
                                    <option value="IN">Indiana</option>
                                    <option value="KS">Kansas</option>
                                    <option value="KY">Kentucky</option>
                                    <option value="LA">Louisiana</option>
                                    <option value="MA">Massachusetts</option>
                                    <option value="MD">Maryland</option>
                                    <option value="ME">Maine</option>
                                    <option value="MI">Michigan</option>
                                    <option value="MN">Minnesota</option>
                                    <option value="MO">Missouri</option>
                                    <option value="MS">Mississippi</option>
                                    <option value="MT">Montana</option>
                                    <option value="NC">North Carolina</option>
                                    <option value="ND">North Dakota</option>
                                    <option value="NE">Nebraska</option>
                                    <option value="NH">New Hampshire</option>
                                    <option value="NJ">New Jersey</option>
                                    <option value="NM">New Mexico</option>
                                    <option value="NV">Nevada</option>
                                    <option value="NY">New York</option>
                                    <option value="OH">Ohio</option>
                                    <option value="OK">Oklahoma</option>
                                    <option value="OR">Oregon</option>
                                    <option value="PA">Pennsylvania</option>
                                    <option value="PR">Puerto Rico</option>
                                    <option value="RI">Rhode Island</option>
                                    <option value="SC">South Carolina</option>
                                    <option value="SD">South Dakota</option>
                                    <option value="TN">Tennessee</option>
                                    <option value="TX">Texas</option>
                                    <option value="UT">Utah</option>
                                    <option value="VA">Virginia</option>
                                    <option value="VT">Vermont</option>
                                    <option value="WA">Washington</option>
                                    <option value="WI">Wisconsin</option>
                                    <option value="WV">West Virginia</option>
                                    <option value="WY">Wyoming</option>
                                </optgroup>
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">{errors.state}</Form.Control.Feedback>
                        </Form.Group>

                    </Form.Row>

                    {/* <OverlayTrigger placement="top" overlay={<Tooltip id="password-tooltip">Must Contain 8+ Characters, One Uppercase, One Lowercase, One Number and one special case Character</Tooltip>}> */}
                    <Form.Row className="justify-content-center">
                        <Form.Group as={Col} md="5" controlId="validationFormik102">
                            <Form.Label>Address - line 1 *</Form.Label>
                            <Form.Control
                                type="text"
                                name="address_line1"
                                value={values.address_line1}
                                onChange={handleChange}
                                isValid={touched.address_line1 && !errors.address_line1}
                                isInvalid={touched.address_line1 && errors.address_line1}
                                placeholder="e.g., street, PO Box"

                            />
                            <Form.Control.Feedback type="invalid">{errors.address_line1}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md={1}></Form.Group>
                        <Form.Group as={Col} md="5" controlId="validationFormik103">
                            <Form.Label>Address - line 2</Form.Label>
                            <Form.Control
                                type="text"
                                name="address_line2"
                                value={values.address_line2}
                                onChange={handleChange}
                                isValid={touched.address_line2 && !errors.address_line2}
                                isInvalid={touched.address_line2 && errors.address_line2}
                                placeholder="e.g., suite, unit"
                            />
                            <Form.Control.Feedback type="invalid">{errors.address_line2}</Form.Control.Feedback>
                        </Form.Group>
                    </Form.Row>
                    {/* </OverlayTrigger> */}

                    <Form.Row className="justify-content-center">
                        <Form.Group as={Col} md="5" controlId="validationFormik104">
                            <Form.Label>Postal Code *</Form.Label>
                            <Form.Control
                                type="text"
                                name="postal_code"
                                value={values.postal_code}
                                onChange={handleChange}
                                isValid={touched.postal_code && !errors.postal_code}
                                isInvalid={touched.postal_code && errors.postal_code}
                                placeholder="Your postal code / zipcode"
                            />
                            <Form.Control.Feedback type="invalid">{errors.postal_code}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md={1}></Form.Group>
                        <Form.Group as={Col} md="5" controlId="validationFormik105">
                            <Form.Label>City *</Form.Label>
                            <Form.Control
                                type="text"
                                name="city"
                                value={values.city}
                                onChange={handleChange}
                                isValid={touched.city && !errors.city}
                                isInvalid={touched.city && errors.city}
                                placeholder="Your city"
                            />
                            <Form.Control.Feedback type="invalid">{errors.city}</Form.Control.Feedback>
                        </Form.Group>
                    </Form.Row>
                    <Row className="justify-content-center">
                        <Col md={9}><i>- required (*)</i></Col>
                        <Col md={2}><Button variant="success" type="submit" disabled={!isInteractive}>Next</Button></Col>
                    </Row>
                    <br />
                </Form>

                    </Col>
                </Row>
            )}
        </Formik>
    );
}

export default CreatePaymentCustomerForm;