import React, { useState } from "react"
import { Formik } from "formik"
import * as yup from "yup"
import { Form, Button, Col, Row } from "react-bootstrap";
import Grid from '@material-ui/core/Grid';

const schema = yup.object({
    email: yup
        .string()
        .email('Please enter a valid email address')
        .required('Email is required'),
    refId: yup
        .string()
        .min(1)
        .max(64)
        .required('Reference ID cannot be empty'),
    proximity: yup
        .bool(),
    note: yup
        .string()
        .min(0)
        .max(256),
    // .matches("^(?=.*[a-z])(?=.*[A-Z])(?=.*d)[a-zA-Zd]$")
    // .required(),
    terms: yup
        .bool()
        // .oneOf([true], 'Field must be checked')
        .oneOf([true], 'TOU must be consented')
    // .required(),
});

const CreateDPODForm = (props) => {
    const [proximityRequired, setProximityRequired] = useState(true);

    const handleToggle = () => {
        proximityRequired ? setProximityRequired(false) : setProximityRequired(true);
    }

    return (
        <Formik validationSchema={schema} onSubmit={props.value} initialValues={{ email: '', refId: '', proximity: true, note: '', terms: false }}>
            {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                isValid,
                errors,
            }) => (
                <Form noValidate onSubmit={handleSubmit}>
                    <Form.Row>
                        <Form.Group as={Col} md="8" controlId="validationFormik101">
                            <Form.Label>Recipient's email address</Form.Label>
                            <Form.Control
                                type="text"
                                name="email"
                                value={values.email}
                                onChange={handleChange}
                                isValid={touched.email && !errors.email}
                                isInvalid={touched.email && errors.email}
                            />
                            <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                            {/* <Form.Control.Feedback tooltip>Looks good!</Form.Control.Feedback> */}
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} md="6" controlId="validationFormik102">
                            <Form.Label>Reference ID (e.g. order number)</Form.Label>
                            <Form.Control
                                type="text"
                                name="refId"
                                value={values.refId}
                                onChange={handleChange}
                                isValid={touched.refId && !errors.refId}
                                isInvalid={touched.refId && errors.refId}
                            />
                            <Form.Control.Feedback type="invalid">{errors.refId}</Form.Control.Feedback>
                            {/* <Form.Control.Feedback tooltip>Looks good!</Form.Control.Feedback> */}
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} controlId="validationFormik103">
                            <Form.Label>Tag Type</Form.Label>
                            <Grid component="label" container alignItems="center" spacing={1}>
                                <Grid item>RT</Grid>
                                <Grid item>
                                    <Form.Switch
                                        name="proximity"
                                        value={values.proximity}
                                        // value={proximityRequired}
                                        checked={proximityRequired}
                                        // checked
                                        // onChange={(checked) => {
                                        //   console.log(checked);
                                        //   values.proximity = values.proximity ? false : true;
                                        //   console.log('changed to '+ values.proximity)}}
                                        onChange={() => {
                                            values.proximity = values.proximity ? false : true;
                                            handleToggle();
                                        }}
                                    />
                                </Grid>
                                <Grid item>RTV</Grid>
                            </Grid>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} controlId="validationFormik104">
                            <Form.Label>Note</Form.Label>
                            <Form.Control
                                type="text"
                                name="note"
                                value={values.note}
                                onChange={handleChange}
                                isValid={touched.note && !errors.note}
                            />
                            {/* <Form.Control.Feedback tooltip>Looks good!</Form.Control.Feedback> */}
                        </Form.Group>
                    </Form.Row>
                    <Form.Group>
                        <Form.Check
                            required
                            name="terms"
                            label="Agree to terms and conditions; I confirm the provided email address is correct."
                            onChange={handleChange}
                            isInvalid={!!errors.terms}
                            feedback={errors.terms}
                            id="validationFormik106"
                            feedbackTooltip
                        />
                    </Form.Group>
                    <Row>
                        <Col>&nbsp;</Col>
                        <Col>&nbsp;</Col>
                        <Col><Button type="submit">Start</Button></Col>
                    </Row>
                </Form>
            )}
        </Formik>
    );
}

// render(<CreateDPODForm />);

export default CreateDPODForm;