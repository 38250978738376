// import PropTypes from 'prop-types'
import React, { useState } from 'react'

// import COLORS from '../utils/colors'
// import { card as cardStyle } from '../utils/styles'

import { Formik } from "formik";
import * as yup from "yup";
import { Form, Button, Row, Col, Alert } from 'react-bootstrap';
// import { useAlert } from 'react-alert'

import TinyFlagReact from "tiny-flag-react";
// import PhoneFormatter from "react-headless-phone-input";
import PhoneFormatter from "react-headless-phone-input/lazy";

import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CheckIcon from '@material-ui/icons/Check';
import PhoneIcon from '@material-ui/icons/Phone';

import { colorPens } from '../utils/colors';

// yup.addMethod(yup.string, "phone", function (messageError = 'Phone number is not valid') {
//     const phoneRegExp = /^\+(?:[0-9] ?){6,14}[0-9]$/;
//     return this.test('phone', messageError, value => {
//         if (value && value.length > 0) {
//             return phoneRegExp.test(value)
//         }
//         return true
//     })
// })

const schema = yup.object({
    first_name: yup
        .string()
        .max(64)
        .required("First name is a required field"),
    last_name: yup
        .string()
        .max(64)
        .required("Last name is a required field"),
    company_name: yup
        .string()
        .max(128),
    consent: yup
        .bool()
        .oneOf([true], 'To use 11Klear, the customer must consent to our privacy policy and terms of use. To opt out, please request to delete your account.'),
    // phone_number: yup
    //     .string()
    //     .phone()
})

const AccountProfileForm = (props) => {
    let { user, isPartner, campaignName } = props;

    const [e164, setE164] = useState(user.get("phone_number"));
    const [e164Valid, setE164Valid] = useState(true);
    const [partnerConsented, setPartnerConsented] = useState(null);

    if (campaignName === 'partners' || user.get('consent_partner') === true) {
        isPartner = true;
    }

    return (
        <Formik
            validationSchema={schema}
            onSubmit={(values, actions) => {
                if (isPartner === true && values["consent_partner"] !== true) {
                    setPartnerConsented(false);
                    return false;
                } else {
                    setPartnerConsented(true);
                }

                values["phone_number"] = e164;
                if (e164Valid) {
                    props.onSubmit(values);
                } else {
                    return false;
                }
            }}
            initialValues={{
                first_name: user.get("first_name"),
                last_name: user.get("last_name"),
                company_name: user.get("company_name"),
                phone_number: user.get("phone_number"),
                consent_partner: user.get("consent_partner") !== undefined ? user.get("consent_partner") : false,
                consent_marketing: user.get("consent_marketing"),
                consent: user.get("consent")
            }}>
            {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                isValid,
                errors,
            }) => (
                <Row className="justify-content-center">
                    <Col md={8} style={{ backgroundColor: colorPens.light, border: '1px solid ' + colorPens.main, borderRadius: '5px' }}>
                        <Form noValidate onSubmit={handleSubmit}>
                            <Row className="justify-content-center">
                                <Col md={11}>
                                    <br />
                                    <h4>Account Profile</h4>
                                    <br />
                                </Col>
                            </Row>
                            {/* <Row className="justify-content-center">
                        <Col md="8"><Alert variant="light">Review the account details and click <i>Update</i> at the bottom to save the changes.</Alert></Col>
                    </Row> */}
                            <Row className="justify-content-center">
                                <Col md="5">Username (E-mail)</Col>
                                <Col md="6">{user.getUsername()}</Col>
                            </Row>
                            <br />
                            <Form.Row className="justify-content-center">
                                <Form.Group as={Col} md="5" controlId="validationFormik101">
                                    <Form.Label>First name *</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="first_name"
                                        value={values.first_name}
                                        onChange={handleChange}
                                        isValid={touched.first_name && !errors.first_name}
                                        isInvalid={touched.first_name && errors.first_name}
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.first_name}</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} md={1}></Form.Group>
                                <Form.Group as={Col} md="5" controlId="validationFormik102">
                                    <Form.Label>Last name *</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="last_name"
                                        value={values.last_name}
                                        onChange={handleChange}
                                        isValid={touched.last_name && !errors.last_name}
                                        isInvalid={touched.last_name && errors.last_name}
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.last_name}</Form.Control.Feedback>
                                </Form.Group>
                            </Form.Row>
                            <Form.Row className="justify-content-center">
                                <Form.Group as={Col} md="11" controlId="validationFormik103">
                                    <Form.Label>Phone number</Form.Label>
                                    <PhoneFormatter defaultCountry="CA" value={e164} onChange={setE164}>
                                        {({ country, impossible, onBlur, onInputChange, inputValue }) => {
                                            if (impossible) {
                                                setE164Valid(false);
                                            } else {
                                                setE164Valid(true);
                                            }

                                            return (
                                                <>
                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                        <span
                                                            style={{
                                                                fontSize: "24px",
                                                            }}>
                                                            {country ? (
                                                                <TinyFlagReact
                                                                    country={country}
                                                                    alt={country + " flag"}
                                                                    fallbackImageURL={`https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/${country}.svg`}
                                                                />
                                                            ) : (
                                                                <PhoneIcon fontSize="inherit" />
                                                            )}
                                                        </span>&nbsp;
                                                        <input
                                                            type="tel"
                                                            name="phone_number"
                                                            value={inputValue}
                                                            onBlur={(e) => {
                                                                onBlur(e);
                                                            }}
                                                            onChange={(e) => {
                                                                onInputChange(e.target.value);
                                                            }}
                                                        />
                                                    </div>
                                                    {inputValue !== null && impossible ? (
                                                        <Col style={{ color: "red", fontSize: 13 }}>Must be a valid phone number <ErrorOutlineIcon fontSize="small" /></Col>
                                                    ) : null}
                                                </>
                                            )
                                        }}
                                    </PhoneFormatter>
                                    <Form.Control.Feedback type="invalid">{errors.phone_number}</Form.Control.Feedback>
                                </Form.Group>
                            </Form.Row>

                            <Form.Row className="justify-content-center">
                                <Form.Group as={Col} md="11" controlId="validationFormik104">
                                    <Form.Label>Company Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="company_name"
                                        value={values.company_name}
                                        onChange={handleChange}
                                        isValid={touched.company_name && !errors.company_name}
                                        isInvalid={touched.company_name && errors.company_name}
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.company_name}</Form.Control.Feedback>
                                </Form.Group>
                            </Form.Row>
                            <Form.Row className="justify-content-center">
                                <Form.Group as={Col} md="11">
                                    <Form.Check
                                        required
                                        name="consent_marketing"
                                        checked={values.consent_marketing}
                                        label="Send me updates with tailored offers and productivity tips."
                                        onChange={handleChange}
                                        isInvalid={!!errors.consent_marketing}
                                        feedback={errors.consent_marketing}
                                        id="validationFormik106"
                                        feedbackTooltip
                                    />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row className="justify-content-center">
                                <Form.Group as={Col} md="11">
                                    <Form.Check
                                        required
                                        name="consent"
                                        checked={values.consent}
                                        label={(<>I read and consent to <a href="/terms" target="_blank">terms</a> and <a href='/policy' target="_blank">policies</a> of 11Klear. *</>)}
                                        onChange={handleChange}
                                        isInvalid={!!errors.consent}
                                        feedback={errors.consent}
                                        id="validationFormik107"
                                        feedbackTooltip
                                    />
                                </Form.Group>
                            </Form.Row>
                            {isPartner === true ? (
                                <Form.Row className="justify-content-center">
                                    <Form.Group as={Col} md="11">
                                        <Form.Check
                                            required
                                            name="consent_partner"
                                            // value={values.consent}
                                            checked={values.consent_partner}
                                            label={(<>I read and consent to 11Klear <a href="/partners/terms" target="_blank">Partner Program Agreement</a>. *</>)}
                                            onChange={handleChange}
                                            isInvalid={!!errors.consent_partner}
                                            feedback={errors.consent_partner}
                                            id="validationFormik108"
                                            feedbackTooltip
                                        />
                                    </Form.Group>
                                </Form.Row>
                            ) : null}
                            {(isPartner === true && partnerConsented === false) ? (
                                <Row className="justify-content-center">
                                    <Col md={11}><Alert variant="danger">To participate, you must consent to our 11Klear Partner Program Agreement.</Alert></Col>
                                </Row>
                            ) : null}

                            <br />
                            <Row className="justify-content-center">
                                <Col md={9}><i>- required (*)</i></Col>
                                <Col md={2}><Button type="submit">Update</Button></Col>
                            </Row>
                            <br />
                        </Form>

                    </Col>
                </Row>
            )}
        </Formik>
    );
}

// const AccessToken = ({ token }) => {
//     const codeStyle = {
//         fontFamily: 'monospace',
//         wordWrap: 'break-word',
//         margin: '.5em 0',
//         padding: '.5em',
//         fontSize: '85%',
//         backgroundColor: 'rgba(27,31,35,0.05)',
//         borderRadius: '3px'
//     }

//     return (
//         <div style={{ fontSize: '.8rem' }}>
//             <label style={{ color: COLORS.lightColor, paddingRight: '.25rem' }}>Access token :</label>
//             <div style={codeStyle}>{token}</div>
//         </div>
//     )
// }

export default AccountProfileForm;