import React, { Component } from "react";
import { Container, Row, Col, Image, Alert, Card, Badge } from 'react-bootstrap';
import CheckIcon from '@material-ui/icons/Check';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'; import { Button } from '@material-ui/core';
import DeveloperModeIcon from '@material-ui/icons/DeveloperMode';
import DeveloperBoardIcon from '@material-ui/icons/DeveloperBoard';
import MoneyOffIcon from '@material-ui/icons/MoneyOff';
import BusinessIcon from '@material-ui/icons/Business';
import BusinessOutlinedIcon from '@material-ui/icons/BusinessOutlined';
import MoneyOffOutlinedIcon from '@material-ui/icons/MoneyOffOutlined';
import DeveloperBoardOutlinedIcon from '@material-ui/icons/DeveloperBoardOutlined';

import ContactMailOutlinedIcon from '@material-ui/icons/ContactMailOutlined';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import MouseOutlinedIcon from '@material-ui/icons/MouseOutlined';
import CodeOutlinedIcon from '@material-ui/icons/CodeOutlined';
import ArrowRightOutlinedIcon from '@material-ui/icons/ArrowRightOutlined';

import { colorPens } from './utils/colors';
import TryNow from './components/tryNow';

var Parse = require('parse');

// const TEST_API_KEY = 'sk_test_2D485FAC91B9BCC4B6B130AB799FBD0E524A07750C48189D';
// const TEST_API_KEY = 'sk_test_1A1E8881DB323DED9B3961CDF4E7C01791E75A9869B7F3C4'; // Bill's test api
const TEST_API_KEY = 'your secret key';

class UserGuide extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loggedInUser: false,
            userEmail: null,
        }
    }

    componentDidMount() {
        let currentUser = Parse.User.current();
        if (currentUser === null) {
            // window.location.href = '/';
            setTimeout(() => {
                this.props.history.push({ pathname: '/account/profile', state: { target: this.props.location } });
            }, 3000);
        } else {
            this.setState({ loggedInUser: true, userEmail: currentUser.get("email") });
        }
    }

    render() {
        if (this.props.match.params.type === 'api') {
            return (this.state.loggedInUser ?
                <Container fluid>
                    <p></p>
                    <Row className="justify-content-center">
                        <Col md={2} align="center">
                            {/* <span style={{ color: colorPens.dark, fontWeight: 'bold' }}></span> */}
                            <h4 style={{ color: colorPens.info }}>Introduction</h4>
                        </Col>
                        <Col md={10}>
                            {/* <h5><b>Introduction</b></h5> */}
                            11Klear API is built around REST, and it returns JSON-encoded responses with standard HTTP response codes.<br />
                        </Col>
                    </Row>
                    <br />
                    <Row className="justify-content-center">
                        <Col md={2} align="center">
                            {/* <span style={{ color: colorPens.dark, fontWeight: 'bold' }}>Authentication</span> */}
                            <h4 style={{ color: colorPens.info }}>Authentication</h4>
                        </Col>
                        <Col md={5}>
                            {/* <h5><b>Introduction</b></h5> */}
                            You can view and manage your API keys under <i><b>Customers > API Access</b></i> menu.<br /><br />
                            The secret key with the prefix <i><b>sk_live_</b></i> is the live mode key, and this key carry full privileges on the live system.
                            Authentication to the API is performed via HTTP Basic Auth without a password. Use your API key as the basic auth username value. Here is an example of pinging our server status.<br /><br />
                            {/* All the following API examples will use the sandbox test API key. Though you can try the example as-is, the result may not be reflected in your dashboard. To try out, substitute the displayed test key with your own API key.<br /><br /> */}
                            To try out, substitute "<u>your secret key</u>" with your own API key.<br /><br />
                        </Col>
                        <Col md={5}>
                            <Card>
                                <Card.Header><b>GET</b> /v1/ping</Card.Header>
                                <Card.Body>
                                    <code style={{ color: colorPens.dark, fontSize: '0.8em' }}>
                                        $ curl {process.env.REACT_APP_API_ENDPOINT}/v1/ping \<br />
                                        &nbsp;&nbsp;&nbsp;&nbsp;-u <u>{TEST_API_KEY}</u>:
                                    </code>
                                </Card.Body>
                                <Card.Footer className="text-muted">Ending with the colon makes no password request.</Card.Footer>
                            </Card><br />
                            <Card>
                                <Card.Header><b>RESPONSE</b></Card.Header>
                                <Card.Body>
                                    <code style={{ color: colorPens.dark, fontSize: '0.8em' }}>
                                        &#123;<br />
                                        &nbsp;&nbsp;&nbsp;&nbsp;"timestamp": "1592144068000",<br />
                                        &nbsp;&nbsp;&nbsp;&nbsp;"message": "pong"<br />
                                        &#125;
                                    </code>
                                </Card.Body>
                                <Card.Footer className="text-muted"><b>timestamp</b> is in milliseconds.</Card.Footer>
                            </Card><br />
                        </Col>
                    </Row>
                    <br />
                    <Row className="justify-content-center">
                        <Col md={2} align="center">
                            {/* <span style={{ color: colorPens.dark, fontWeight: 'bold' }}>Issuing a new tag</span> */}
                            <h4 style={{ color: colorPens.info }}>Create a new tag</h4>
                        </Col>
                        <Col md={5}>
                            {/* <h5><b>Introduction</b></h5> */}
                            Issues a 11Klear tag object.<br /><br />
                            <h6>Parameters</h6>
                            <hr />
                            <b>recipient_email</b> <Badge variant="warning">REQUIRED</Badge><br />
                            Email address of the recipient. If the email hits the hard bounce, the tag property <i><b>recipient_email_valid</b></i> will be updated to <i>false</i> asynchronously, and the API user would need to follow this.<br /><br />
                            <Alert variant="danger">Because the tag activation email is sent immediately and irrevocably, the API user must make sure the provided address is correct and the chain of consents is maintained.</Alert>
                            <hr />
                            <b>reference_id</b> <Badge variant="warning">REQUIRED</Badge><br />
                            Reference ID for your internal purposes.
                            <hr />
                            <b>proximity_required</b> <Badge variant="warning">REQUIRED</Badge><br />
                            Set to <i>true</i> for <b>RTV</b> tags. Otherwise, set to <i>false</i>.
                            <hr />
                            <b>note</b> <Badge variant="light">optional</Badge><br />
                            Remarks to display when the delivery confirmation request is sent to the recipient.
                            <br /><br />
                        </Col>
                        <Col md={5}>
                            <Card>
                                <Card.Header><b>POST</b> /v1/tags</Card.Header>
                                <Card.Body>
                                    <code style={{ color: colorPens.dark, fontSize: '0.8em' }}>
                                        $ curl {process.env.REACT_APP_API_ENDPOINT}/v1/tags \<br />
                                        &nbsp;&nbsp;&nbsp;&nbsp;-u <u>{TEST_API_KEY}</u>: \<br />
                                        &nbsp;&nbsp;&nbsp;&nbsp;-H 'Content-Type: application/json' \<br />
                                        &nbsp;&nbsp;&nbsp;&nbsp;-d '&#123; "recipient_email": "{this.state.userEmail}", "reference_id": "ord_2020mar059", "note": "New Hammock", "proximity_required": true &#125;'
                                    </code>
                                </Card.Body>
                                {/* <Card.Footer className="text-muted">Ending with the colon makes no password request.</Card.Footer> */}
                            </Card><br />
                            <Card>
                                <Card.Header><b>RESPONSE</b></Card.Header>
                                <Card.Body>
                                    <code style={{ color: colorPens.dark, fontSize: '0.8em' }}>
                                        &#123;<br />
                                        &nbsp;&nbsp;&nbsp;&nbsp;"id": "10293817265615283749",<br />
                                        &nbsp;&nbsp;&nbsp;&nbsp;"object": "tag"<br />
                                        &#125;
                                    </code>
                                </Card.Body>
                                <Card.Footer className="text-muted">Use this tag <b>id</b> to query details/updates in the future and associate with your own  internal IDs.</Card.Footer>
                            </Card><br />
                        </Col>
                    </Row>
                    <br />
                    <Row className="justify-content-center">
                        <Col md={2} align="center">
                            {/* <span style={{ color: colorPens.dark, fontWeight: 'bold' }}>Issuing a new tag</span> */}
                            <h4 style={{ color: colorPens.info }}>Retrieve a tag</h4>
                        </Col>
                        <Col md={5}>
                            {/* <h5><b>Introduction</b></h5> */}
                            Retrieves the details of a 11Klear tag object.<br /><br />
                            {/* <h6>Parameters</h6>
                            <hr />
                            <i>none</i>
                            <br /><br /> */}
                            {/* <h6>Remarks</h6> */}
                            {/* <hr /> */}
                            <Alert variant="warning">
                                You may find <i><b>recipient_email_valid</b></i> property is set to <i>false</i> when the activation email to the recipient has bounced back.<br /><br />For using this tag for the delivery confirmation, you must update it with the correct recipient email address. To update, use <i>/v1/tags/:id/update</i>
                            </Alert>

                            <br /><br />
                        </Col>
                        <Col md={5}>
                            <Card>
                                <Card.Header><b>GET</b> /v1/tags/:id</Card.Header>
                                <Card.Body>
                                    <code style={{ color: colorPens.dark, fontSize: '0.8em' }}>
                                        $ curl {process.env.REACT_APP_API_ENDPOINT}/v1/tags/10293817265615283749 \<br />
                                        &nbsp;&nbsp;&nbsp;&nbsp;-u <u>{TEST_API_KEY}</u>:
                                    </code>
                                </Card.Body>
                                {/* <Card.Footer className="text-muted">Ending with the colon makes no password request.</Card.Footer> */}
                            </Card><br />
                            <Card>
                                <Card.Header><b>RESPONSE</b></Card.Header>
                                <Card.Body>
                                    <code style={{ color: colorPens.dark, fontSize: '0.8em' }}>
                                        &#123;<br />
                                        &nbsp;&nbsp;&nbsp;&nbsp;"id": "10293817265615283749",<br />
                                        &nbsp;&nbsp;&nbsp;&nbsp;"object": "tag",<br />
                                        &nbsp;&nbsp;&nbsp;&nbsp;"created": 1591983559,<br />
                                        &nbsp;&nbsp;&nbsp;&nbsp;"reference_id": "ord_2020mar059",<br />
                                        &nbsp;&nbsp;&nbsp;&nbsp;"note": "New Hammock",<br />
                                        &nbsp;&nbsp;&nbsp;&nbsp;"proximity_required": true,<br />
                                        &nbsp;&nbsp;&nbsp;&nbsp;"recipient_email": "{this.state.userEmail}",<br />
                                        &nbsp;&nbsp;&nbsp;&nbsp;"recipient_email_valid": true,<br />
                                        &nbsp;&nbsp;&nbsp;&nbsp;"confirmation_request_url": "{process.env.REACT_APP_MOBILE_APP_LAUNCH_ENDPOINT}/checkin/10293817265615283749/1",<br />
                                        &#125;
                                    </code>
                                </Card.Body>
                                <Card.Footer className="text-muted">Use this tag <b>confirmation_request_url</b> to launch the delivery confirmation request screen on 11Klear mobile app. You may send or display this link for your delivery personnel.</Card.Footer>
                            </Card><br />

                        </Col>
                    </Row>
                    <br />
                    <Row className="justify-content-center">
                        <Col md={2} align="center">
                            {/* <span style={{ color: colorPens.dark, fontWeight: 'bold' }}>Issuing a new tag</span> */}
                            <h4 style={{ color: colorPens.info }}>Print a tag</h4>
                        </Col>
                        <Col md={5}>
                            {/* <h5><b>Introduction</b></h5> */}
                            Downloads a fully populated 11Klear tag in a PDF file.<br /><br />
                            {/* <h6>Parameters</h6>
                            <hr />
                            <i>none</i>
                            <br /><br /> */}
                            {/* <h6>Remarks</h6> */}
                            {/* <hr /> */}
                            {/* <Alert variant="warning">
                                You may find <i><b>recipient_email_valid</b></i> property is set to <i>false</i> when the activation email to the recipient has bounced back.<br /><br />For using this tag for the delivery confirmation, you must update it with the correct recipient email address. To update, use <i>/v1/tags/:id/update</i>
                            </Alert> */}

                            <br /><br />
                        </Col>
                        <Col md={5}>
                            <Card>
                                <Card.Header><b>GET</b> /v1/tags/:id/pdf</Card.Header>
                                <Card.Body>
                                    <code style={{ color: colorPens.dark, fontSize: '0.8em' }}>
                                        $ curl {process.env.REACT_APP_API_ENDPOINT}/v1/tags/10293817265615283749/pdf \<br />
                                        &nbsp;&nbsp;&nbsp;&nbsp;-u <u>{TEST_API_KEY}</u>:
                                    </code>
                                </Card.Body>
                                {/* <Card.Footer className="text-muted">Ending with the colon makes no password request.</Card.Footer> */}
                            </Card><br />
                            <Card>
                                <Card.Header><b>RESPONSE</b></Card.Header>
                                <Card.Body>
                                    PDF file
                                </Card.Body>
                                <Card.Footer className="text-muted">Use this 11Klear tag to print and attach to the delivery package as needed.</Card.Footer>
                            </Card><br />
                        </Col>
                    </Row>
                    <br />
                    <Row className="justify-content-center">
                        <Col md={2} align="center">
                            {/* <span style={{ color: colorPens.dark, fontWeight: 'bold' }}>Issuing a new tag</span> */}
                            <h4 style={{ color: colorPens.info }}>Update a tag</h4>
                        </Col>
                        <Col md={5}>
                            Updates the details of a 11Klear tag object.<br /><br />
                            <h6>Parameters</h6>
                            <hr />
                            <b>recipient_email</b> <Badge variant="light">optional</Badge> <i>(default: unchanged)</i><br />
                            Email address of the recipient. If the email hits the hard bounce, the tag property <i><b>recipient_email_valid</b></i> will be updated to <i>false</i> asynchronously, and the API user would need to follow this.<br /><br />
                            <Alert variant="danger">Updating the email address will trigger the 11Klear key renewal process. This involves revoking the old key and sending a <u>new activation email</u> to the recipient immediately and irrevocably; the API user must make sure the provided address is correct and the chain of consents is maintained.</Alert>
                            <hr />
                            <b>reference_id</b> <Badge variant="light">optional</Badge> <i>(default: unchanged)</i><br />
                            Reference ID for your internal purposes.
                            <hr />
                            <b>note</b> <Badge variant="light">optional</Badge> <i>(default: unchanged)</i><br />
                            Remarks to display when the delivery confirmation request is sent to the recipient.
                            <br /><br />
                            <Alert variant="warning">
                                When updating only <i><b>reference_id</b></i> and/or <i><b>note</b></i> on the existing 11Klear tag object, the current 11Klear key (recipient activation) will not be affected.
                            </Alert>
                            <br /><br />
                        </Col>
                        <Col md={5}>
                            <Card>
                                <Card.Header><b>POST</b> /v1/tags/:id/update</Card.Header>
                                <Card.Body>
                                    <code style={{ color: colorPens.dark, fontSize: '0.8em' }}>
                                        $ curl {process.env.REACT_APP_API_ENDPOINT}/v1/tags/10293817265615283749/update \<br />
                                        &nbsp;&nbsp;&nbsp;&nbsp;-u <u>{TEST_API_KEY}</u>: \<br />
                                        &nbsp;&nbsp;&nbsp;&nbsp;-H 'Content-Type: application/json' \<br />
                                        &nbsp;&nbsp;&nbsp;&nbsp;-d '&#123; "recipient_email": "{this.state.userEmail}", "reference_id": "ord_2020mar059;fulfill-shipperA", "note": "Enjoy your New Hammock" &#125;'
                                    </code>
                                </Card.Body>
                                {/* <Card.Footer className="text-muted">Ending with the colon makes no password request.</Card.Footer> */}
                            </Card><br />
                            <Card>
                                <Card.Header><b>RESPONSE</b></Card.Header>
                                <Card.Body>
                                    <code style={{ color: colorPens.dark, fontSize: '0.8em' }}>
                                        &#123;<br />
                                        &nbsp;&nbsp;&nbsp;&nbsp;"id": "10293817265615283749",<br />
                                        &nbsp;&nbsp;&nbsp;&nbsp;"object": "tag",<br />
                                        &nbsp;&nbsp;&nbsp;&nbsp;"recipient_email": "{this.state.userEmail}",<br />
                                        &nbsp;&nbsp;&nbsp;&nbsp;"reference_id": "ord_2020mar059;fulfill-shipperA",<br />
                                        &nbsp;&nbsp;&nbsp;&nbsp;"note": "Enjoy your New Hammock",<br />
                                        &#125;
                                    </code>
                                </Card.Body>
                                <Card.Footer className="text-muted">Use the tag <b>reference_id</b> to manage delivery-specific metadata.</Card.Footer>
                            </Card><br />
                        </Col>
                    </Row>
                    <br />
                    <Row className="justify-content-center">
                        <Col md={2} align="center">
                            {/* <span style={{ color: colorPens.dark, fontWeight: 'bold' }}>Issuing a new tag</span> */}
                            <h4 style={{ color: colorPens.info }}>Trace a tag</h4>
                        </Col>
                        <Col md={5}>
                            Trace the timeline of a 11Klear tag object.<br /><br />
                            <h6>Parameters</h6>
                            <hr />
                            <b>limit</b> <Badge variant="light">optional</Badge> <i>(default: 50)</i><br />
                            Number of latest transaction history for the requested 11Klear tag object.<br />
                            List of timestamp(UTC) and human-readable event code pairs is returned in <i><b>history</b></i> property.
                            <br /><br />
                        </Col>
                        <Col md={5}>
                            <Card>
                                <Card.Header><b>GET</b> /v1/tags/:id/trace</Card.Header>
                                <Card.Body>
                                    <code style={{ color: colorPens.dark, fontSize: '0.8em' }}>
                                        $ curl {process.env.REACT_APP_API_ENDPOINT}/v1/tags/10293817265615283749/trace \<br />
                                        &nbsp;&nbsp;&nbsp;&nbsp;-u <u>{TEST_API_KEY}</u>:<br />
                                        &nbsp;&nbsp;&nbsp;&nbsp;-d limit=2
                                    </code>
                                </Card.Body>
                                {/* <Card.Footer className="text-muted">Ending with the colon makes no password request.</Card.Footer> */}
                            </Card><br />
                            <Card>
                                <Card.Header><b>RESPONSE</b></Card.Header>
                                <Card.Body>
                                <code style={{ color: colorPens.dark, fontSize: '0.8em' }}>
                                    &#123;<br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;"id": "10293817265615283749", \<br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;"object": "tag", \<br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;"history": [[1617036070,"FIN P-CONFIRM"],[1617036068,"C-FIN"]] \<br />
                                    &#125;
                                    </code>
                                </Card.Body>
                                <Card.Footer className="text-muted">Use this tag <b>history</b> to audit each event with the matching timestamp.</Card.Footer>
                            </Card><br />

                        </Col>
                    </Row>
                    <br />
                </Container> :
                <Container>
                    <br />
                    <Row className="justify-content-center">
                        <Alert variant="warning">
                            <h5>Authorization Required. Redirecting to the sign-in page in 3 seconds...</h5>
                        </Alert>
                    </Row>
                </Container>
            );
        } else {
            return (this.state.loggedInUser ?
                <Container fluid>
                    <p></p>
                    <Row className="justify-content-center">
                        <Col md={2} align="center">
                            <ContactMailOutlinedIcon style={{ color: colorPens.dark, fontSize: 55 }} />
                        </Col>
                        <Col md={8}>
                            <h5><b>User Guide (v1.0) 2021.5.2</b></h5>
                            <ArrowRightOutlinedIcon /> <a href={process.env.PUBLIC_URL + '/downloads/11Klear_User_Guide_V1.0.pdf' + '?' + Date.now()} target="_blank">Download</a><br />
                        </Col>
                    </Row>
                    <br />
                    <Row className="justify-content-center">
                        <Col md={2} align="center">
                            <MouseOutlinedIcon style={{ color: colorPens.dark, fontSize: 55 }} />
                        </Col>
                        <Col md={8}>
                            <h5><b>Quick Start Guide (v1.0) 2021.5.2</b></h5>
                            <ArrowRightOutlinedIcon /> <a href={process.env.PUBLIC_URL + '/downloads/11Klear_Quick_Start_Guide_V1.0.pdf' + '?' + Date.now()} target="_blank">Download</a><br />
                        </Col>
                    </Row>
                    <br />
                    <Row className="justify-content-center">
                        <Col md={2} align="center">
                            <CodeOutlinedIcon style={{ color: colorPens.dark, fontSize: 55 }} />
                        </Col>
                        <Col md={8}>
                            <h5><b>API Guide (v1.0-preview1) 2021.7.7</b></h5>
                            <ArrowRightOutlinedIcon /> <a href={process.env.PUBLIC_URL + '/userguide/api'}>API Reference (latest)</a><br />
                        </Col>
                    </Row>
                    <br />
                    {/* <br />
        <Row className="justify-content-center">
          <Col md={2} align="center">
            <Image src={process.env.PUBLIC_URL+'/images/details/icon_faq_pie.svg'} style={{ width: 100 }} fluid />
          </Col>
          <Col md={8}>
            <h4><b>E-merchants and their customers need and want better contactless solutions!</b></h4>
            <CheckBoxOutlineBlankIcon /> 70% of consumers want a contactless digital alternative to proof-of-delivery signatures for their higher-value packages<br />
            <CheckBoxOutlineBlankIcon /> 49% of adults are uncomfortable with personal interaction even after Covid vaccination <br />
            <br />
            <h5>Get the tools you need to provide excellent contactless delivery service.</h5>
          </Col>
        </Row> */}
                    <br />
                    <TryNow />
                    <br />
                </Container> :
                <Container>
                    <br />
                    <Row className="justify-content-center">
                        <Alert variant="warning">
                            <h5>Authorization Required. Redirecting to the sign-in page in 3 seconds...</h5>
                        </Alert>
                    </Row>
                </Container>
            );
        }
    }
}

export default UserGuide;