import React, { Component, useContext } from 'react';

import { Button, Alert, Container, Row, Col } from 'react-bootstrap';

import { Auth } from 'aws-amplify';
import { AmplifyAuthenticator, AmplifySignIn } from '@aws-amplify/ui-react';
import { AuthState } from '@aws-amplify/ui-components';

import '@aws-amplify/ui/dist/style.css';
import { UserContext } from './components/userContext';

import AccountProfileForm from './components/accountCard';
import CreateAccountForm from './components/accountSignUpCard';
import ConfirmSignUpForm from './components/accountConfirmSignUpCard';
import SignInForm from './components/accountSignInCard';
import ResetPasswordForm from './components/accountResetPasswordCard';
import ConfirmResetPasswordForm from './components/accountConfirmResetPasswordCard';

import queryString from 'query-string';
import PreorderAccountForm from './components/accountPreorderCard';
import TagManager from 'react-gtm-module';

// const tagManagerArgs = {
//     gtmId: 'AW-10862967003'
//     // gtmId: 'GTM-599D7XB'
// }

// TagManager.initialize(tagManagerArgs)

// - Reference
// https://docs.amplify.aws/ui/auth/authenticator/q/framework/react#authstate

var Parse = require('parse');

class Account extends Component {
    _isMounted = false;
    _postLoginInProgress = false;
    _postSignUpProgress = false;
    // _isSigningUp = false;
    // _userContext = useContext(UserContext);

    constructor(props) {
        super(props)

        this.state = {
            logged: false,
            userToken: null,
            currentUser: null,
            isPartner: null,
            currentProvider: '',
            currentAuthState: null,
            authInProgress: false,
            action: 'profile',
            errorMessage: null,
            needToConfirmSignUp: false,
            ca_formData: {}
        }

        this.onLoginSuccess = this.onLoginSuccess.bind(this)
        this.onLoginFailure = this.onLoginFailure.bind(this)
        this.onLogoutSuccess = this.onLogoutSuccess.bind(this)
        this.onLogoutFailure = this.onLogoutFailure.bind(this)
        this.logout = this.logout.bind(this)
    }

    handleAuthStateChange = (nextAuthState, authData) => {
        if (this.props.match.params.action === 'logout') {
            // No need to log back in
            this.setState({ authInProgress: true });
            return true;
        }

        if (nextAuthState === AuthState.SignedIn && this.state.currentAuthState !== nextAuthState) {
            console.log(' ... handleAuthStateChange has been called; nextAuthState=', nextAuthState);
            this.setState({ currentAuthState: nextAuthState });
            console.log("user successfully signed in!");
            console.log("user data: ", authData);
            this.onCognitoLoginSuccess(authData);
        }
        // if (authData) {
        //     console.log("authData: ", authData);
        // }
    };

    checkUser() {
        Auth.currentAuthenticatedUser()
            .then(user => console.log({ user }))
            .catch(err => console.log(err));
    }

    signOut() {
        Auth.signOut()
            .then(data => console.log(data))
            .catch(err => console.log(err));
    }

    loginWithBackendAPI = async (data) => {
        // var data = { data: 'wow you made it!' }
        const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/v1/login`, {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json'
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify(data)
        });

        if (response.status !== 200) {
            // console.log('hmm => '+ JSON.stringify(response));
            throw Error({ status: response.status, message: response.statusText })
        }

        const body = await response.json();
        return body;
    };

    logoutWithBackendAPI = async (data) => {
        const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/v1/logout`, {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json'
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify(data)
        });

        if (response.ok) {
            let body = null;
            // let body = await response.json();
            return body;
        } else {
            console.log('non-OK response.????');
            throw Error(response.statusText);
        }
    };

    registerRefererWithBackendAPI = async (data) => {
        // var data = { data: 'wow you made it!' }
        const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/v1/add-referred`, {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json'
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify(data)
        });

        if (response.status !== 200) {
            // console.log('hmm => '+ JSON.stringify(response));
            throw Error({ status: response.status, message: response.statusText })
        }

        const body = await response.json();
        return body;
    };

    onLoggedIn(provider, currentUser) {
        // let currentUser = Parse.User.current();
        console.log('identity established...');
        // console.log('currentUserToken => ' + JSON.stringify(currentUser.get("sessionToken")));
        if (currentUser) {
            this.setState({
                logged: true,
                provider: provider,
                authInProgress: false,
                currentUser: currentUser,
                isPartner: currentUser.get("consent_partner")
            });
            this.props.user.setLoginState(true, currentUser.get("consent_partner"));
            // this.props.user.setPartnerState(currentUser.get("consent_partner"));
            console.log(`consent_partner = ${currentUser.get("consent_partner")}`);

            try {
                if (this.props.location.state.target &&
                    currentUser.get("consent") === true &&
                    (this.props.match.params.campaign !== 'partners' ||
                        (this.props.match.params.campaign === 'partners' && currentUser.get("consent_partner") === true)
                    )
                ) {
                    this.props.history.push({ pathname: this.props.location.state.target.pathname });
                }
            } catch {
                // none
            }
        }
    }

    async onCognitoLoginSuccess(cognitoUser) {
        this._postLoginInProgress = true;
        // this.setState({ authInProgress: true });

        let retrievedIdToken = cognitoUser["signInUserSession"].idToken;
        let data = {
            provider: 'cognito',
            id: retrievedIdToken.payload['sub'],
            email: retrievedIdToken.payload['email'],
            id_token: retrievedIdToken.jwtToken
        }

        try {
            let needToReload = true;
            let currentUser;

            try {
                // let currentSession = await Parse.Session.current();
                // console.log(`current session retrieved => ${currentSession}`);

                currentUser = await Parse.User.currentAsync();
                if (currentUser !== null) {
                    let currentAuthData = currentUser.get("authData")["cognito"];
                    if (currentAuthData["id"] === retrievedIdToken.payload['sub'] || currentAuthData["token"] === retrievedIdToken.jwtToken) {
                        needToReload = false;
                    }
                }

            } catch (error) {
                //needToReload = true;
            }

            if (needToReload) {
                // If the current user session is obsolete on the server side, sync it
                await Parse.User.logOut();

                // Login with the backend and register the session
                let res = await this.loginWithBackendAPI(data);
                currentUser = await Parse.User.become(res.sessionToken);

                // Update the details accordingly
                if (retrievedIdToken.payload['given_name']) {
                    currentUser.set("first_name", retrievedIdToken.payload['given_name']);
                }
                if (retrievedIdToken.payload['family_name']) {
                    currentUser.set("last_name", retrievedIdToken.payload['family_name']);
                }
                if (retrievedIdToken.payload['phone_number']) {
                    currentUser.set("phone_number", retrievedIdToken.payload['phone_number']);
                }
                if (retrievedIdToken.payload['custom:company_name']) {
                    currentUser.set("company_name", retrievedIdToken.payload['custom:company_name']);
                }
                if (retrievedIdToken.payload['custom:campaign_identifier']) {
                    if (currentUser.get("referer") === undefined) {
                        // update the referer on the local database only when there is none registered, yet.
                        let splitReferer = retrievedIdToken.payload['custom:campaign_identifier'].split('/');
                        let referer = splitReferer[0];
                        if (splitReferer.length === 3 && referer === 'referral') {
                            let referer = splitReferer[0];
                            currentUser.set("referer", referer);
                            if (referer !== '11Klear') {
                                await this.registerRefererWithBackendAPI({ referer: referer });
                            }
                        }
                    }
                }
                if (retrievedIdToken.payload['custom:consent_partner']) {
                    currentUser.set("consent_partner", parseInt(retrievedIdToken.payload['custom:consent_partner']) === 1 ? true : false);
                }
                if (retrievedIdToken.payload['custom:consent_marketing']) {
                    currentUser.set("consent_marketing", parseInt(retrievedIdToken.payload['custom:consent_marketing']) === 1 ? true : false);
                }
                if (retrievedIdToken.payload['custom:consent']) {
                    currentUser.set("consent", parseInt(retrievedIdToken.payload['custom:consent']) === 1 ? true : false);
                }
                currentUser.set("mobile", false);
                currentUser.save();

            } else {
                console.log(` ... currentUser is valid; skipping re-entry`);
            }

            this.onLoggedIn(data._provider, currentUser);
        } catch (error) {
            console.log('error caught while loggin in with the backend API => ' + error);
        } finally {
            this._postLoginInProgress = false;
        }
    }

    onLoginSuccess(user) {
        // user._provider
        // console.log(user._profile['id']);
        // console.log(user._token['idToken']);
        // make an API call to login with this info

        // this.context.loginUser(user);
        // this.context.

        let data = {
            provider: user._provider,
            id: user._profile['id'],
            email: user.profile['email'],
            id_token: user._token['idToken']
        }

        // console.log('data for loginWithBackendAPI '+ JSON.stringify(data));

        this.loginWithBackendAPI(data)
            .then(res => {
                // console.log(res);

                Parse.User.become(res.sessionToken).then(
                    (parseUser) => {
                        this.onLoggedIn(data._provider, parseUser)
                    }, (error) => {
                        // The token could not be validated.
                        console.log('error caught while setting up the current parseUser: ' + error);
                    });
            })
            .catch(err => console.log('error caught while loggin in with the backend API => ' + JSON.stringify(err)));


        // this.props.history.push('/dashboard');
        // don't use this as this will remove the session
        // window.location.href = '/';
    }

    onLoginFailure(err) {
        console.error(err)

        this.setState({
            logged: false,
            currentProvider: '',
            user: {}
        })
    }

    onLogoutSuccess() {
        this.setState({
            logged: false,
            currentProvider: '',
            user: {}
        })
    }

    onLogoutFailure(err) {
        console.error(err)
    }

    async logout() {
        // const { logged, currentProvider } = this.state

        try {
            let data = {}
            await this.logoutWithBackendAPI(data);
            await Parse.User.logOut();
            Auth.signOut();

            this.props.user.setLoginState(false, false);
            // this.props.user.setPartnerState(false);
            this.setState({ logged: false, authInProgress: false });
            this.props.history.push('/');
        } catch (error) {
            console.log('error caught while loggin OUT with the backend API => ' + error);
        }

        // let data = {}
        // this.logoutWithBackendAPI(data)
        //     .then(res => {
        //         // clear the parseUser
        //         Parse.User.logOut().then(() => {
        //             this.signOut();
        //             // console.log('current parseUser after logged OUT => ' + Parse.User.current());
        //             this.props.user.setLoginState(false);
        //             this.setState({ logged: false, authInProgress: false });

        //             // window.location.href = '/';
        //             this.props.history.push('/');
        //             // this.props.history.replaceState(null, '', '/');
        //         });
        //     })
        //     .catch(err => {
        //     console.log('error caught while loggin OUT with the backend API => ' + error);
        //     });
    }

    async handleUpdateAccountSubmit(currentUser, data) {
        console.log(`entered data => ${JSON.stringify(data)}`);

        // let currentUser = Parse.User.current();
        // Parse.User.currentAsync().then((currentUser) => {
        try {
            console.log(`currentSessionToken= ${JSON.stringify(currentUser.get("sessionToken"))}`);

            currentUser.set("first_name", data.first_name);
            currentUser.set("last_name", data.last_name);
            currentUser.set("company_name", data.company_name);
            currentUser.set("phone_number", data.phone_number);
            currentUser.set("consent_partner", data.consent_partner);
            currentUser.set("consent_marketing", data.consent_marketing);
            currentUser.set("consent", data.consent);
            currentUser.save();


            let first_name = data.first_name ? data.first_name : '';
            let last_name = data.last_name ? data.last_name : '';
            let company_name = data.company_name ? data.company_name : '';
            let phone_number = data.phone_number ? data.phone_number : '';
            let consent_partner = data.consent_partner ? '1' : '0';
            let consent_marketing = data.consent_marketing ? '1' : '0';
            let consent = data.consent ? '1' : '0';

            let user_cognito = await Auth.currentAuthenticatedUser();

            await Auth.updateUserAttributes(user_cognito, {
                'given_name': first_name,
                'family_name': last_name,
                'phone_number': phone_number,
                'custom:company_name': company_name,
                'custom:consent_partner': consent_partner,
                'custom:consent_marketing': consent_marketing,
                'custom:consent': consent
            });
            alert('Profile has been updated!');
            // console.log(`Account Profile has been updated!!!`);

            // if (this.props.match.params.action === 'signup' && this.state.errorMessage === null) {
            //     // alert('Your account balance has been updated!');
            //     // window.location.href = '/dashboard/';
            // } else {
            try {
                if (this.props.location.state.target) {
                    this.props.history.push({ pathname: this.props.location.state.target.pathname });
                }
            } catch (error) {
                // console.log(error);
                // No where to be forwarded
            }
            // }
        } catch (error) {
            // alert('An error occurred while updating the profile. Please re-try.');
            this.setState({ errorMessage: error.message });
            // console.log(error);
        }
        // }).catch((error) => {
        //     alert('Invalid login session has been detected. Please sign in again and proceed.');
        //     this.logout();
        // });
    }

    async handleResetPasswordSubmit(data) {
        console.log(`reset password => ${JSON.stringify(data)}`);
        let payload = {
            ea: data.username
        }
        try {
            let response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/v1/check-account`, {
                method: 'POST',
                mode: 'cors',
                cache: 'no-cache',
                credentials: 'same-origin',
                headers: {
                    'Content-Type': 'application/json',
                    // 'Authorization': `Bearer ${ref1}`
                },
                redirect: 'follow',
                referrerPolicy: 'no-referrer',
                body: JSON.stringify(payload)
            })

            let responseJSON = await response.json();
            if (!response.ok) {
                throw Error(responseJSON.message);
            } else {
                if (responseJSON.un !== undefined && responseJSON.un !== null) {
                    // throw Error('found the user!! => '+ JSON.stringify(responseJSON.un))
                    await Auth.forgotPassword(data.username);
                    this.setState({ ca_formData: { username: data.username }, action: 'ConfirmResetPassword', errorMessage: null });
                } else {
                    throw Error('No user found with the provided e-mail address!')
                }
            }
        } catch (error) {
            this.setState({ errorMessage: error.message });
        }
        // try {
        //     await Auth.forgotPassword(data.username);
        //     // console.log('oooooohhh!!');
        //     this.setState({ ca_formData: { username: data.username }, action: 'ConfirmResetPassword', errorMessage: null });
        // } catch (error) {
        //     this.setState({ errorMessage: error.message });
        // }
    }

    async handleConfirmResetPasswordSubmit(data) {
        console.log(`confirm reset password => ${JSON.stringify(data)}`);

        try {
            await Auth.forgotPasswordSubmit(data.username, data.confirmationCode, data.password);
            this.setState({ ca_formData: {}, action: 'profile', errorMessage: 'Your password has been successfully reset.' });
        } catch (error) {
            this.setState({ errorMessage: error.message });
        }
    }

    async handleSignInSubmit(data) {
        // console.log(`sign in => ${JSON.stringify(data)}`);
        this.setState({ ca_formData: { username: data.username } });

        try {
            let cognitoUser = await Auth.signIn(data.username, data.password);
            this.setState({ ca_formData: {}, needToConfirmSignUp: false, authInProgress: true, errorMessage: null });
            await this.onCognitoLoginSuccess(cognitoUser);
        } catch (error) {
            this.setState({ errorMessage: error.message });
        }
    }

    async handleConfirmSignUpSubmit(campaignIdentifier, data) {
        console.log(`new account confirmation (campaign: ${campaignIdentifier}) => ${JSON.stringify(data)}`);

        try {
            await Auth.confirmSignUp(data.username, data.confirmationCode);
            let cognitoUser = await Auth.signIn(data.username, this.state.ca_formData['password']);
            this.setState({ ca_formData: {}, needToConfirmSignUp: false, authInProgress: true, errorMessage: null });
            await this.onCognitoLoginSuccess(cognitoUser);
        } catch (error) {
            this.setState({ errorMessage: error.message });
        }
    }

    async handleCreateAccountSubmit(campaignIdentifier, data) {
        console.log(`new account (campaign: ${campaignIdentifier}) => ${JSON.stringify(data)}`);
        this.setState({ ca_formData: data });

        try {
            let payload = {
                ea: data.username
            }
            let response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/v1/check-account`, {
                method: 'POST',
                mode: 'cors',
                cache: 'no-cache',
                credentials: 'same-origin',
                headers: {
                    'Content-Type': 'application/json',
                    // 'Authorization': `Bearer ${ref1}`
                },
                redirect: 'follow',
                referrerPolicy: 'no-referrer',
                body: JSON.stringify(payload)
            })

            let responseJSON = await response.json();
            if (!response.ok) {
                throw Error(responseJSON.message);
            } else {
                if (responseJSON.un !== undefined && responseJSON.un !== null) {
                    throw Error('The user with the provided e-mail address already exists. Please use a different e-mail address to register a new account or reset the password to continue using the account.');
                } else {
                    // No user found under the requested username/e-mail address, proceed!

                    let tagManagerArgs = {
                        gtmId: 'AW-10862967003',
                        dataLayer: {
                            action: 'signup',
                            stage: 'conversion',
                            page: 'account',
                            campaignIdentifier: campaignIdentifier
                        }
                    }
                    // TagManager.initialize(tagManagerArgs)
                    TagManager.dataLayer(tagManagerArgs);
                }
            }

            let cognitoUser = await Auth.signUp({
                username: data.username,
                password: data.password,
                attributes: {
                    "family_name": data.last_name,
                    "given_name": data.first_name,
                    "phone_number": data.phone_number,
                    "custom:company_name": data.company_name,
                    "custom:campaign_identifier": campaignIdentifier,
                    "custom:consent_partner": data.consent_partner ? "1" : "0",
                    "custom:consent_marketing": data.consent_marketing ? "1" : "0",
                    "custom:consent": data.consent ? "1" : "0"
                }
            });

            console.log(`created cognito user= ${JSON.stringify(cognitoUser)}`);
            this.setState({ needToConfirmSignUp: true });
        } catch (error) {
            if (error.code === 'UsernameExistsException') {
                await Auth.resendSignUp(this.state.ca_formData['username']);
                this.setState({ needToConfirmSignUp: true });
            } else {
                this.setState({ errorMessage: error.message });
            }
        }
    }

    async handlePreorderSubmit(campaignName, campaignIdentifier, data, queryParams) {
        // console.log(`new preorder (${campaignName}: ${campaignIdentifier}) => ${JSON.stringify(data)}`);
        this.setState({ ca_formData: data });

        try {
            // console.log(`will put you on the list right away! ${JSON.stringify(data)} from ${JSON.stringify(queryParams)}`);

            let payload = {
                t: campaignName,
                c: queryParams.c.trim(),
                r: queryParams.r.trim(),
                un: data.username,
                fn: data.first_name,
                ln: data.last_name,
                // pn: data.phone_number ? data.phone_number : null,
                // cn: data.company_name ? data.company_name : null,
                cm: data.consent_marketing
            }
            if (data.phone_number) {
                payload['pn'] = data.phone_number.trim();
            }
            if (data.company_name) {
                payload['cn'] = data.company_name.trim();
            }
            let response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/v1/preorder`, {
                method: 'POST',
                mode: 'cors',
                cache: 'no-cache',
                credentials: 'same-origin',
                headers: {
                    'Content-Type': 'application/json',
                    // 'Authorization': `Bearer ${ref1}`
                },
                redirect: 'follow',
                referrerPolicy: 'no-referrer',
                body: JSON.stringify(payload)
            })

            let responseJSON = await response.json();
            if (!response.ok || responseJSON.success === false) {
                throw Error(responseJSON.message);
            } else {
                let tagManagerArgs = {
                    gtmId: 'AW-10862967003',
                    dataLayer: {
                        action: 'preorder',
                        stage: 'conversion',
                        page: 'account',
                        campaignName: campaignName,
                        campaignIdentifier: campaignIdentifier
                    }
                }
                // TagManager.initialize(tagManagerArgs)
                TagManager.dataLayer(tagManagerArgs);

                alert('Thanks for your interest!');
                window.location.href = '/';
            }
        } catch (error) {
            this.setState({ errorMessage: error.message });
        }
    }


    componentDidMount() {
        this._isMounted = true;

        let action = this.props.match.params.action
        console.log(` ... requested action= ${action}`);
        if (action === 'logout') {
            this.logout();
            return true;
        } else if (action === 'signup' || action === 'preorder') {
            // for signing up, it'll reuse the profile template without the sign-in screen
            // this._isSigningUp = true;
            if (this.props.match.params.campaign !== undefined && this.props.match.params.ref1 !== undefined && this.props.match.params.ref2 !== undefined) {
                this.setState({ action: action });
            } else {
                console.log('invalid signup parameters were given, ABORT!');
                this.setState({ action: 'profile' });
            }
            return true;
        } else if (action === 'resetPassword') {
            this.setState({ action: 'resetPassword' });
        } else {
            Auth.currentAuthenticatedUser().then((cognitoUser) => {
                console.log('found the user? YES!!!');
                this.onCognitoLoginSuccess(cognitoUser);
            }).catch((error) => {
                console.log('found the user? NO');
                // this.props.user.setLoginState(false);
                this.setState({ error: error.message });
            })
        }
    }

    componentWillUnmount() {
        this._isMounted = false;

        // FAILSAFE, final resort!
        // fix Warning: Can't perform a React state update on an unmounted component => make setState dummy
        this.setState = (state, callback) => {
            return;
        }
    }

    async performInitialSetup(currentUser = null) {
        if (this.state.action !== 'signup') {
            console.log(` ... initial setup process already in progress ...`);
            return false;
        }

        if (currentUser !== null && this.props.match.params.campaign !== undefined && this.props.match.params.ref1 !== undefined && this.props.match.params.ref2 !== undefined) {
            console.log(` ... initial setup params = ${JSON.stringify(this.props.match.params)}`);

            let data = {
                it: currentUser.get("sessionToken"),
                cp: this.props.match.params.campaign,
                r1: this.props.match.params.ref1,
                r2: this.props.match.params.ref2
            }

            let response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/v1/account-setup-initial`, {
                method: 'POST',
                mode: 'cors',
                cache: 'no-cache',
                credentials: 'same-origin',
                headers: {
                    'Content-Type': 'application/json',
                    // 'Authorization': `Bearer ${ref1}`
                },
                redirect: 'follow',
                referrerPolicy: 'no-referrer',
                body: JSON.stringify(data)
            })

            let responseJSON = await response.json();
            if (!response.ok) {
                throw Error(responseJSON.message);
            } else {
                console.log(' ... kid is up-to-date ...');
            }

            return true;
        } else {
            throw Error('Requested campaign parameters are invalid!');
        }
    }

    render() {
        let children

        // console.log('loginUser in userContext = '+ this.props.loginUser);
        // console.log('user in userContext = '+ this.props.user);

        if (this.state.authInProgress) {
            children = (
                <Alert variant="info">Loading...</Alert>
            )
        } else if (this.state.logged) {
            if (this.props.match.params.action === 'logout') {
                children = (
                    <Alert variant="success">Signing out...</Alert>
                )
            } else if (this.state.currentUser) {
                // The post sign-up process (initial setup) occurs only once here.
                if (this.state.action === 'signup' && this.state.errorMessage === null && this._postSignUpProgress === false) {
                    this._postSignUpProgress = true;
                    this.setState((currentState) => ({
                        action: 'profile',
                        errorMessage: null
                    }));
                    this.performInitialSetup(this.state.currentUser).then(() => {
                        // alert('Your account balance has been updated!');
                        alert("Awesome, let's 11Klear!");
                        window.location.href = '/dashboard/';
                    }).catch((error) => {
                        this.setState({ errorMessage: error.message });

                    });
                }

                // This will render when the existing user signs in.
                children = (
                    <Container>
                        <Row>
                            <Col>
                                {this.props.match.params.action === 'signup' && this.state.errorMessage !== null ? <Alert variant="warning">Failed to apply the credit automatically, please consult with our support with this diagnostic message <b><i>(response= "{this.state.errorMessage}", request= {JSON.stringify(this.props.match.params)})</i></b></Alert> : null}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <AccountProfileForm user={this.state.currentUser} campaignName={this.props.match.params.campaign} isPartner={this.state.isPartner} logout={this.logout} onSubmit={(enteredData) => this.handleUpdateAccountSubmit(this.state.currentUser, enteredData)} />
                            </Col>
                        </Row>
                        {/* <Row>
                                <Col>
                                    <Button variant="success" onClick={async () => {
                                        try {
                                            await this.performInitialSetup(this.state.currentUser);
                                            this.setState({ errorMessage: null });
                                        } catch (error) {
                                            this.setState({ errorMessage: error.message });
                                        }
                                    }}>Test Bonus</Button>
                                </Col>
                            </Row> */}
                    </Container>
                )
            } else {
                children = (
                    // {userContextUpdater},
                    <Alert variant="success">Loading...</Alert>
                )
            }
        } else if (this.props.user.isLoggedIn === false && this.state.action === 'ConfirmResetPassword') {
            children = (
                <Container fluid>
                    {this.state.errorMessage !== null ?
                        <Row className="justify-content-center">
                            <Col md={8}>
                                <Alert variant="danger"><b>{this.state.errorMessage}</b></Alert>
                            </Col>
                        </Row>
                        : null}
                    <Row className="justify-content-center">
                        <Col>
                            <ConfirmResetPasswordForm wipFormData={this.state.ca_formData} onSubmit={(enteredData) => this.handleConfirmResetPasswordSubmit(enteredData)} />
                        </Col>
                    </Row>
                </Container>
            )
        } else if (this.props.user.isLoggedIn === false && this.state.action === 'resetPassword') {
            children = (
                <Container fluid>
                    {this.state.errorMessage !== null ?
                        <Row className="justify-content-center">
                            <Col md={8}>
                                <Alert variant="danger"><b>{this.state.errorMessage}</b></Alert>
                            </Col>
                        </Row>
                        : null}
                    <Row className="justify-content-center">
                        <Col>
                            <ResetPasswordForm wipFormData={this.state.ca_formData} onSubmit={(enteredData) => this.handleResetPasswordSubmit(enteredData)} />
                        </Col>
                    </Row>
                </Container>
            )
        } else if (this.props.user.isLoggedIn === false && this.state.action === 'profile') {
            // console.log(' .. login dialog should show up..');

            children = (
                <Container fluid>
                    {this.state.errorMessage !== null ?
                        <Row className="justify-content-center">
                            <Col md={8}>
                                <Alert variant="danger"><b>{this.state.errorMessage}</b></Alert>
                            </Col>
                        </Row>
                        : null}
                    <Row className="justify-content-center">
                        <Col>
                            <SignInForm wipFormData={this.state.ca_formData} onSubmit={(enteredData) => this.handleSignInSubmit(enteredData)} />
                        </Col>
                    </Row>
                </Container>
            )
        } else if (this.state.action === 'signin_AMPLIFY') {
            children = (
                <AmplifyAuthenticator
                    usernameAlias="email"
                    handleAuthStateChange={this.handleAuthStateChange}
                    // theme={Bootstrap}
                    initialAuthState="signin"
                    style={{
                        // https://docs.amplify.aws/ui/auth/authenticator/q/framework/react#props-slots-amplify-authenticator
                        '--amplify-font-family': "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
                        '--amplify-primary-color': '#282c34',
                        '--amplify-primary-tint': '#282c34',
                        '--amplify-primary-shade': '#282c34',
                        // '--amplify-background-color': '#aaaaaa'
                    }}
                >
                    <AmplifySignIn
                        slot="sign-in"
                        usernameAlias="email"
                        hideSignUp
                        formFields={[
                            {
                                type: "email",
                                label: "Email Address *",
                                // placeholder: "Email",
                                // required: true,
                                inputProps: {
                                    autocomplete: "username",
                                    name: "login_username"
                                }
                            },
                            {
                                type: "password",
                                label: "Password *",
                                // placeholder: "**********",
                                // required: true,
                                inputProps: {
                                    autocomplete: "current-password",
                                    name: "login_password"
                                }
                            }
                        ]}
                    />

                    {/* </div> */}
                    <Container>
                        <Row>
                            <Col>
                                <Alert variant="success">Loading... If this message persists, please refresh the page manually or sign out first before re-trying.</Alert>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                &nbsp;
                            </Col>
                            <Col>
                                <Button variant="outline-success" onClick={() => { window.location.reload(false) }}>Refresh</Button>
                            </Col>
                            <Col>
                                {/* <AmplifySignOut /> */}
                                <Button variant="outline-danger" onClick={() => { this.logout() }}>Sign Out</Button>
                            </Col>
                        </Row>
                    </Container>
                </AmplifyAuthenticator>
            )
        } else if (this.state.needToConfirmSignUp === true) {
            let campaignIdentifier = `${this.props.match.params.campaign}/${this.props.match.params.ref1}/${this.props.match.params.ref2}`

            children = (
                <Container fluid>
                    {this.state.errorMessage !== null ?
                        <Row className="justify-content-center">
                            <Col md={8}>
                                <Alert variant="danger"><b>{this.state.errorMessage}</b></Alert>
                            </Col>
                        </Row>
                        : null}
                    <Row className="justify-content-center">
                        <Col>
                            <ConfirmSignUpForm campaignIdentifier={campaignIdentifier} wipFormData={this.state.ca_formData} onSubmit={(enteredData) => this.handleConfirmSignUpSubmit(campaignIdentifier, enteredData)} />
                        </Col>
                    </Row>
                </Container>
            )
        } else if (this.state.action === 'signup') {
            let campaignName = `${this.props.match.params.campaign}`
            let campaignIdentifier = `${this.props.match.params.campaign}/${this.props.match.params.ref1}/${this.props.match.params.ref2}`

            let tagManagerArgs = {
                gtmId: 'AW-10862967003',
                dataLayer: {
                    action: 'signup',
                    stage: 'initiate',
                    page: 'account',
                    campaign: campaignName,
                    campaignRef1: this.props.match.params.ref1,
                    campaignRef2: this.props.match.params.ref2
                }
            }
            TagManager.dataLayer(tagManagerArgs);
            // TagManager.initialize(tagManagerArgs)

            children = (
                <Container fluid>
                    {this.state.errorMessage !== null ?
                        <Row className="justify-content-center">
                            <Col md={8}>
                                <Alert variant="danger"><b>{this.state.errorMessage}</b></Alert>
                            </Col>
                        </Row>
                        : null}
                    <Row className="justify-content-center">
                        <Col>
                            <CreateAccountForm campaignName={campaignName} campaignIdentifier={campaignIdentifier} wipFormData={this.state.ca_formData} onSubmit={(enteredData) => this.handleCreateAccountSubmit(campaignIdentifier, enteredData)} />
                        </Col>
                    </Row>
                </Container>
            )
        } else if (this.state.action === 'preorder') {
            let campaignName = `${this.props.match.params.campaign}`
            let campaignIdentifier = `${this.props.match.params.campaign}/${this.props.match.params.ref1}/${this.props.match.params.ref2}`
            let queryParams = queryString.parse(this.props.location.search);

            let tagManagerArgs = {
                gtmId: 'AW-10862967003',
                dataLayer: {
                    action: 'preorder',
                    stage: 'initiate',
                    page: 'account',
                    campaign: campaignName,
                    campaignRef1: this.props.match.params.ref1,
                    campaignRef2: this.props.match.params.ref2
                }
            }
            TagManager.dataLayer(tagManagerArgs);

            children = (
                <Container fluid>
                    {this.state.errorMessage !== null ?
                        <Row className="justify-content-center">
                            <Col md={8}>
                                <Alert variant="danger"><b>{this.state.errorMessage}</b></Alert>
                            </Col>
                        </Row>
                        : null}
                    <Row className="justify-content-center">
                        <Col>
                            <PreorderAccountForm campaignName={campaignName} campaignIdentifier={campaignIdentifier} country={queryParams.c} region={queryParams.r} wipFormData={this.state.ca_formData} onSubmit={(enteredData) => this.handlePreorderSubmit(campaignName, campaignIdentifier, enteredData, queryParams)} />
                        </Col>
                    </Row>
                </Container>
            )
        } else {
            children = (
                <Alert variant="info">Loading...</Alert>
            )
        }

        return children
    }
}

export default function (props) {
    const userContext = useContext(UserContext);
    // const history = useHistory();

    return <Account {...props} user={userContext} />;
}

// Account.contextType = userContext;
// export default Account;

// export default withAuthenticator(Account, {
//     theme: Bootstrap,
//     usernameAttributes: 'email',
//     signUpConfig: {
//         hiddenDefaults: ["phone_number"],
//         signUpFields: [
//             {
//                 key: 'name',
//                 label: 'Name',
//                 required: true,
//                 placeholder: 'Enter your name'
//             }
//         ]
//     }
// });