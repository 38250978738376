import React, { Component } from "react";
// import banner from './Special_Delivery__Bird_preview.jpg';
import { Container, Row, Col } from 'react-bootstrap';
// import birdImage from './images/Special_Delivery__Bird_preview.jpg';
// import CheckIcon from '@material-ui/icons/Check';
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Button } from '@material-ui/core';
// import * as Scroll from 'react-scroll';
import { Events, scrollSpy } from 'react-scroll';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
// import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';

import { colorPens } from './utils/colors';
import TryNow from './components/tryNow';

class Pricing extends Component {
  state = {
    readMore: false
  };

  componentDidMount() {
    Events.scrollEvent.register('begin', function (to, element) {
      console.log('begin', arguments);
    });

    Events.scrollEvent.register('end', function (to, element) {
      console.log('end', arguments);
    });

    scrollSpy.update();
  }

  componentWillUnmount() {
    Events.scrollEvent.remove('begin');
    Events.scrollEvent.remove('end');
  }

  handleSetActive(to) {
    console.log(`handleSetActive ${to}`);
  }

  render() {
    return (
      <Container fluid>
        <p></p>
        <Row className="justify-content-center">
          <Col>
            <h2 align="center"><b>Simple Pricing</b></h2>
          </Col>
        </Row>
        {/* <Row className="justify-content-center">
          <Col>
            <h5 align="center">
              <i>More for far less</i>
            </h5>
          </Col>
        </Row> */}
        <br />

        <Row className="justify-content-center align-items-center">
          <Col>&nbsp;</Col>
          <Col md={9}>
            <Row>
              <Col style={{ color: colorPens.dark }}>
                <Row>
                  <Col align="center">
                    <h4><b>Pay-As-You-Go</b></h4>
                    <b>(USD per tag) <sup>*1</sup></b>

                  </Col>
                </Row>
                <br />
                <Row>
                  <Col align="center" style={{ color: colorPens.success }}>
                    <b>
                      <Row>
                        <Col md={8}>
                          RTV Tag
                        </Col>
                        <Col md={4} align="left">
                          $1
                        </Col>
                      </Row>
                      <Row>
                        <Col md={8}>
                          RT Tag
                        </Col>
                        <Col md={4} align="left">
                          $0.25
                        </Col>
                      </Row>
                    </b>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col align="center">
                    <ChevronRightIcon /> Only activated tags are charged<br />
                    <ChevronRightIcon /> The tag cost is deducted when created<br />
                    <ChevronRightIcon /> BUT added back to your account if unused<br />
                    {/* <ChevronRightIcon /> No charge for unused tags, refunded after two weeks<br />
                    <ChevronRightIcon /> The minimum pre-paid amount is $10.00​<br /> */}
                  </Col>
                </Row>
                <br />
                {/* <Row>
                  <Col style={{ color: colorPens.secondary }}>
                    - Issued but unused tags are reversed after 2 weeks at the latest posted rate.<br />
                    - The minimum pre-paid amount is $10.00​<br />
                    - Applicable sales taxes are not included.                    
                  </Col>
                </Row> */}
              </Col>
              <Col style={{ color: colorPens.dark }}>
                <Row>
                  <Col align="center">
                    <h4><b>Pay-As-You-Grow</b></h4>
                    <b>(volume discounts)</b> <sup>*1</sup>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col align="center" style={{ color: colorPens.success }}>
                    <b>
                      <Row>
                        <Col md={5}>
                          Pre-Pay $500 ~
                        </Col>
                        <Col md={7} align="left">
                          Get 25% Bonus Service Credit
                        </Col>
                      </Row>
                      <Row>
                        <Col md={5}>
                          Pre-Pay $3,000 ~
                        </Col>
                        <Col md={7} align="left">
                          Get 35% Bonus Service Credit
                        </Col>
                      </Row>
                    </b>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col align="center">
                    {/* <AddShoppingCartIcon /> */}
                    Pre-paid savings examples:<br />
                    <ChevronRightIcon /> Recharge $500 <ArrowRightAltIcon /> $625 in your account<br />
                    <ChevronRightIcon /> Recharge $3,000 <ArrowRightAltIcon /> $4,050 in your account<br />
                  </Col>
                </Row>
                {/* <Row>
                  <Col align="center">
                    <Button 
                      style={{
                        fontSize: 14,
                        color: colorPens.light,
                        backgroundColor: colorPens.main
                      }}                      
                      onClick={() => { window.FreshworksWidget('open', 'ticketForm'); }}
                    >Ask Us About Enterprise Pricing!</Button>
                  </Col>
                </Row> */}
              </Col>
            </Row>
            <br />
          </Col>
          <Col>&nbsp;</Col>
        </Row>

        <Row className="justify-content-center align-items-center">
          <Col md="6" align="center">
            <Button
              style={{
                fontSize: 18,
                color: colorPens.main,
                backgroundColor: colorPens.light
              }}                      
              variant="outlined"
              // onClick={() => { window.FreshworksWidget('open', 'ticketForm'); }}
              onClick={() => { window.location.href = 'https://11klear.freshdesk.com/support/tickets/new'; }}
            >Ask Us About Enterprise Pricing!</Button>
          </Col>
        </Row>
        <br /><br />

        {/* <Row className="justify-content-center align-items-center">
          <Col md={5} align="right">
            <Button
              disabled={false}
              style={{
                fontSize: '1.2em',
                color: colorPens.light,
                backgroundColor: colorPens.success,
                '&:hover': {
                  borderColor: colorPens.primary
                }
              }}
              onClick={() => {
                // window.location.href = '/invite';
                window.location.href = `/account/signup/referral/11Klear/${process.env.REACT_APP_11K_DIRECT_CUSTOMER_SIGNUP_BONUS_CODE}`;
              }}
            >TRY IT FOR FREE</Button>
          </Col>
          <Col md={5} align="left">
            <font size="1.2em">
              *Limited-time $50 sign-up bonus. No Credit Card Required.
            </font>
          </Col>
          <Col md={2} align="left">&nbsp;
            </Col>
        </Row>
        <br /><br /> */}


        {/* <Row>
          <Col><Image src={process.env.PUBLIC_URL+'/images/banner_RTV_advantages.png'} fluid /></Col>
        </Row> */}
        <Row className="justify-content-center align-items-center">
          <Col md="8">
            <font size="1">(1) plus sales tax where applicable</font>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Pricing;