import React, { Component } from "react";
import { Container, Row, Col, Button, Alert } from "react-bootstrap";
// import BootstrapTable from 'react-bootstrap-table-next';
// import paginationFactory from 'react-bootstrap-table2-paginator';

import { loadStripe } from "@stripe/stripe-js";
// import { Elements } from "@stripe/react-stripe-js";
// import CheckoutForm from "./components/paymentCheckoutForm";
import PaymentAmountForm from './components/paymentAmountForm';
import CreatePaymentCustomerForm from './components/paymentCustomerForm';

// import "./Payment.css";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
// refer this: https://stripe.com/docs/payments/integration-builder

var Parse = require('parse');

class Payment extends Component {
    constructor(props) {
        super(props);

        this.state = {
            amount: null,
            stripeCustomer: null,
            customerReady: false
        }
    }

    async retrieveStripeCustomer(sessionToken) {
        let data = {
            it: sessionToken,
        }

        try {
            let response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/v1/retrieve-stripe-customer`, {
                method: 'POST',
                mode: 'cors',
                cache: 'no-cache',
                credentials: 'same-origin',
                headers: {
                    'Content-Type': 'application/json'
                },
                redirect: 'follow',
                referrerPolicy: 'no-referrer',
                body: JSON.stringify(data)
            });
    
            if (!response.ok) {
                throw Error(response);
            } else {
                let result = await response.json();
                return result;
            }    
        } catch (error) {
            alert(`Failed to retrieve the customer. Please try later again. (${JSON.stringify(error)})`);
        }
    }

    async updateStripeCustomer(sessionToken, newData) {
        let data = {
            it: sessionToken,
            nd: newData
        }

        try {
            // // DEBUG!
            // throw Error('test error!!!');

            let response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/v1/update-stripe-customer`, {
                method: 'POST',
                mode: 'cors',
                cache: 'no-cache',
                credentials: 'same-origin',
                headers: {
                    'Content-Type': 'application/json'
                },
                redirect: 'follow',
                referrerPolicy: 'no-referrer',
                body: JSON.stringify(data)
            });
    
            if (!response.ok) {
                throw Error(response);
            } else {
                let result = await response.json();
                return result;
            }    
        } catch (error) {
            alert(`Failed to update the customer billing details. Please try later again. (${error.message})`);
            window.location.href='/usage';
        }
    }

    async checkSalesEligibility(id, country, state) {
        let data = {
            // it: sessionToken,
            sc: {
                id: id,
                country: country,
                state: state,
            }
        }

        // try {
            let response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/v1/check-sales-eligibility`, {
                method: 'POST',
                mode: 'cors',
                cache: 'no-cache',
                credentials: 'same-origin',
                headers: {
                    'Content-Type': 'application/json'
                },
                redirect: 'follow',
                referrerPolicy: 'no-referrer',
                body: JSON.stringify(data)
            });
    
            let result = await response.json();
            if (!response.ok) {
                throw Error(result.error_message);
            } else {
                // let result = await response.json();
                return result;
            }    
        // } catch (error) {
        //     alert(`Failed to retrieve the tax rates. (${error.message})`);
        //     window.location.href='/usage';
        // }
    }

    async createCheckoutSession(sessionToken, amount) {
        let data = {
            it: sessionToken,
            sc: {
                id: this.state.stripeCustomer.id,
                country: this.state.stripeCustomer.address.country,
                state: this.state.stripeCustomer.address.state,
            },
            // em: this.currentUser.get("email"),
            at: amount
        }

        try {
            let response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/v1/generate-checkout-session`, {
                method: 'POST',
                mode: 'cors',
                cache: 'no-cache',
                credentials: 'same-origin',
                headers: {
                    'Content-Type': 'application/json'
                },
                redirect: 'follow',
                referrerPolicy: 'no-referrer',
                body: JSON.stringify(data)
            });
    
            let result = await response.json();
            if (!response.ok) {
                throw Error(result.error_message);
            } else {
                // let result = await response.json();
                return result;
            }    
        } catch (error) {
            alert(`Failed to create a checkout session. (${error.message})`);
            window.location.href='/usage';
        }
    }

    async componentDidMount() {
        let currentUser = Parse.User.current();
        if (currentUser != null) {
            // display the payment interface
            this.currentUser = currentUser;
            // console.log(`user=> ${JSON.stringify(this.currentUser)}`);

            let retrievedStripeCustomer = await this.retrieveStripeCustomer(this.currentUser.get("sessionToken"));
            this.setState({ stripeCustomer: retrievedStripeCustomer.customer });

            // let thisStripeId = currentUser.get("stripe_id");
            // if (thisStripeId !== undefined) {
            //     this.setState({ stripeId: thisStripeId });
            // } else {

            // }
        } else {
            window.location.href = '/';
        }
    }

    async handlePaymentAmountSubmit(enteredData) {
        console.log(`enteredData= ${JSON.stringify(enteredData)}`);
        let thisAmount = enteredData.amount * 100.0; // convert cents to dollars
        this.setState({ amount: thisAmount });

        const stripe = await stripePromise;
        let thisCheckoutSession = await this.createCheckoutSession(this.currentUser.get("sessionToken"), thisAmount);
        console.log(`thisCheckoutSession= ${JSON.stringify(thisCheckoutSession)}`);
        const result = await stripe.redirectToCheckout({
            sessionId: thisCheckoutSession.id,
        });

        if (result.error) {
            alert(`Failed to redirect to the checkout screen. Please try later again.`);
            window.location.href='/usage';
        }
    }

    async handlePaymentCustomerSubmit(enteredData) {
        // console.log(`enteredData= ${JSON.stringify(enteredData)}`);
        let updatedStripeCustomer = this.state.stripeCustomer;

        if (this.state.stripeCustomer.address === null ||
            enteredData.country !== this.state.stripeCustomer.address.country ||
            enteredData.address_line1 !== this.state.stripeCustomer.address.line1 ||
            enteredData.address_line2 !== this.state.stripeCustomer.address.line2 ||
            enteredData.city !== this.state.stripeCustomer.address.city ||
            enteredData.postal_code !== this.state.stripeCustomer.address.postal_code ||
            enteredData.state !== this.state.stripeCustomer.address.state
        ) {
                console.log(`Let's update the billing detail on record...`);
                enteredData['id'] = this.state.stripeCustomer.id;
                let retrievedStripeCustomer = await this.updateStripeCustomer(this.currentUser.get("sessionToken"), enteredData);
                updatedStripeCustomer = retrievedStripeCustomer.customer;
        } else {
            console.log(`NO NEED to update the billing details; remains the same...`);
        }

        // check the eligibility for this sales tax region
        try {
            await this.checkSalesEligibility(updatedStripeCustomer.id, updatedStripeCustomer.address.country, updatedStripeCustomer.address.state);

            this.setState({ stripeCustomer: updatedStripeCustomer, customerReady: true });    
        } catch (error) {
            alert(`Failed to retrieve the tax rates. (${error.message})`);
            window.location.href='/usage';
        }
    }

    render() {
        let action = this.props.match.params.action;

        if (action === 'success') {
            return (
                <Container fluid>
                    <Row className="justify-content-center">
                        <Col align="center">
                            <Alert variant="success">Your payment has been processed. We appreciate your business!</Alert>
                            <Button onClick={() => { window.location.href='/usage' }}>Check out the transaction history</Button>
                        </Col>
                    </Row>
                </Container>
            );
        } else if (action === 'cancel') {
            return (
                <Container fluid>
                    <Row className="justify-content-center">
                        <Col align="center">
                            <Alert variant="warning">Your checkout process has been cancelled.</Alert>
                        </Col>
                    </Row>
                </Container>
            );
        } else if ((this.state.stripeCustomer !== null) && (this.state.customerReady === false || action === 'billing')) {
            

            return (
                <Container fluid>
                    {/* <Row>
                        <Col md={8} align="right">
                            <h4>Service credit purchase amount: [ enter number ]</h4>
                        </Col>
                        <Col md={4} align="left">
                            <Button variant="success" size="sm" onClick={() => { alert(`Not implemented, yet!`); }}>Proceed</Button>
                        </Col>
                    </Row> */}
                    {/* { (this.state.stripeCustomer !== null) ? */}
                    { (this.state.stripeCustomer !== null) ?
                        <>
                        <Row className="justify-content-center">
                            <Col align="center">
                                <CreatePaymentCustomerForm customer={this.state.stripeCustomer} onSubmit={async (enteredData) => {
                                    await this.handlePaymentCustomerSubmit(enteredData);
                                }} />
                            </Col>
                        </Row>
                        </>
                        :
                        <>
                        <Alert variant="light">Retrieving the billing information...</Alert>
                        </>
                    }
                </Container>
            );
        } else if (this.state.stripeCustomer !== null && this.state.customerReady === true) {
            return (
                <Container fluid>
                    {/* <Row>
                        <Col md={8} align="right">
                            <h4>Service credit purchase amount: [ enter number ]</h4>
                        </Col>
                        <Col md={4} align="left">
                            <Button variant="success" size="sm" onClick={() => { alert(`Not implemented, yet!`); }}>Proceed</Button>
                        </Col>
                    </Row> */}
                    { (this.state.stripeCustomer !== null) ?
                        <>
                        <Row className="justify-content-center">
                            <Col align="center">
                                <PaymentAmountForm onSubmit={(enteredData) => this.handlePaymentAmountSubmit(enteredData)} />
                            </Col>
                        </Row>
                        </>
                        :
                        <>
                        <Alert variant="light">Retrieving the billing information...</Alert>
                        </>
                    }
                </Container>
            );
        } else {
            return (
                <Container fluid>
                    <Row>
                        <Col>
                            <Alert variant="light">Retrieving the billing information...</Alert>
                        </Col>
                    </Row>
                </Container>
            )
        }
    }
}

export default Payment;