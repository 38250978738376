import React, { Component } from 'react'
import { Modal, Button, Badge, Container, Row, Col, Card } from 'react-bootstrap'
// import { QRCode } from 'qrcode.react'

import * as DPOD from './DPOD.js';

import PrintProvider, { Print, NoPrint } from 'react-easy-print';
var QRCode = require('qrcode.react');

export default class DPODCard extends Component {
    constructor(props) {
        super(props)

        this.state = {
            dPODHistoryElement: null
        }

        this.nid = this.props.nid;
        this.proximity = this.props.dpod.proximity;
        this.proximityValue = this.proximity ? 1 : 0;
        // this.qrcodeValue = 'https://oslo.softopinion.com/carbon/checkin/' + this.nid + '/' + this.proximityValue;
        this.qrcodeValue = `${process.env.REACT_APP_MOBILE_APP_LAUNCH_ENDPOINT}/checkin/` + this.nid + '/' + this.proximityValue;

    }

    componentDidMount() {
        // console.log('setting up DPoDCard# ' + this.nid);
        // console.log(this.props.dPODHistory);
        let dPODHistoryElement = [];
        for (let i = 0; i < this.props.dPODHistory.length; i++) {
            dPODHistoryElement.push(<Row>
                <Col>{this.props.dPODHistory[i][0]}</Col>
                <Col>{this.props.dPODHistory[i][1]}</Col>
            </Row>);
        }
        // console.log(JSON.stringify(dPODHistoryElement));
        this.setState({
            dPODHistoryElement: dPODHistoryElement
        })
    }

    render() {

        return (
            <>
                <PrintProvider>
                    <NoPrint>
                        {/* <Button variant="outline-primary" size="sm" onClick={ () => this.props.handleShow(this.nid) }>View</Button> */}
                        <Modal size="md" aria-labelledby="detailed-dpod" centered show={true} onHide={() => this.props.handleHide(this.nid)}>
                            <Modal.Header closeButton>
                                <Modal.Title id="detailed-dpod">11Tag # {this.nid}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Container fluid>
                                    {/* <Row>
                <Col>objectId (raw)</Col>
      <Col>{this.props.dpod.id}<br />{DPOD.dec2asc(this.nid)}</Col>
              </Row> */}
                                    <Row className="align-items-center">
                                        {/* <Col><QRCode value={ () => { return ('https://www.dlibird.com/api/v1/checkin/'+ this.nid) } } level={"H"} includeMargin={true} /></Col> */}
                                        <Col align="center"><QRCode value={this.qrcodeValue} level={"H"} includeMargin={true} /></Col>
                                        <Col align="center"><h3><Badge variant="primary">{DPOD.status(this.props.dpod.status)}</Badge></h3></Col>
                                    </Row>
                                    {/* <Row>
                <Col>dLock</Col>
                <Col>{this.props.dpod.get('dLock')}</Col>
              </Row> */}
                                    <Row>
                                        <Col>Reference ID</Col>
                                        <Col>{this.props.dpod.refId}</Col>
                                    </Row>
                                    {
                                        this.proximity ?
                                            (
                                                <Row>
                                                    <Col>Proximity Check</Col>
                                                    <Col><Badge variant="warning">Required</Badge></Col>
                                                </Row>
                                            ) : null
                                    }
                                    <Row>
                                        <Col>Note</Col>
                                        <Col>{this.props.dpod.note}</Col>
                                    </Row>
                                    <br />
                                    <Row>
                                        <Col align="center">Transaction History</Col>
                                    </Row>
                                    {this.state.dPODHistoryElement}
                                </Container>
                                <Print single={true} printOnly={true} name="foo">
                                    <Container fluid>
                                        <Row className="align-items-center">
                                            <Col align="center">
                                                <Card bg="light" border="dark" text="dark" style={{ width: '30rem' }}>
                                                    <Card.Header style={{ fontSize: 50, fontWeight: "bold" }}><img alt="11Klear" src={process.env.PUBLIC_URL+'/images/11Klear-Logo-WB-192.png'} style={{ height: 80 }} /> 11Klear</Card.Header>
                                                    <Card.Body>
                                                        <Card.Text><QRCode value={this.qrcodeValue} level={"H"} includeMargin={false} size={256} /></Card.Text>
                                                        <Card.Title style={{ fontSize: 36, fontWeight: "bold" }}>{this.nid}</Card.Title>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </Container>
                                </Print>
                            </Modal.Body>
                            <Modal.Footer>
                                {/* <Button variant="outline-warning" onClick={ window.print }>Print the tag</Button> */}
                                {
                                    DPOD.isActive(this.props.dpod.status) ?
                                        (
                                            <Button variant="outline-danger" onClick={(e) => {
                                                if (window.confirm('Do you really want to re-issue and send a new key to the recipient? This will REVOKE the current key in the previous activation link?')) {
                                                    // Let's renew the key
                                                    let data = {
                                                        ni: this.nid,
                                                        dl: this.props.dpod.dLock
                                                    }

                                                    fetch(`${process.env.REACT_APP_API_ENDPOINT}/v1/renew-key`, {
                                                        method: 'POST',
                                                        mode: 'cors',
                                                        cache: 'no-cache',
                                                        credentials: 'same-origin',
                                                        headers: {
                                                            'Content-Type': 'application/json'
                                                        },
                                                        redirect: 'follow',
                                                        referrerPolicy: 'no-referrer',
                                                        body: JSON.stringify(data)
                                                    }).then(async res => {
                                                        if (!res.ok) {
                                                            let result = await res.json();
                                                            // console.log(JSON.stringify(result));

                                                            window.confirm(`Failed to renew the key ("${result.message}")`);
                                                            return false;
                                                        } else {
                                                            // let result = await res.json();
                                                            // console.log('success =>', JSON.stringify(result));

                                                            this.props.handleHide(this.nid);
                                                            return true;
                                                        }
                                                    });
                                                };
                                            }}>Issue a new key</Button>
                                        ) : null
                                }
                                <Button variant="outline-primary" onClick={(e) => {
                                    // References;
                                    // https://stackoverflow.com/questions/58798867/react-js-pdfkit
                                    // https://pdfkit.org/index.html

                                    fetch(`${process.env.REACT_APP_API_ENDPOINT}/v1/print-tag`, {
                                        method: 'POST',
                                        headers: {
                                            'Content-Type': 'application/json',
                                        },
                                        body: JSON.stringify({
                                            filename: "11Klear-" + this.nid,
                                            id: this.nid,
                                            content: this.qrcodeValue,
                                            proximity: this.proximity
                                        })
                                    }).then(async res => {
                                        if (res.status === 200) {
                                            const blob = await res.blob();
                                            const pdfFile = new Blob(
                                                [blob],
                                                { type: 'application/pdf' }
                                            );

                                            const pdfFileURL = URL.createObjectURL(pdfFile);
                                            window.open(pdfFileURL);

                                            // To open the download/save link ===>
                                            // var link = document.createElement("a");
                                            // link.href = pdfFileURL;
                                            // link.download = "11Klear-"+ this.nid +".pdf";
                                            // link.click();

                                        }
                                    });
                                }}>Print the tag</Button>
                                <Button variant="outline-secondary" onClick={() => this.props.handleHide(this.nid)}>Close</Button>
                            </Modal.Footer>
                        </Modal>
                    </NoPrint>
                </PrintProvider>
            </>
        )
    }
}