import React, { useState } from "react";

import { Card, Row, Col } from 'react-bootstrap';
import { Button } from '@material-ui/core';
// import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import ReplayIcon from '@material-ui/icons/Replay';

import { colorPens } from './utils/colors';

function Carbon(props) {
    var requestedParams = props.match.params['0'].split('/');
    let action = requestedParams[0];
    let dpodId = requestedParams[1];
    let reference = requestedParams[2];

    // This page WILL display for users who haven't installed the app, yet.
    // They would need to:
    // 1. Install the app following the proposed link; no automatic redirection to make sure.
    // 2. Go back to the received sender notification email and click on it. 
    //    => Recipients don't have any identity presence on Dlibird-Web.

    const [buttonStyle, setButtonStyle] = useState({
        fontSize: 18,
        color: colorPens.main,
        backgroundColor: colorPens.warning
    });
    const [buttonIcon, setButtonIcon] = useState(<ReplayIcon />);
    const [buttonText, setButtonText] = useState('Retry');

    return (
        <div>
            <Card border="secondary" bg="light" className="text-center">
                <Card.Header as="h5">
                    <ErrorOutlineIcon /> <i>Action Required</i> <ErrorOutlineIcon />
                </Card.Header>
                <Card.Body>
                    <h4>The requested content is availble only via the native app on iOS/Android mobile devices.</h4>
                    <br />

                    <a href="https://play.google.com/store/apps/details?id=com.xiedge.xiklear"><img alt="Get it on Google Play" src={process.env.PUBLIC_URL + '/images/badges/google-play-badge-cropped.png'} style={{ width: 256 }} /></a><br /><br />
                    <a href="https://apps.apple.com/app/11klear/id1566786979"><img alt="Download on the App Store" src={process.env.PUBLIC_URL + '/images/badges/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg'} style={{ width: 256 }} /></a>

                    <br /><br />
                    <h5>To proceed, please install 11Klear mobile app <img alt="11Klear" src={process.env.PUBLIC_URL + '/images/11Klear-Logo-192.png'} style={{ height: 32 }} /> from App Store, close this browser, and click on the activation button in the received email once again.</h5><br />


                    <h5>If you see this message after the initial app installation, remove and re-install the app from the App store before activating an 11Klear tag.</h5>
                    {/* <Button
                        // color={colorPens.secondary} 
                        disabled={false}
                        variant="outlined"
                        // size="small"
                        // labelStyle={{ fontSize: 28 }} 
                        style={buttonStyle}
                        startIcon={buttonIcon}
                        onClick={() => { window.location.reload(); }}
                    >{buttonText}</Button> */}
                </Card.Body>
                <h6 style={{ color: colorPens.secondary }}><span class="copyright-notice"><span>&copy; </span><span property="dc:date" datatype="xsd:gYear">2020,</span><span> 11Edge Corporation</span></span></h6>
            </Card>

            {/* <h3>External Link:{JSON.stringify(props)}</h3> */}


            {/* <hr></hr>

            <h5>(debug note) This page will open 11Klear native app and trigger =></h5>
            <br />
            <h4>Action: <i>{action}</i></h4>
            <h4>ID: <i>{dpodId}</i></h4>
            <h4>Ref: <i>{reference}</i></h4> */}
        </div>
    );
}

export default Carbon;