import React, { Component } from "react";
// import banner from './Special_Delivery__Bird_preview.jpg';
import { Container, Row, Col, Button, Modal, Badge, Alert, Spinner } from 'react-bootstrap';

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

import { UserContext } from './components/userContext';

import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import { colorPens } from './utils/colors';

var Parse = require('parse');

class PartnerDashboard extends Component {
    constructor(props) {
        super(props);

        this.currentUser = Parse.User.current();
        this.state = {
            mode: 'loading',
            partnerID: null,
            level: 1,
            payable: 0,
            referred: 0,
            loadingSpinner: <>
                <Spinner animation="border" variant="secondary" size="sm" role="status">
                    <span className="sr-only">Loading...</span>
                </Spinner> </>
        }

    }

    async retrievePartner() {
        let data = {
            it: this.currentUser.get("sessionToken")
        }

        const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/v1/retrieve-partner`, {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json'
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify(data)
        });

        if (response.status !== 200) {
            // console.log('hmm => '+ JSON.stringify(response));
            alert(`Unknown error occurred. Please try again later. (${response.status}-${response.statusText})`);
            // throw Error({ status: response.status, message: response.statusText });
        }

        const result = await response.json();
        console.log(`result=${JSON.stringify(result)}`);
        this.setState({
            partnerID: result.partner,
            level: result.level,
            referred: result.referred,
            payable: result.payable,
            note: result.note
        });
    }

    // async retrieveBalance() {
    //     // Update the displayed balance
    //     let kontostand = Parse.Object.extend("Kontostand");
    //     let queryBalance = new Parse.Query(kontostand);
    //     queryBalance.descending("createdAt");
    //     queryBalance.notEqualTo("balance", null);

    //     try {
    //         let balanceResult = await queryBalance.first();
    //         let balance = balanceResult.get("balance");
    //         if (balance === undefined) {
    //             balance = 0;
    //         }
    //         this.setState({ balance: balance, loadingSpinner: null });
    //     } catch (error) {
    //         console.log('error while retrieveing the balance; '+ JSON.stringify(error));
    //         this.setState({ balance: "NA" });
    //     }
    // }

    async componentDidMount() {
        try {
            await Parse.Session.current();
            this.currentUser = await Parse.User.currentAsync();

            if (this.currentUser.get("consent") === true && this.currentUser.get("consent_partner") === true) {
                this.context.setLoginState(true, true);
                this.setState({ mode: 'consentConfirmed' });

                await this.retrievePartner();
            } else {
                this.setState({ mode: 'consentRequired' });
            }
        } catch (error) {
            if (error.code === 209) {
                await Parse.User.logOut();
            }
            this.setState({ mode: 'loginRequired' });
        }
    }

    getCurrentPayable() {
        return this.state.payable;
    }

    render() {
        let children;

        if (this.state.mode === 'consentConfirmed') {
            // userCard = JSON.stringify(currentUser);
            children = <Container fluid>
                <Row className="justify-content-center">
                    <Col md={11} style={{ backgroundColor: colorPens.light, border: '1px solid ' + colorPens.main, borderRadius: '5px' }}>
                        <Container fluid>
                            <Row>
                                <Col>&nbsp;</Col>
                            </Row>
                            <Row className="justify-content-center">
                                <h3>Partner Dashboard</h3>
                            </Row>
                            <Row>
                                <Col>&nbsp;</Col>
                            </Row>
                            <Row>
                                <Col md="3"><b>Your Referral Link</b></Col>
                                <Col>{this.state.partnerID !== null ?
                                    (<>                                {process.env.REACT_APP_AWS_COGNITO_SIGNOUT_URI}/account/signup/referral/{this.state.partnerID}/{process.env.REACT_APP_11K_PARTNERS_CUSTOMER_SIGNUP_BONUS_CODE}</>)
                                    :
                                    (<><i>Retrieving... please refresh the browser if this continues after 5 seconds.</i></>)
                                }
                                </Col>
                            </Row>
                            <Row>
                                <Col>&nbsp;</Col>
                            </Row>
                            <Row>
                                <Col md="3"><b>Revenue Shares <i>(Unpaid)</i></b></Col>
                                <Col><b>$ {this.state.payable}</b></Col>
                            </Row>
                            <Row>
                                <Col md="3"><b>Payout Address</b></Col>
                                <Col><u>{this.currentUser !== null ? this.currentUser.get("email") : 'loading...'}</u></Col>
                            </Row>
                            <Row>
                                <Col>&nbsp;</Col>
                            </Row>
                            <Row>
                                <Col md="3">Total number of referrals</Col>
                                <Col>{this.state.referred}</Col>
                            </Row>
                            {/* <Row>
                                <Col md="3">Revenue Shares (Total)</Col>
                                <Col>$ #,###.##</Col>
                            </Row> */}
                            <Row>
                                <Col>&nbsp;</Col>
                            </Row>
                            <Row>
                                <Col md="3"><b>Partner Terms</b></Col>
                                <Col>
                                    <a href="/partners/terms" target="_blank">11Klear Partner Program Agreenment</a>
                                </Col>
                            </Row>
                            <Row>
                                <Col>&nbsp;</Col>
                            </Row>

                        </Container>
                    </Col>
                </Row>

            </Container>
        } else if (this.state.mode === 'consentRequired') {
            this.props.history.push({ pathname: '/partners/consentRequired', state: { target: this.props.location } });
            children = <Container fluid>
                <Row>
                    <Col>
                        <Alert variant="primary">Loading...</Alert>
                    </Col>
                </Row>
            </Container>;
        } else if (this.state.mode === 'loginRequired') {
            this.props.history.push({ pathname: '/account/profile/partners', state: { target: this.props.location } });
            children = <Container fluid>
                <Row>
                    <Col>
                        <Alert variant="primary">Loading...</Alert>
                    </Col>
                </Row>
            </Container>;
        } else {
            children = <Container fluid>
                <Row>
                    <Col>
                        <Alert variant="primary">Loading...</Alert>
                    </Col>
                </Row>
            </Container>;
        }

        return children
    }
}

PartnerDashboard.contextType = UserContext;
export default PartnerDashboard;