// import PropTypes from 'prop-types'
import React, { useState } from 'react';

// import COLORS from '../utils/colors'
// import { card as cardStyle } from '../utils/styles'

import { Formik } from "formik";
import * as yup from "yup";
import { Form, Button, Row, Col, Alert, Tooltip, OverlayTrigger } from 'react-bootstrap';
// import { useAlert } from 'react-alert'

import TinyFlagReact from "tiny-flag-react";
// import PhoneFormatter from "react-headless-phone-input";
import PhoneFormatter from "react-headless-phone-input/lazy";

import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CheckIcon from '@material-ui/icons/Check';
import PhoneIcon from '@material-ui/icons/Phone';

// import { Auth } from 'aws-amplify';

// yup.addMethod(yup.string, "phone", function (messageError = 'Phone number is not valid') {
//     const phoneRegExp = /^\+(?:[0-9] ?){6,14}[0-9]$/;
//     return this.test('phone', messageError, value => {
//         if (value && value.length > 0) {
//             return phoneRegExp.test(value)
//         }
//         return true
//     })
// })

import { colorPens } from '../utils/colors';

const schema = yup.object({
    username: yup
        .string()
        .email("Username must be a valid email address")
        .required("E-mail address is a required field"),
    first_name: yup
        .string()
        .max(64)
        .required("First name is a required field"),
    last_name: yup
        .string()
        .max(64)
        .required("Last name is a required field"),
    company_name: yup
        .string()
        .max(128),
    consent_marketing: yup
        .bool()
        .oneOf([true], 'To notify the future availability in your area, we need your consent to our marketing communications and privacy policy. You may opt out by unsubscribing from the link included in the confirmation email sent to you.'),
    // phone_number: yup
    //     .string()
    //     .phone()
})

const PreorderAccountForm = (props) => {
    let { campaignName, campaignIdentifier, wipFormData, country, region } = props;

    const [e164, setE164] = useState(wipFormData.phone_number !== undefined ? wipFormData.phone_number : "");
    const [e164Valid, setE164Valid] = useState(true);
    // const [partnerConsented, setPartnerConsented] = useState(null);

    return (
        <Formik
            validationSchema={schema}
            onSubmit={(values, actions) => {
                // if (campaignName === 'partners' && values["consent_partner"] !== true) {
                //     setPartnerConsented(false);
                //     return false;
                // } else {
                //     setPartnerConsented(true);
                // }

                // window.alert('All GOOD! This is a drill, so we are not gonna do the actual sign up now. Thanks!');
                // return false;

                values["phone_number"] = e164;
                if (e164Valid) {
                    props.onSubmit(values);
                } else {
                    return false;
                }
            }}
            initialValues={{
                username: wipFormData.username !== undefined ? wipFormData.username : '',
                first_name: wipFormData.first_name !== undefined ? wipFormData.first_name : '',
                last_name: wipFormData.last_name !== undefined ? wipFormData.last_name : '',
                company_name: wipFormData.company_name !== undefined ? wipFormData.company_name : '',
                phone_number: wipFormData.phone_number !== undefined ? wipFormData.phone_number : '',
                consent_marketing: wipFormData.consent_marketing !== undefined ? wipFormData.consent_marketing : true
            }}>
            {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                isValid,
                errors,
            }) => (
                <Row className="justify-content-center">
                    <Col md={8} style={{ backgroundColor: colorPens.light, border: '1px solid ' + colorPens.main, borderRadius: '5px' }}>
                        <Form noValidate onSubmit={handleSubmit}>
                            <Row className="justify-content-center">
                                <Col md={11}>
                                    <br />
                                    {campaignName === 'partners' ? (
                                        <h4>New Partner Account Pre-Registration</h4>
                                    ) : (
                                        <h4>New Account Pre-Registration</h4>
                                    )}
                                </Col>
                            </Row>
                            <Row className="justify-content-center">
                                <Col md={11}>
                                    <h5>Please fill out the contact info and click <i>Sign Up</i> at the bottom</h5><br />
                                </Col>
                            </Row>
                            {/* <Form.Row className="justify-content-center">
                        <Form.Group as={Col} md="11">
                            <Form.Label>Invitation Reference</Form.Label>
                            <Form.Control
                                type="text"
                                name="campaign_identifier"
                                value={campaignIdentifier}
                                readOnly
                            />
                        </Form.Group>
                    </Form.Row> */}
                            {/* <Form.Row className="justify-content-center">
                                <Form.Group as={Col} md="5">
                                    <Form.Label>Country</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="country"
                                        value={country}
                                        readOnly
                                    />
                                </Form.Group>
                                <Form.Group as={Col} md={1}></Form.Group>
                                <Form.Group as={Col} md="5">
                                    <Form.Label>Region</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="region"
                                        value={region}
                                        readOnly
                                    />
                                </Form.Group>
                            </Form.Row> */}

                            <Form.Row className="justify-content-center">
                                <Form.Group as={Col} md="11" controlId="validationFormik101">
                                    <Form.Label>E-mail address *</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="username"
                                        value={values.username}
                                        onChange={handleChange}
                                        isValid={touched.username && !errors.username}
                                        isInvalid={touched.username && errors.username}
                                        placeholder="Enter your email address"
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.username}</Form.Control.Feedback>
                                </Form.Group>

                            </Form.Row>

                            <Form.Row className="justify-content-center">
                                <Form.Group as={Col} md="5" controlId="validationFormik104">
                                    <Form.Label>First name *</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="first_name"
                                        value={values.first_name}
                                        onChange={handleChange}
                                        isValid={touched.first_name && !errors.first_name}
                                        isInvalid={touched.first_name && errors.first_name}
                                        placeholder="First name"
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.first_name}</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} md={1}></Form.Group>
                                <Form.Group as={Col} md="5" controlId="validationFormik105">
                                    <Form.Label>Last name *</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="last_name"
                                        value={values.last_name}
                                        onChange={handleChange}
                                        isValid={touched.last_name && !errors.last_name}
                                        isInvalid={touched.last_name && errors.last_name}
                                        placeholder="Last name"
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.last_name}</Form.Control.Feedback>
                                </Form.Group>
                            </Form.Row>
                            <Form.Row className="justify-content-center">
                                <Form.Group as={Col} md="11" controlId="validationFormik106">
                                    <Form.Label>Phone number</Form.Label>
                                    <PhoneFormatter defaultCountry="CA" value={e164} onChange={setE164}>
                                        {({ country, impossible, onBlur, onInputChange, inputValue }) => {
                                            if (impossible) {
                                                setE164Valid(false);
                                            } else {
                                                setE164Valid(true);
                                            }

                                            return (
                                                <>
                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                        <span
                                                            style={{
                                                                fontSize: "24px",
                                                            }}>
                                                            {country ? (
                                                                <TinyFlagReact
                                                                    country={country}
                                                                    alt={country + " flag"}
                                                                    fallbackImageURL={`https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/${country}.svg`}
                                                                />
                                                            ) : (
                                                                <PhoneIcon fontSize="inherit" />
                                                            )}
                                                        </span>&nbsp;
                                                        <input
                                                            type="tel"
                                                            name="phone_number"
                                                            value={inputValue}
                                                            onBlur={(e) => {
                                                                onBlur(e);
                                                            }}
                                                            onChange={(e) => {
                                                                onInputChange(e.target.value);
                                                            }}
                                                        />
                                                    </div>
                                                    {inputValue !== null && impossible ? (
                                                        <Col style={{ color: "red", fontSize: 13 }}>Must be a valid phone number <ErrorOutlineIcon fontSize="small" /></Col>
                                                    ) : null}
                                                </>
                                            )
                                        }}
                                    </PhoneFormatter>
                                    <Form.Control.Feedback type="invalid">{errors.phone_number}</Form.Control.Feedback>
                                </Form.Group>
                            </Form.Row>
                            <Form.Row className="justify-content-center">
                                <Form.Group as={Col} md="11" controlId="validationFormik107">
                                    <Form.Label>Company Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="company_name"
                                        value={values.company_name}
                                        onChange={handleChange}
                                        isValid={touched.company_name && !errors.company_name}
                                        isInvalid={touched.company_name && errors.company_name}
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.company_name}</Form.Control.Feedback>
                                </Form.Group>
                            </Form.Row>
                            <Form.Row className="justify-content-center">
                                <Form.Group as={Col} md="11">
                                    <Form.Check
                                        required
                                        name="consent_marketing"
                                        // value={values.consent}
                                        checked={values.consent_marketing}
                                        label="Send me updates with tailored offers and productivity tips. *"
                                        onChange={handleChange}
                                        isInvalid={!!errors.consent_marketing}
                                        feedback={errors.consent_marketing}
                                        id="validationFormik106"
                                        feedbackTooltip
                                    />
                                </Form.Group>
                            </Form.Row>
                            <br />
                            <Row className="justify-content-center">
                                <Col md={9}><i>- required (*)</i></Col>
                                <Col md={2}><Button variant="primary" type="submit">Sign Up</Button></Col>
                            </Row>
                            <br />
                        </Form>

                    </Col>
                </Row>
            )}
        </Formik>
    );
}

// const AccessToken = ({ token }) => {
//     const codeStyle = {
//         fontFamily: 'monospace',
//         wordWrap: 'break-word',
//         margin: '.5em 0',
//         padding: '.5em',
//         fontSize: '85%',
//         backgroundColor: 'rgba(27,31,35,0.05)',
//         borderRadius: '3px'
//     }

//     return (
//         <div style={{ fontSize: '.8rem' }}>
//             <label style={{ color: COLORS.lightColor, paddingRight: '.25rem' }}>Access token :</label>
//             <div style={codeStyle}>{token}</div>
//         </div>
//     )
// }

export default PreorderAccountForm;