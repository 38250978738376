// import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react';

// import COLORS from '../utils/colors'
// import { card as cardStyle } from '../utils/styles'

import { Formik } from "formik";
import * as yup from "yup";
import { Form, Button, Row, Col, Alert } from 'react-bootstrap';

import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CheckIcon from '@material-ui/icons/Check';
import PhoneIcon from '@material-ui/icons/Phone';

import { colorPens } from '../utils/colors';

const schema = yup.object({
    username: yup
        .string()
        .email("Username must be a valid email address")
        .required("E-mail address is a required field")
})

const ResetPasswordForm = (props) => {
    let { wipFormData } = props;

    return (
        <Formik 
            validationSchema={schema} 
            onSubmit={(values, actions) => {
                props.onSubmit(values);
            }}
            initialValues={{ 
                username: wipFormData.username !== undefined ? wipFormData.username : '' }}>
            {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                isValid,
                errors,
            }) => (
                <Row className="justify-content-center">
                    <Col md={5} style={{ backgroundColor: colorPens.light, border: '1px solid '+ colorPens.main, borderRadius: '5px' }}>
                        <Form noValidate onSubmit={handleSubmit}>
                        <Row className="justify-content-center">
                            <Col md={11}>
                                <br />
                                <h4>Reset your password</h4>
                            </Col>
                        </Row>
                        <Row className="justify-content-center">
                            <Col md={11}>
                                <h5>Step 1/2 - Please enter your registered e-mail address.</h5><br />
                            </Col>
                        </Row>
                        <Form.Row className="justify-content-center">
                            <Form.Group as={Col} md={11} controlId="validationFormik101">
                                <Form.Label>E-mail address *</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="username"
                                    value={values.username}
                                    onChange={handleChange}
                                    isValid={touched.username && !errors.username}
                                    isInvalid={touched.username && errors.username}
                                    placeholder="Enter your email address"
                                />
                                <Form.Control.Feedback type="invalid">{errors.username}</Form.Control.Feedback>
                                <Form.Text className="text-muted">No need to reset? <Button variant="link" size="sm" onClick={() => {window.location.href='/account/profile'}}>Go Back</Button></Form.Text>
                            </Form.Group>
                        </Form.Row>
                        <br />
                        <Row className="justify-content-center">
                            <Col md={6}>&nbsp;</Col>
                            <Col md={5}><Button variant="primary" type="submit">Send the request</Button></Col>
                        </Row>
                        <br />
                    </Form>

                    </Col>
                </Row>
            )}
        </Formik>
    );
}

export default ResetPasswordForm;