import React, { Component } from "react";
import { Container, Row, Col, Image, Alert, Card, Badge, Button } from 'react-bootstrap';
import { colorPens } from './utils/colors';

import CheckIcon from '@material-ui/icons/Check';

import { UserContext } from './components/userContext';

// import PartnerDashboard from "./PartnerDashboard";

const JoinButton = () => {
    return (<>
        <UserContext.Consumer>
            {(context) => {
                if (!context.asPartner) {
                    return (<>
                        <h3>Claim your 11Klear<sup>®</sup> Partner Privilege today!</h3><br />
                        <Button
                            style={{
                                fontSize: 30,
                                color: colorPens.light,
                                backgroundColor: colorPens.success
                            }}
                            // onClick={() => {
                            //     if (!context.isLoggedIn) {
                            //         window.location.href = '/account/signup/partners/11Klear/' + process.env.REACT_APP_11K_PARTNERS_SIGNUP_BONUS_CODE;
                            //     } else {
                            //         window.location.href = '/partners/consentRequired';
                            //     }
                            // }}>JOIN NOW</Button>
                            onClick={() => {
                                window.location.href = 'https://11klear.freshdesk.com/support/tickets/new';
                            }}>JOIN NOW</Button>                           
                    </>);
                } else {
                    return (<>
                        <h3>You're already an 11Klear Partner!</h3><br />
                        <Button style={{
                            fontSize: 18,
                            color: colorPens.light,
                            backgroundColor: colorPens.primary
                        }}
                            onClick={() => {
                                window.location.href = '/partners/dashboard';
                            }}
                        >
                            Check out the Partner Dashboard
                        </Button>
                    </>);
                }
            }}
        </UserContext.Consumer>
    </>)
}

class Partner extends Component {
    // const { name } = props.match.params;

    // if (name === undefined) {
    //     name = 'privacy';
    // }
    constructor(props) {
        super(props);

        this.divStyle = {
            paddingTop: "1vh",
            paddingBottom: "1vh",
            paddingRight: "3vw",
            paddingLeft: "3vw"
        }
    }

    render() {
        let name = this.props.match.params.name;

        if (name === 'terms') {
            return (
                <Container fluid>
                    <div style={this.divStyle}>
                        <h2>Partner Program Agreement</h2>
                        <i>Last updated: November 25, 2021</i><br />
                        <br />
                        <h6>By clicking on “I agree” (or a similar box or button) when you sign up for a Partner Account, using the Partner Platform or participating in any Partner Program activities, you agree to be bound by the applicable sections of the following Partner Program Agreement (the “<b>Agreement</b>”). The Agreement is between you, as Partner (as defined below in Section A.1.) and 11Edge Corporation (“<b>11Edge</b>”). You can review the current version of the Agreement at any time at 11klear.com/partners/terms. 11Edge reserves the right to update and change the Agreement by posting updates and changes here: 11klear.com/partners/terms. If a significant change is made, we will provide reasonable notice by email, posting a notice on the 11Klear Partner News or in the Partner Dashboard. You are advised to check the Agreement from time to time for any updates or changes that may impact you. Any reference to the Agreement includes any and all terms and documents incorporated by reference.</h6>

                        <h6>This Agreement addresses different types of Partner activities. <a href="#part-a">Part A</a> applies to all Partners. <a href="#part-b">Part B</a> applies to Referral Partners. <a href="#part-c">Part C</a> applies to 11Klear Experts Program. If you do not participate in partner activities described in Parts B, or C, then these Parts of the Agreement do not apply to you.</h6>

                        <h6>You must read, agree with and accept all of the terms and conditions contained in this Agreement, including <a href="/policy/privacy">11Klear’s Privacy Policy</a> and <a href="/terms">11Klear’s Terms of Service</a>, before you may become a Partner. For the avoidance of doubt, 11Klear’s Privacy Policy and 11Klear’s Terms of Service form part of this Agreement and are incorporated by reference. For the purposes of the Partner Program and this Agreement, all references to “Account” and “Services” in 11Klear’s Terms of Service will be deemed to refer to “Partner Account” and “Services or Partner’s participation in the Partner Program”, respectively. Some types of 11Klear Partner Program activities may require that you agree to additional terms (“<b>Additional Terms</b>”). Such Additional Terms are incorporated into this Agreement by reference. In the event of a conflict or inconsistency between this Agreement and the Additional Terms, the Additional Terms will govern, to the extent of such conflict or inconsistency.</h6>

                        <h6><b>Everyday language summaries are provided for convenience only and are not legally binding. Please read the entire Agreement for the complete picture of your legal requirements. This Agreement governs your activities as a Partner, including access to the Partner Dashboard and participation in the activities described on the Partner Program website. Be sure to occasionally check back for updates.</b></h6>

                        <br />

                        <h3 id="part-a">Part A - Terms Applicable to All Partners</h3>
                        <h4>1. Definitions</h4>

                        <Row>
                            <Col>
                                Unless defined elsewhere in the Agreement, capitalized terms set out in the Agreement are defined as follows:
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                &nbsp;&nbsp;&nbsp;&nbsp;“<b>11Edge</b>” means 11Edge Corporation (11edge.com), the sole owner/operator of 11Klear.<br />
                                &nbsp;&nbsp;&nbsp;&nbsp;“<b>11Klear</b>” means the delivery confirmation software as a service (11klear.com) and the branded department within 11Edge.<br />
                                &nbsp;&nbsp;&nbsp;&nbsp;“<b>Application</b>” or “<b>App</b>” means 11Klear web, mobile applications and API defined in the 11Klear Terms of Service.<br />
                                &nbsp;&nbsp;&nbsp;&nbsp;“<b>Customer</b>” means an individual or business that uses the Service.<br />
                                &nbsp;&nbsp;&nbsp;&nbsp;“<b>Customer Account</b>” means a registered account on 11Klear by the Customer.<br />
                                &nbsp;&nbsp;&nbsp;&nbsp;“<b>Customer Data</b>” means information (including personal information) relating to a Customer, including, but not limited to, order information, payment information, and account information.<br />
                                &nbsp;&nbsp;&nbsp;&nbsp;“<b>Partner</b>” or “<b>You</b>” means an individual or entity that has agreed to the terms of this Agreement and participates in the 11Klear Partner Program. The different types of Partners are listed below:<br />
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1.	A “<b>Referral Partner</b>” is a Partner who has registered for a Partner Account via the <a href="/partners">11Klear partner program</a> page and who promotes the service by:<br />
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;a. registering, and being approved by 11Klear, for a unique referral link (an “<b>Affiliate Link</b>”) to refer Customers to 11Klear via such Affiliate Link or<br />
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;b. acting as a value added partner or “<b>VAP</b>” working directly with Customers as a designer, developer, or other consultant.<br />
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2.	An “<b>Expert</b>” is a VAP who has been accepted by 11Klear into the Experts Program, and can publish a profile offering services to Customers.<br />
                                &nbsp;&nbsp;&nbsp;&nbsp;“<b>Partner Account</b>” means a 11Klear Partner Program account.<br />
                                &nbsp;&nbsp;&nbsp;&nbsp;“<b>Partner Dashboard</b>” means the internal administrative page available at: <a href="/partners/dashboard">https://11klear.com/partners/dashboard</a> that allows Partners to manage their Partner Account.<br />
                                &nbsp;&nbsp;&nbsp;&nbsp;“<b>Partner Manager</b>” means the 11Edge employee designated by 11Edge from time to time as the Partner’s primary 11Klear contact.<br />
                                &nbsp;&nbsp;&nbsp;&nbsp;“<b>Payment Period</b>” has the meaning as set out in Section 3.2.<br />
                                &nbsp;&nbsp;&nbsp;&nbsp;“<b>Partner Program</b>” means the resources made available by 11Edge to Partners. For the avoidance of doubt, the Partner Program includes the Channel Program and Experts Program, to the extent that Partner is invited to participate in those programs.<br />
                                &nbsp;&nbsp;&nbsp;&nbsp;“<b>Referred Customer</b>” means any unique Customer that: (a) has registered for a paid 11Klear account; and (b) was introduced by a Referral Partner that actively promoted the Service.<br />
                                &nbsp;&nbsp;&nbsp;&nbsp;“<b>Revenue Generating Activity</b>” means a revenue generating activity carried out by Partner, as determined by 11Klear, including the following:<br />
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- introduction of a Referred Customer to 11Klear via Partner’s Affiliate Link.<br />
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;A Revenue Generating Activity will be attributed to Partner on the date the Customer makes the payment of any subscription fees or prepaid service credits for the relevant Customer account. For more information regarding Revenue Generating Activities, please refer to <a href="https://11klear.freshdesk.com/a/solutions/articles/80000963483">Getting Started (How to Earn)</a>.<br />
                                &nbsp;&nbsp;&nbsp;&nbsp;“<b>Service</b>” means the 11Klear platform available via www.11Klear.com and any associated websites, products or services offered by 11klear.<br />
                                &nbsp;&nbsp;&nbsp;&nbsp;“<b>11Klear API</b>” is defined in the 11Klear Terms of Service.<br />
                                &nbsp;&nbsp;&nbsp;&nbsp;“<b>11Klear API Terms</b>” means Services Adaptations and API terms in the 11Klear Terms of Service.<br />
                                &nbsp;&nbsp;&nbsp;&nbsp;“<b>11Klear Creative</b>” means any marketing or promotional materials relating to 11Klear or 11Klear brands, including but not limited to copyrighted content, hypertext links, domain names, icons, buttons, banners, graphic files, images and the 11Klear Trademarks.<br />
                                &nbsp;&nbsp;&nbsp;&nbsp;“<b>11Klear Related Entities</b>” means any entity that directly or indirectly controls, is controlled by, or is under common control with, 11Klear; where “control” means the possession, directly or indirectly, of the power to direct or cause the direction of the management policies of another, whether through the ownership of voting securities, by contract, as trustee or executor, or otherwise.<br />
                                &nbsp;&nbsp;&nbsp;&nbsp;“<b>11Klear Trademarks</b>” means the trademarks, logos, service marks and trade names of 11Klear and any 11Klear Related Entities, whether registered or unregistered, including but not limited to the word mark 11Klear and the graphic-design mark with the checkmark.
                                &nbsp;&nbsp;&nbsp;&nbsp;“<b>Taxes</b>” means all taxes, federal, provincial, state, local or other governmental sales, value added, goods and services, harmonized or other taxes, fees or charges now in force or enacted in the future.<br />
                                &nbsp;&nbsp;&nbsp;&nbsp;“<b>Payout Fees</b>” means all transaction fees or charges incurred by the payment processor when each payout is processed. For more information regarding the latest fee schedule, please refer to <a href="https://11klear.freshdesk.com/a/solutions/articles/80000963484">Getting Started (Payout Fees)</a><br />
                                &nbsp;&nbsp;&nbsp;&nbsp;“<b>Websites</b>” means any websites that are owned, operated or managed by a Partner and that relate to Partner’s activities pursuant to this Agreement.<br />
                                <br />
                            </Col>
                        </Row>
                        <Row className="justify-content-center">
                            <Col md={11}>
                                <Card>
                                    <Card.Header>WHICH MEANS</Card.Header>
                                    <Card.Body>
                                        This section assigns a specific meaning to some of the capitalized terms used in this agreement. Other capitalized terms are defined elsewhere in the Agreement.
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>

                        <br />
                        <h4>2. Partner Responsibilities</h4>

                        <h5>2.1. Marketing Activities</h5>

                        <p>
                            1.	Partner will bear all costs and expenses related to Partner’s marketing or promotion of 11Klear or any 11Klear Related Entity, and, as applicable, Partner’s Applications, Websites, Channel Platform, or Partner’s other products or services associated with Partner’s participation in the Partner Program (collectively, “Partner Marketing Activities”) in any area, location, territory or jurisdiction, unless otherwise determined by 11Klear in its sole discretion.<br />
                            2.	In no event will Partner engage in any Partner Marketing Activities except as expressly set forth in this Agreement. In the event that Partner has a list of emails where the individuals on the list have expressly elected to receive emails from Partner (“Opt-in List”), Partner may make a written request to 11Klear to send emails regarding the offering of 11Klear and 11Klear Related Entities to the individuals on the Opt-in List (and 11Klear may, in its sole discretion, allow Partner to send such emails). In conducting all Partner Marketing Activities, Partner will comply with all applicable laws, rules, regulations and directives, including but not limited to those relating to email marketing and “spamming”. Unless Partner has secured the applicable Customer’s consent first, Partner will not email any Customer whose email address they have received via 11Klear.<br />
                            3.	Without limiting the generality of Section 2.1.2, Partner will (i) not send any email regarding 11Klear or 11Klear Related Entities to any individual or entity that has not requested such information; (ii) always include Partner’s contact information and “unsubscribe” information in any email regarding 11Klear, 11Klear Related Entities, the Service or the 11Klear platform; and (iii) not imply that such emails are being sent on behalf of 11Klear or 11Klear Related Entities.<br />
                            4.	A Partner will not (i) engage in any fax, broadcast, telemarketing or any other offline marketing methods with respect to 11Klear or 11Klear Related Entities; (ii) use malware, spyware or any other aggressive advertising or marketing methods in any of its dealings relating to 11Klear or 11Klear Related Entities; (iii) make any false, misleading or disparaging representations or statements with respect to 11Klear or 11Klear Related Entities; (iv) solicit Customers to leave the 11Klear Service; (v) copy, resemble or mirror the look and feel of 11Klear’s websites, 11Klear Trademarks or Services or otherwise misrepresent Partner’s affiliation with 11Klear or 11Klear Related Entities; or (vi) engage in any other practices which may adversely affect the credibility or reputation of 11Klear or 11Klear Related Entities, including but not limited to, sending email communications or using any Website in any manner, or having any content on any Website, that (a) uses aggressive or low-quality marketing, including marketing services that are unrelated to 11Klear or the Partner’s services, (b) promotes sexually explicit materials, violence, discrimination based on race, sex, religion, nationality, disability, sexual orientation or age, or any illegal or objectionable activities, or (c) violates any intellectual property or other proprietary rights of any third party; or (d) violates the Use Restriction Policy.</p>

                        <Row className="justify-content-center">
                            <Col md={11}>
                                <Card>
                                    <Card.Header>WHICH MEANS</Card.Header>
                                    <Card.Body>
                                        Partners cannot send email marketing materials about 11Klear without 11Klear’s permission. If 11Klear gives its permission, the messages must comply with this section and all applicable laws. Partner cannot engage in any illegal or aggressive marketing techniques on behalf of 11Klear. Partner must not make any false or misleading statements about 11Klear or represent anything that could cause harm to the credibility of 11Klear. In all of Partner’s marketing activities, Partner must comply with all applicable laws. Do not email a Customer without their prior consent.
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>

                        <br />
                        <h5>2.2. Compliance with Laws</h5>

                        In addition to, and without limiting the provisions of this Agreement, Partner will perform its obligations under this Agreement in accordance with the highest applicable industry standards and in compliance with all applicable laws, rules and regulations, including obtaining any licenses required in order for Partner to operate and to offer the products or services associated with Partner’s participation in the Partner Program.<br />
                        Partner represents and warrants that none of the Partner, its subsidiaries, or any director or officer thereof, is, or is owned or controlled by individuals or entities that are: (i) the target of any sanctions administered or enforced by the U.S. Department of the Treasury’s Office of Foreign Asset Control or the U.S. State Department, the United Nations Security Council, the European Union or Her Majesty’s Treasury (collectively, “Sanctions”), or (ii) located, organized or resident in a country or territory that is the subject of comprehensive territorial Sanctions.<br />
                        <br />
                        <Row className="justify-content-center">
                            <Col md={11}>
                                <Card>
                                    <Card.Header>WHICH MEANS</Card.Header>
                                    <Card.Body>
                                        Partner will comply with all applicable laws and highest industry standards.
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <br />
                        <h5>2.3. Partner Duty to Inform</h5>

                        Partner will promptly inform 11Klear of any information known to Partner that could reasonably lead to a claim, demand or liability of or against 11Klear or the 11Klear Related Entities by any third party.<br />
                        <br />
                        <Row className="justify-content-center">
                            <Col md={11}>
                                <Card>
                                    <Card.Header>WHICH MEANS</Card.Header>
                                    <Card.Body>
                                        Partner has to keep 11Klear informed about any potential claims, demands or liabilities relating to 11Klear of which the Partner is aware.
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <br />

                        <h5>2.4. Partner Duty to Disclose</h5>

                        If Partner is acting as an agent on behalf of a Customer, then Partner will disclose to the Customer any Fees that Partner is entitled to receive from 11Klear in accordance with this Agreement that are associated with such Customer.<br />
                        <br />
                        <Row className="justify-content-center">
                            <Col md={11}>
                                <Card>
                                    <Card.Header>WHICH MEANS</Card.Header>
                                    <Card.Body>
                                        If Partner is acting as Customer’s agent, then Partner must disclose any Fees that Partner will earn under the Partner Program that relate to the Customer.
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <br />

                        <h5>2.5. Other Partner Terms</h5>
                        1.	If the Partner is an individual, you must be the older of (i) 18 years, or (ii) at least the age of majority in the jurisdiction where you reside and from where you carry out Partner activities.<br />
                        2.	You confirm that you are becoming a Partner for the purposes of carrying on a business activity and not for any personal, household or family purpose.<br />
                        3.	To become a Partner, the Partner must create a Partner Account by providing all information indicated as required. 11Klear may reject an application for a Partner Account for any reason, in its sole discretion. Partner acknowledges that 11Klear will use the email address provided by Partner as the primary method for communication. Partner is responsible for keeping its Partner Account password secure. 11Klear cannot and will not be liable for any loss or damage arising from Partner’s failure to maintain the security of the Partner Account and password.<br />
                        4.	If you sign up for a Partner Account on behalf of your employer, your employer will be deemed to be the Partner for the purpose of this Agreement, and you represent and warrant that you have the authority to bind your employer to this Agreement. Each Partner is responsible for: (a) ensuring that its employees, agents and subcontractors comply with this Agreement and (b) any breach of this Agreement by Partner’s employees, agents or subcontractors.<br />
                        5.	Partner acknowledges and agrees that Partner will be responsible for the performance of all of its obligations under the Agreement, regardless of whether it sublicenses or subcontracts any such obligations to any third party, including but not limited to any affiliates or subsidiaries of Partner.<br />
                        6.	Partner acknowledges and agrees that 11Klear may amend this Agreement at any time by posting the relevant amended and restated Partner Program Agreement on 11Klear’s website, available at 11Klear.com/partners/terms and such amendments to the Agreement are effective as of the date of posting. If a significant change is made, 11Klear will provide reasonable notice by email, posting a notice on the 11Klear Partner Blog or in the Partner Dashboard. Partner’s continued participation in the 11Klear Partner Program after the amended Partner Program Agreement is posted to 11Klear’s website constitutes Partner’s agreement to, and acceptance of, the amended Agreement. If Partner does not agree to any changes to the Agreement, Partner must terminate the Agreement by discontinuing its participation in the 11Klear Partner Program.<br />
                        7.	Partner acknowledges and agrees that Partner’s participation in the 11Klear Partner Program, including information transmitted to or stored by 11Klear, is governed by the 11Klear Privacy Policy found at 11klear.com/policy/privacy.<br />

                        <br />
                        <Row className="justify-content-center">
                            <Col md={11}>
                                <Card>
                                    <Card.Header>WHICH MEANS</Card.Header>
                                    <Card.Body>
                                        Partner must (i) be at least 18 years old or the age of majority where Partner resides; (ii) provide certain information and agree that email will be our primary method of communication; (iii) make certain acknowledgments if signing up on behalf of an employer or a company; (iv) agree that this Agreement may be changed by 11Klear at any time; and (v) acknowledge and agree to 11Klear’s privacy policy.
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <br />

                        <h4>3. Fees and Payments</h4>

                        <h5>3.1. Revenue Sharing Plans</h5>

                        <p>
                            Subject to: (i) Partner’s compliance with this Agreement, and (ii) the revenue sharing plan associated with a Partner’s activities pursuant to the Partner Program, Partner will be entitled to receive certain fees from 11Klear (the “Fees”). The revenue sharing plans applicable to the different types of Referral Partners are set out in Part B. The revenue sharing plans applicable to Experts set out in Part C.</p>

                        <h5>3.2. Payment</h5>

                        <p>1.	Fees due to Partner under the Reference Plan will be calculated by 11Klear once per month upon receipt of payment from the Customer, for each month that the Referred Customer Account is active, provided that Partner has carried out at least one (1) Revenue Generating Activity in the immediately preceding 12-month period (as set out in Part B, Section 2.1). Fees due to Partner under the Premium Plan will be calculated by 11Klear once per month upon receipt of payment from the Customer, for each month that the Referred Customer Account is active, provided that Partner has carried out at least one (1) Revenue Generating Activity in the immediately preceding 12-month period (as set out in Part C, Section 2.2.1).<br />
                            2.	11Klear distributes Fees owing to its Partners once per calendar month (each such period, a “Payment Period”). The Payment Period occurs during the  month following  the Fee calculation. Where the Fees owing to Partner are greater than USD $25 at the end of any Payment Period, the Fees will be paid to Partner. If the Fees owing to Partner are less than USD $25 at the end of any Payment Period, 11Klear will be entitled to withhold payment of Fees until the end of the next Payment Period in which the balance of unpaid Fees owing to Partner from any previous Payment Period and Fees owing to Partner under the current Payment Period are USD $25 or more.<br />
                            3.	Partners are responsible for all applicable Taxes that arise from or as a result of any activities under this Agreement or with respect to Partners dealings with a Customer. If Taxes are not collected by 11Klear in respect of a Partner transaction with a Customer facilitated by 11Klear, Partner is responsible for determining if Taxes are payable on such transaction, and if so, self-remitting Taxes to the appropriate tax authorities.<br />
                            4.	Partners are responsible for all applicable Payout Fees that arise from or as a result of processing the commission payouts. The amount of Payout Fees is deducted in each commission invoice.<br />
                            5.	11Klear will issue you an invoice for any commission fees 11Klear is charging you for activities like product sales, and service sales. 11Klear will prepare an invoice, on your behalf, to 11Klear for any commission fees that 11Klear pays to you for referral activities. Fee and commission invoices are generated when a payout is processed. You can't view invoices for pending transactions.</p>

                        <Row className="justify-content-center">
                            <Col md={11}>
                                <Card>
                                    <Card.Header>WHICH MEANS</Card.Header>
                                    <Card.Body>
                                        Fees owing to the Partner under the Reference Plan, the Premium Plan will be paid in accordance with this section. If a Partner wants to continue receiving revenue share for Referred Customers, the Partner must carry out at least one Revenue Generating Activity in each consecutive 12-month period. Partners are responsible for all taxes and fees related to the payouts.
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <br />

                        <h5>3.3. Additional Payment Information</h5>
                        <p>
                            1.	All payments are subject to fraud and risk analysis considerations and anti-money laundering procedures and may be withheld by 11Klear during the period of investigation. 11Klear may also withhold payment if Partner fails to provide 11Klear with information that is required to make payment.<br />
                            2.	Notwithstanding anything to the contrary in this Agreement, 11Klear will not be responsible to pay any Fees:<br />
                            &nbsp;&nbsp;&nbsp;&nbsp;a. related to amounts that have been refunded to Customers by 11Klear;<br />
                            &nbsp;&nbsp;&nbsp;&nbsp;b. for a Referred Customer created or owned in whole or in part by a Partner;<br />
                            &nbsp;&nbsp;&nbsp;&nbsp;c. related to fraudulent sales;<br />
                            &nbsp;&nbsp;&nbsp;&nbsp;d. related to revenues that have been subject to chargebacks;<br />
                            &nbsp;&nbsp;&nbsp;&nbsp;e. to Partners who are employed by 11Klear (whether full-time, part-time, term or any other employment-type relationship); or<br />
                            &nbsp;&nbsp;&nbsp;&nbsp;f. to Partners who are employed by the Customer to whom the Fees relate (whether full-time, part-time, term or any other employment-type relationship).<br />
                            3.	If any Fees paid by 11Klear are subsequently discovered to be subject to one or more of the exclusions set out in Section 3.3.2, or to have been paid in error, 11Klear will have the right, at its sole discretion, to: (a) reclaim any Fees paid to Partner in error; or (b) set off the amounts described in Section 3.3 from payments due to Partner in future Payment Periods. If the Agreement is terminated before such amounts are fully repaid by Partner to 11Klear, Partner will pay to 11Klear the remaining balance within thirty (30) days of the effective date of termination of the Agreement.<br />
                            4.	11Klear reserves the right to modify the Fees or the payment terms at any time upon reasonable advance notice to Partner. Such notice will be provided by email, posting a notice on the 11Klear blog or in the Partner Dashboard. In the event of any disputes over Fees, 11Klear’s determination will be final and binding.
                        </p>

                        <Row className="justify-content-center">
                            <Col md={11}>
                                <Card>
                                    <Card.Header>WHICH MEANS</Card.Header>
                                    <Card.Body>
                                        11Klear has the right to set off or withhold payments to Partners in certain circumstances. 11Klear has the right to alter the Fees, the Fee structure or the payment terms with reasonable notice to Partner.
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <br />

                        <h4>4. Termination</h4>

                        <h5>4.1. Termination</h5>
                        <p>
                            1.	Unless otherwise specified in the Agreement, any Party may terminate this Agreement at any time, with or without cause, effective immediately upon notice to another Party.<br />
                            2.	Fraudulent or other unacceptable behaviour by Partner, including breach of the Acceptable Use Policy, as determined by 11Klear in its sole discretion, may result in one or more of the following actions being taken by 11Klear: (a) termination of Partner’s affiliation with Customers within the Partner Account; (b) suspension of some or all Partner privileges under the Partner Program; and (c) termination of the Partner Account entirely without notice to, or recourse for, Partner.<br />
                            3.	11Klear reserves the right to cancel or modify the Partner Program Agreement in its entirety, including Fees, at any time. If a significant change is made to the Partner Program Agreement, including any material change to Fees, 11Klear will provide reasonable notice by email, posting a notice on the 11Klear blog or in the Partner Dashboard.<br />
                        </p>
                        <Row className="justify-content-center">
                            <Col md={11}>
                                <Card>
                                    <Card.Header>WHICH MEANS</Card.Header>
                                    <Card.Body>
                                        Either 11Klear or Partner can end this agreement at any time by providing notice to another Party. If there is fraud or any other unacceptable behaviour by Partner, or if Partner violates the Acceptable Use Policy, 11Klear can suspend Partner’s privileges or end the agreement without notice. 11Klear can change or eliminate all or any portion of the Partner Program at any time, upon reasonable notice.
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <br />

                        <h5>4.2. Consequences of Termination</h5>
                        <p>
                            1.	Upon termination of this Agreement: (a) each Party will return to the other Party, or destroy (and provide certification of such destruction), all property of the other Party in its possession or control (including all 11Klear Creative and all Confidential Information (as defined below)); (b) Partner will immediately cease displaying any 11Klear Creative or any 11Klear Trademarks on any Website or otherwise; and (c) all rights granted to Partner under this Agreement will immediately cease, including but not limited to the right of Partner to access the Partner Account and Partner Dashboard, 11Klear API, or to receive any payments of Fees under this Agreement, unless otherwise determined by 11Klear in its sole discretion.<br />
                            2.	This Section 4.2 and the following Sections will survive any termination or expiration of this Agreement:<br />
                            &nbsp;&nbsp;&nbsp;&nbsp;1.	PART A: Section 1 (Definitions), Section 5.4 (Proprietary Rights of 11Klear), Section 6 (Confidentiality), Section 7 (Disclaimer of Warranty), Section 8 (Limitation of Liability and Indemnification), and Section 9 (General Provisions)<br />
                            &nbsp;&nbsp;&nbsp;&nbsp;2.	PART B: Section 5 (Relationship between VAP and Customer);<br />
                            In addition, any provisions of this Agreement that by their nature are intended to survive, will survive termination.
                        </p>
                        <Row className="justify-content-center">
                            <Col md={11}>
                                <Card>
                                    <Card.Header>WHICH MEANS</Card.Header>
                                    <Card.Body>
                                        If the Agreement ends, Partner must stop using and return to 11Klear all 11Klear creative material and 11Klear’s trademarks and logos and Partner will no longer be permitted to access the 11Klear API or receive Fees from 11Klear, unless otherwise determined by 11Klear in its sole discretion. The sections listed here and any other sections that by their nature are meant to survive will survive the termination of this agreement.
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <br />

                        <h4>5. Intellectual Property Rights</h4>

                        <h5>5.1. 11Klear Creative</h5>
                        <p>
                            1.	All 11Klear Creative will be solely created and provided by 11Klear unless otherwise agreed to by 11Klear in writing in advance. 11Klear will provide Partner with copies of or access to 11Klear Creative. The 11Klear Creative may also be accessible from the <a href="/partners">Partner Program website</a> and the <a href="https://11klear.freshdesk.com/a/solutions/articles/80000956661">11Klear brand guidelines</a> (“11Klear Trademark Usage Guidelines”). By using the 11Klear Creative, you indicate your acceptance of our 11Klear Trademark Usage Guidelines and you understand that a violation of these guidelines or this Agreement will result in the termination of your license or permission to use the 11Klear Creative. The 11Klear Creative is provided “as is” and without warranty of any kind.<br />
                            2.	Partner may display 11Klear Creative on the Websites solely for the purpose of marketing and promoting the Service and any 11Klear brands permitted by 11Klear and by 11Klear Related Entities during the term of this Agreement, or until such time as 11Klear may, upon reasonable prior notice, instruct Partner to cease displaying the 11Klear Creative. Partner may not alter, amend, adapt or translate the 11Klear Creative without 11Klear’s prior written consent. Nothing contained in any 11Klear Creative will in any way be deemed a representation or warranty of 11Klear or of any of 11Klear Related Entity. The 11Klear Creative will at all times be the sole and exclusive property of 11Klear and no rights of ownership will at any time vest with Partner even in such instances where Partner has been authorized by 11Klear to make changes or modifications to the 11Klear Creative.
                        </p>
                        <Row className="justify-content-center">
                            <Col md={11}>
                                <Card>
                                    <Card.Header>WHICH MEANS</Card.Header>
                                    <Card.Body>
                                        Partners may promote 11Klear using the creative materials provided by 11Klear. The creative materials of 11Klear belong to 11Klear, cannot be changed and must be used only to promote the 11Klear Services and 11Klear brand.
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <br />

                        <h5>5.2. 11Klear Trademarks</h5>
                        <p>
                            During the term of this Agreement, 11Klear hereby grants to Partner a limited, revocable, non-exclusive, non-sublicensable and non-transferable license to display the 11Klear Trademarks solely as necessary to perform Partner’s obligations under this Agreement. Partner acknowledges and agrees that: (a) it will use 11Klear’s Trademarks only as permitted under this Agreement; (b) it will use the 11Klear Trademarks in a lawful manner and in strict compliance with all format(s), guidelines, standards and other requirements prescribed by 11Klear in writing from time to time, including but not limited to the 11Klear Trademark Usage Guidelines; (c) the 11Klear Trademarks are and will remain the sole property of 11Klear; (d) nothing in this Agreement will confer in Partner any right of ownership in the 11Klear Trademarks and all use thereof by Partner will inure to the benefit of 11Klear; (e) Partner will not, now or in the future, apply for or contest the validity of any 11Klear Trademarks; and (f) Partner will not, now or in the future, apply for or use any term or mark confusingly similar to any 11Klear Trademarks.
                        </p>
                        <Row className="justify-content-center">
                            <Col md={11}>
                                <Card>
                                    <Card.Header>WHICH MEANS</Card.Header>
                                    <Card.Body>
                                        11Klear owns the 11Klear Trademarks entirely and Partner agrees to use them only in accordance with the Agreement.
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <br />

                        <h5>5.3. Restrictions on Partner’s Use of the 11Klear Trademarks</h5>
                        <p>
                            Notwithstanding Section 5.2, Partners will not:<br />
                            1.	use the 11Klear Trademarks or variations or misspellings thereof in Partner’s business name, logo, branding, advertising, social media or domain name (including without limitation top-level domains, sub-domains and page URLs), products or services (including without limitation, in the name or design of any Application or Theme), unless granted express written permission by 11Klear in advance of each use; or<br />
                            2.	purchase or register search engine or other pay-per-click keywords (such as Google Ads), trademarks, email addresses or domain names that use the 11Klear Trademarks or any variations or misspellings thereof that may be deceptively or confusingly similar to the 11Klear Trademarks.
                        </p>
                        <Row className="justify-content-center">
                            <Col md={11}>
                                <Card>
                                    <Card.Header>WHICH MEANS</Card.Header>
                                    <Card.Body>
                                        Partners cannot use “11Klear”, or the graphic-design mark with the checkmark, or any other 11Klear Trademarks in the name or design of the Partner’s business or logo, or any Partner product or service (including Apps or Themes) without permission. Partner will not buy search engine advertising, trademarks or domain names that use “11Klear” or other 11Klear Trademarks.
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <br />

                        <h5>5.4. Proprietary Rights of 11Klear</h5>
                        <p>
                            As between Partner and 11Klear, the 11Klear Creative, 11Klear Trademarks, all demographic and other information relating to Customers including Referred Customers, prospective Partners and Partners, the Services, the 11Klear API, Customer Data, and all software, documentation, hardware, equipment, devices, templates, tools, documents, processes, methodologies, know-how, websites, and any additional intellectual or other property used by or on behalf of 11Klear or 11Klear Related Entities or otherwise related to the Service, 11Klear Partner Program, 11Klear or 11Klear Related Entities, together with all copyrights, trademarks, patents, trade secrets and any other proprietary rights inherent therein and appurtenant thereto (collectively, “<b>11Klear Property</b>”) will be and remain the sole and exclusive property of 11Klear. To the extent, if any, that ownership of any 11Klear Property does not automatically vest in 11Klear by virtue of this Agreement, or otherwise, and vests in Partner, Partner hereby transfers and assigns to 11Klear, upon the creation thereof, all rights, title and interest Partner may have in and to such 11Klear Property (and waives any and all moral rights, as applicable), including the right to sue and recover for past, present and future violations thereof.
                        </p>
                        <Row className="justify-content-center">
                            <Col md={11}>
                                <Card>
                                    <Card.Header>WHICH MEANS</Card.Header>
                                    <Card.Body>
                                        All of the information, property and intellectual property you have access to as a result of your participation in the Partner Program belongs entirely to 11Klear.
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <br />

                        <h5>5.5. 11Klear’s Use of Partner’s Intellectual Property</h5>
                        <p>
                            Partner grants to 11Klear a worldwide, non-exclusive, royalty-free, transferable and sub-licensable right and licence to use and display in any manner the Partner's trademarks, service marks, logos, trade names, copyrighted content, graphic files, images and other intellectual property, in connection with and for the purpose of performing its obligations and exercising its rights under this Agreement. In addition, at 11Klear's discretion, 11Klear may use any such intellectual property for the purposes of promoting or marketing Partner, Partner's products or services, or as otherwise agreed to with Partner.
                        </p>

                        <h4>6. Confidentiality</h4>
                        <p>
                            1.	“Confidential Information” will include, but will not be limited to, any and all information associated with a Party’s business and not publicly known, including specific business information, technical processes and formulas, software, customer lists, prospective customer lists, names, addresses and other information regarding customers and prospective customers, product designs, sales, costs (including any relevant processing fees), price lists, and other unpublished financial information, business plans and marketing data, and any other confidential and proprietary information, whether or not marked as confidential or proprietary. For the avoidance of doubt, as between 11Klear and Partner, Customer Data is the Confidential Information of 11Klear.<br />
                            2.	Each Party agrees to use the other Party’s/ies’ Confidential Information solely as necessary for performing its obligations under this Agreement and in accordance with any other obligations in this Agreement including this Section 6. Each Party agrees that it will take all reasonable steps, at least substantially equivalent to the steps it takes to protect its own proprietary information, to prevent the duplication, disclosure or use of any such Confidential Information, other than (i) by or to its employees, agents and subcontractors who must have access to such Confidential Information to perform such Party’s obligations under this Agreement, who each will treat such Confidential Information as provided in this Agreement, and who are each subject to obligations of confidentiality to such Party that are at least as stringent as those contained in this Agreement; or (ii) as required by any law, regulation, or order of any court of proper jurisdiction over the Parties and the subject matter contained in this Agreement, provided that, if legally permitted, the receiving Party will give the disclosing Party prompt written notice and use commercially reasonable efforts to ensure that such disclosure is accorded confidential treatment. Confidential Information will not include any information that the receiving Party can prove: (A) was already in the public domain, or was already known by or in the possession of the receiving Party, at the time of disclosure of such information; (B) is independently developed by the receiving Party without use of or reference to the other Party’s/ies’ Confidential Information, and without breaching any provisions of this Agreement; or (C) is thereafter rightly obtained by the receiving Party from a source other than the disclosing Party without breaching any provision of this Agreement.
                        </p>
                        <Row className="justify-content-center">
                            <Col md={11}>
                                <Card>
                                    <Card.Header>WHICH MEANS</Card.Header>
                                    <Card.Body>
                                        Both 11Klear and Partner agree to use Confidential Information only to perform the obligations of the Agreement. Confidential Information must be protected and respected. Customer Data is 11Klear’s Confidential Information.
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <br />

                        <h4>7. Disclaimer of warranty</h4>
                        <p>
                            The 11Klear Partner Program, the Service, the 11Klear Trademarks, the 11Klear Creative, Ads (including delivery and related reporting) and the 11Klear API are provided “as-is”. 11Klear makes no warranties under this Agreement, and 11Klear expressly disclaims all warranties, express or implied, including, but not limited to, warranties of merchantability, non-infringement or fitness for a particular purpose. Without limiting the foregoing, 11Klear further disclaims all representations and warranties, express or implied, that the Service, the 11Klear API, the 11Klear Trademarks, the 11Klear Creative, or the 11Klear API satisfy all of Partner’s or Customer’s requirements and or will be uninterrupted, error-free or free from harmful components.
                        </p>
                        <Row className="justify-content-center">
                            <Col md={11}>
                                <Card>
                                    <Card.Header>WHICH MEANS</Card.Header>
                                    <Card.Body>
                                        11Klear makes no warranties about the 11Klear Partner Program, the Experts Program, the Plus Partner Program, the Service, the 11Klear API, the 11Klear Trademarks, Ads, or the 11Klear Creative.
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <br />

                        <h4>8. Limitation of Liability and Indemnification</h4>

                        <h5>8.1. Limitation of Liability</h5>
                        <p>
                            11Klear, and the 11Klear Related Entities, will have no liability with respect to the 11Klear Partner Program, the Service, the 11Klear API, the 11Klear Trademarks, the 11Klear Creative or 11Klear’s obligations under this Agreement or otherwise for any direct, indirect, incidental, special, consequential, or exemplary damages, including but not limited to, damages for losses of profits, goodwill, use, data or other intangible losses resulting in any way from the Service, the 11Klear API, the 11Klear Trademarks, the 11Klear Creative, or Partner’s participation or inability to participate in the 11Klear Partner Program, even if 11Klear has been advised of the possibility of such damages. In any event, 11Klear’s, and the 11Klear Related Entities’, liability to Partner under this Agreement for any reason will be limited to the Fees paid to Partner by 11Klear during the six (6) month period immediately preceding the event giving rise to the claim for damages. This limitation applies to all causes of action in the aggregate, including, but not limited to, breach of contract, breach of warranty, negligence, strict liability, misrepresentations, and other torts. The relationship between a Customer and a Partner is strictly between the Customer and the Partner, and neither 11Klear or the 11Klear Related Entities are obligated to intervene in any dispute arising between the Customer and the Partner. Under no circumstances will 11Klear, or the 11Klear Related Entities, be liable for any direct, indirect, incidental, special, consequential, punitive, extraordinary, exemplary or other damages whatsoever, that result from or relate to the Partner’s relationship with any Customer. These limitations will apply even if 11Klear or the 11Klear Related Entities have been advised of the possibility of such damages. The foregoing limitations will apply to the fullest extent permitted by applicable law.
                        </p>
                        <Row className="justify-content-center">
                            <Col md={11}>
                                <Card>
                                    <Card.Header>WHICH MEANS</Card.Header>
                                    <Card.Body>
                                        11Klear is limiting its liability with regard to the 11Klear Partner Program, the Experts Program, the Plus Partner Program, the Service, the 11Klear API, the 11Klear Trademarks, the 11Klear Creative, the relationship between the Partner and any Customer, and 11Klear’s obligations under the Agreement. If there is a liability owed to the Partner by 11Klear, 11Klear will provide no more than the Fees that were paid to Partner in the past six months.
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <br />

                        <h5>8.2. Partner Indemnification</h5>
                        <p>
                            Partner agrees to indemnify, defend and hold harmless 11Klear and any 11Klear Related Entities and the directors, officers, employees, subcontractors and agents thereof (each, an “<b>Indemnified Party</b>”, and collectively, the “<b>Indemnified Parties</b>”), with respect to any claim, demand, cause of action, debt or liability, including reasonable attorneys’ fees (collectively, “<b>claims</b>”), to the extent that such claim is based upon or arises out of: (a) Partner’s breach of any representation, warranty, obligation or covenant under this Agreement; (b) Partner’s gross negligence or wilful misconduct; (c) any warranty, condition, representation, indemnity or guarantee relating to 11Klear and 11Klear Related Entities granted by Partner to any Customer, prospective Partner or other third party; (d) Partner’s use of the 11Klear API; (e) Partner’s breach of any term of this Agreement (including any documents it incorporates by reference) or a Customer Agreement; (f) any third party claim that Partner’s products or services, including without limitation any Application, Theme, or Channel Platform infringes the intellectual property or other rights of a third party; (g) Partner Taxes, including any audits or penalties related thereto; (h) the performance, non-performance or improper performance of the Partner’s products or services, including without limitation, any Application or Theme or Channel Platform; (i) Partner’s relationship with any Customer; and (j) any breach of applicable law by the Partner.
                        </p>
                        <Row className="justify-content-center">
                            <Col md={11}>
                                <Card>
                                    <Card.Header>WHICH MEANS</Card.Header>
                                    <Card.Body>
                                        Partner will cover all costs associated with a claim made against, or liabilities incurred by, 11Klear if it is caused by Partner’s breach of this Agreement (including the <a href="/policy/restrictions">Acceptable Use Policy</a>), Partner’s gross negligence or wilful misconduct, promises made by Partner to third parties about 11Klear, Partner’s use of the 11Klear API, Partner’s products or services, Partner’s infringement or violation of the intellectual property or other rights of a third party, Partner’s violation of applicable law, or Partner’s relationship with a Customer.
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <br />

                        <h5>8.3. Notice of Indemnification</h5>
                        <p>
                            In claiming any indemnification under this Agreement, the Indemnified Party will promptly provide Partner with written notice of any claim which the Indemnified Party believes falls within the scope of the indemnifications provided under this Agreement. The Indemnified Party may, at its own expense, assist in the defense if it so chooses, provided that Partner will control such defense and all negotiations relative to the settlement of any such claim and further provided that in settling any claim the Partner will not make any admission on behalf of the Indemnified Party or agree to any terms or conditions that do or reasonably could result in any admission by, or the imposition of any liability upon, the Indemnified Party without the prior written approval of the Indemnified Party.
                        </p>
                        <Row className="justify-content-center">
                            <Col md={11}>
                                <Card>
                                    <Card.Header>WHICH MEANS</Card.Header>
                                    <Card.Body>
                                        11Klear will notify Partner of any claims and may choose to assist in the defense.
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <br />

                        <h5>8.4. Non-exclusive remedies</h5>
                        <p>
                            In the event of any breach or threatened breach by Partner of any provision of Sections 2, 3.2.4, 5 or 6 above, in addition to all other rights and remedies available to 11Klear under this Agreement and under applicable law, 11Klear will have the right to (a) immediately enjoin all such activity, without the necessity of showing damages or posting bond or other security, (b) immediately terminate this Agreement and Partner’s access to the Partner Program, (c) receive a prompt refund of all amounts paid to Partner under this Agreement, and (d) be indemnified for any losses, damages or liability incurred by 11Klear in connection with such violation, in accordance with the provisions of this Section 8.
                        </p>
                        <Row className="justify-content-center">
                            <Col md={11}>
                                <Card>
                                    <Card.Header>WHICH MEANS</Card.Header>
                                    <Card.Body>
                                        Which means: If Partner breaches its obligations with respect to Partner’s responsibilities (Section 2), 11Klear’s intellectual property or other proprietary rights (Section 5) or confidentiality (Section 6), 11Klear has rights outside of “breach of contract”.
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <br />

                        <h4>9. General provisions</h4>

                        <h5>9.1. Force Majeure</h5>
                        <p>
                            If the performance of any part of this Agreement by either Party is prevented, hindered, delayed or otherwise made impracticable by reason of any flood, riot, fire, judicial or governmental action (including, but not limited to, any law, regulation or embargo prohibiting the performance contemplated under this Agreement or the failure or refusal of a government agency to issue a license required for any performance pursuant to this Agreement), labour disputes, act of God or any cause beyond the reasonable control of that Party, the Party will be excused from such performance to the extent that it is prevented, hindered or delayed by such cause. Notwithstanding anything in this Agreement to the contrary, the Party prevented from performing under this Agreement by a force majeure event will nevertheless use its best efforts to recommence its performance under this Agreement as soon as reasonably practicable and to mitigate any damages resulting from its non-performance under this Agreement.
                        </p>
                        <Row className="justify-content-center">
                            <Col md={11}>
                                <Card>
                                    <Card.Header>WHICH MEANS</Card.Header>
                                    <Card.Body>
                                        In case something serious happens outside the control of either 11Klear or Partner, both will be excused from performance of duties to the extent that such event affects either Party’s ability to perform such duties.
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <br />

                        <h5>9.2. Independent Contractors</h5>
                        <p>
                            The Parties to this Agreement are independent contractors. Except with respect to the collection and transfer of payments, credits or refunds between Customers and Partner, or as otherwise expressly stated in this Agreement, neither 11Klear or any 11Klear Related Entity is an agent, representative or related entity of the Partner. Neither 11Klear nor the Partner will have any right, power or authority to enter into any agreement for, or on behalf of, or incur any obligation or liability of, or otherwise bind, the other Party, except where the Partner expressly authorizes 11Klear to act on its behalf in this Agreement. For the avoidance of doubt, Partner expressly authorizes 11Klear to act on its behalf for the purposes of collecting and remitting payment, credits or refunds between Customer and Partner. This Agreement will not be interpreted or construed to create an association, agency, joint venture or partnership between the Parties or to impose any liability attributable to such a relationship upon either Party.
                        </p>
                        <Row className="justify-content-center">
                            <Col md={11}>
                                <Card>
                                    <Card.Header>WHICH MEANS</Card.Header>
                                    <Card.Body>
                                        Both 11Klear and Partner are independent contractors in respect of each other. There is no relationship of agents, representatives or otherwise between you and 11Klear, except when 11Klear is dealing with Customer payments, credits and refunds on your behalf, or unless we say we are expressly doing something on your behalf in this Agreement.
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <br />

                        <h5>9.3. Non-Exclusivity</h5>
                        <p>
                            Nothing in this Agreement is intended to create, nor will it be construed as creating, any exclusive arrangement between the Parties to this Agreement. This Agreement will not restrict either Party from entering into similar arrangements with others, provided it does not breach its obligations under this Agreement by doing so, including without limitation, any confidentiality obligations.
                        </p>
                        <Row className="justify-content-center">
                            <Col md={11}>
                                <Card>
                                    <Card.Header>WHICH MEANS</Card.Header>
                                    <Card.Body>
                                        Both 11Klear and Partner are non-exclusive to each other and each can enter into similar arrangements with others, as long as that Party does not use the other Party’s confidential information.
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <br />

                        <h5>9.4. Notice</h5>
                        <p>
                            Any notice, approval, request, authorization, direction or other communication under this Agreement will be given in writing and will be deemed to have been delivered and given for all purposes (a) on the delivery date if delivered personally, or by email to Partner’s email address listed in the Partner Account, and to legal@11Klear.com; (b) two (2) business days after deposit with an internationally recognized commercial overnight courier service, with written verification of receipt; or (c) five (5) business days after deposit in certified or registered mail, return receipt requested, postage and charges prepaid. Notice will be sent to the Partner at the address provided in the Partner Account, and to 11Edge at 1000 Innovation Drive, Suite 500, Kanata, Ontario, Canada, K2K 3E7 Attention: Legal Department.
                        </p>
                        <Row className="justify-content-center">
                            <Col md={11}>
                                <Card>
                                    <Card.Header>WHICH MEANS</Card.Header>
                                    <Card.Body>
                                        Notice is considered to be sent on the day it is emailed or hand delivered, 2 days after it is given to a courier, or 5 days after it is placed in the mail.
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <br />

                        <h5>9.5. No Waiver</h5>
                        <p>
                            The failure of any Party to insist upon or enforce strict performance by another Party of any provision of this Agreement or to exercise any right under this Agreement will not be construed as a waiver or relinquishment to any extent of such Party’s right to assert or rely upon any such provision or right in that or any other instance; rather, the same will be and remain in full force and effect. Each waiver will be set forth in a written instrument signed by the waiving Party.
                        </p>
                        <Row className="justify-content-center">
                            <Col md={11}>
                                <Card>
                                    <Card.Header>WHICH MEANS</Card.Header>
                                    <Card.Body>
                                        If a provision is not enforced by any Party, it does not mean that they are waiving their rights to enforce that provision in the future.
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <br />

                        <h5>9.6. Entire Agreement</h5>
                        <p>
                            This Agreement, including any completed application form and all guidelines and other documents linked or otherwise incorporated or referenced in this Agreement, sets forth the entire agreement and supersedes any and all prior agreements, written or oral, of the Parties with respect to the subject matter hereof (including, but not limited to, any prior version of this Agreement). Neither 11Klear nor the Partner will be bound by, and each Party specifically objects to, any term, condition or other provision that is different from or in addition to the provisions of this Agreement (whether or not it would materially alter this Agreement) and which is proffered by another Party in any correspondence or other document, unless the Party to be bound thereby specifically agrees to such provision in writing.<br />
                            This Agreement may be available in languages other than English. To the extent of any inconsistencies or conflicts between this English Agreement and the Agreements available in another language, the most current English version of the Agreement will prevail.
                        </p>
                        <Row className="justify-content-center">
                            <Col md={11}>
                                <Card>
                                    <Card.Header>WHICH MEANS</Card.Header>
                                    <Card.Body>
                                        This Agreement and the documents it links to and references are the entire agreement governing the relationship between 11Klear and Partner under the Partner Program, and it replaces any previous agreements.
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <br />

                        <h5>9.7. Assignment</h5>
                        <p>
                            All the terms and provisions of this Agreement will be binding upon and inure to the benefit of the Parties to this Agreement and to their respective heirs, successors, permitted assigns and legal representatives. 11Klear will be permitted to assign this agreement without notice to or consent from Partner. Partner will have no right to assign or otherwise transfer this Agreement, or any of its rights or obligations under this Agreement, to any third party without 11Klear’s prior written consent, to be given or withheld in 11Klear’s sole discretion.
                        </p>
                        <Row className="justify-content-center">
                            <Col md={11}>
                                <Card>
                                    <Card.Header>WHICH MEANS</Card.Header>
                                    <Card.Body>
                                        Partner cannot assign away or transfer this agreement to another party without 11Klear’s consent.
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <br />

                        <h5>9.8. Applicable Laws</h5>
                        <p>
                            This Agreement will be governed by and interpreted in accordance with the laws of the Province of Ontario and the laws of Canada applicable therein, without regard to principles of conflicts of laws. The United Nations Convention on Contracts for the International Sale of Goods will not apply to this Agreement and is hereby expressly excluded.<br />
                            The parties irrevocably and unconditionally submit to the exclusive jurisdiction of the courts of the Province of Ontario with respect to any dispute or claim arising out of or in connection with this Agreement.

                        </p>
                        <Row className="justify-content-center">
                            <Col md={11}>
                                <Card>
                                    <Card.Header>WHICH MEANS</Card.Header>
                                    <Card.Body>
                                        This agreement is covered by the laws of Ontario, Canada. If anything were to go wrong, Ontario is where the issue would be litigated.
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <br />

                        <h5>9.9. Patent Non-Assertion</h5>
                        <p>
                            Partner and its affiliates covenant not to assert patent infringement claims against 11Klear, 11Klear Related Entities, or 11Klear products and services including the 11Klear API.
                        </p>
                        <Row className="justify-content-center">
                            <Col md={11}>
                                <Card>
                                    <Card.Header>WHICH MEANS</Card.Header>
                                    <Card.Body>
                                        You agree not to sue us for patent infringement.
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <br />

                        <h5>9.10. Competitive or Similar Materials</h5>
                        <p>
                            11Klear is not precluded from discussing, reviewing, developing for itself, having developed, acquiring, licensing, or developing for or by third parties, as well as marketing and distributing materials, products or services which are competitive with Partner’s products or services, including without limitation any Application or Theme, regardless of their similarity to Partner’s products or services, provided that 11Klear does not use Partner’s Confidential Information in so doing.
                        </p>
                        <Row className="justify-content-center">
                            <Col md={11}>
                                <Card>
                                    <Card.Header>WHICH MEANS</Card.Header>
                                    <Card.Body>
                                        Which means: 11Klear may develop products or services that compete with Partner’s products or services so long as 11Klear does not use Partner’s Confidential Information.
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <br />

                        <h5>9.11. Feedback</h5>
                        <p>
                            If Partner provides any feedback (including identifying potential errors and improvements) to 11Klear concerning the Partner Program, the 11Klear API, the 11Klear Creative or any aspects of the Service (“Feedback”), Partner hereby assigns to 11Klear all right, title, and interest in and to the Feedback, and 11Klear is free to use, reproduce, disclose, and otherwise exploit the Feedback without attribution, payment or restriction, including to improve the Partner Program, 11Klear API, the 11Klear Creative or the Service and to create other products and services. 11Klear will treat any Feedback as non-confidential and non-proprietary. Partner will not submit any Feedback that it considers confidential or proprietary.
                        </p>
                        <Row className="justify-content-center">
                            <Col md={11}>
                                <Card>
                                    <Card.Header>WHICH MEANS</Card.Header>
                                    <Card.Body>
                                        If you provide 11Klear with Feedback about the Partner Program, 11Klear API, 11Klear Creative or the Service, we will own this Feedback and we can use it without restriction.
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <br />

                        <h5>9.12. Beta Services</h5>
                        <p>
                            From time to time, 11Klear may, in its sole discretion, invite Partner to use, on a trial basis, potential new services or features that are in development and not yet available to all Partners or Customers (“<b>Beta Services</b>”). Beta Services may be subject to additional terms and conditions, which 11Klear will provide to Partner prior to Partner’s use of the Beta Services. Such Beta Services and all associated conversations and materials relating thereto will be considered Confidential Information of 11Klear and subject to the confidentiality provisions of this Agreement. 11Klear makes no representations or warranties that the Beta Services will function. 11Klear may discontinue the Beta Services at any time in its sole discretion. 11Klear will have no liability for any harm or damage arising out of or in connection with a Beta Service.
                        </p>

                        <h5>9.13. Service Providers</h5>
                        <p>
                            Partner may work with service providers as necessary to facilitate Partner’s performance under this Agreement. Partner acknowledges and agrees that Partner is responsible for all of its service providers’ acts or omissions in relation to Partner’s performance of the Agreement, and any act or omission by Partner’s service provider amounting to a breach of this Agreement will be deemed to be a breach by Partner.
                        </p>
                        <Row className="justify-content-center">
                            <Col md={11}>
                                <Card>
                                    <Card.Header>WHICH MEANS</Card.Header>
                                    <Card.Body>
                                        Partner can work with third parties, but is responsible for any breach of this Agreement by those third parties.
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <br />

                        <h5>9.14. Industry Standards</h5>
                        <p>
                            1.	Partner’s networks, operating system and software of its web servers, routers, databases, and computer systems (collectively, “Partner System”) must be properly configured to Internet industry standards so as to securely operate Partner’s Website, Applications and Themes, as applicable. If Partner does not completely control some aspect of the Partner System, Partner will use all influence that Partner has over the Partner System to do so. Partner must diligently correct any security deficiency and disconnect immediately any known or suspected intrusions or intruder.<br />
                            2.	In addition, if Partner has access to Customer Data, Partner: (i) will only use or store such information for the purpose of providing the Partner’s services to the Customer to whom the Customer Data relates, and will not share, sell, disclose or otherwise provide such information to any third party, except as provided for in this Agreement; (ii) will not communicate with Customers directly or indirectly, provided however that Partner may contact Customers if the information is obtained from another source, such as from the Customers themselves; (iii) will only store such information for as long as reasonably necessary to provide the Partner’s services to the Customer to whom the Customer Data relates; (iv) will use industry standard measures to protect against unauthorized access to, disclosure or use of such information; (v) will comply with all applicable laws and regulations relating to the protection and privacy of personally identifiable information in Partner’s provision of the Partner’s services; and (vi) will notify 11Edge of any actual or suspected breach or compromise of Customer Data (a “Data Breach”) within two (2) business days of becoming aware of such occurrence, by reporting an issue to <a href="https://11klear.freshdesk.com/support/tickets/new">11Klear Partner Support Ticket</a>. Upon learning of the Data Breach, at its own cost, Partner will: (A) promptly remedy the Data Breach to prevent any further loss of Customer Data; (B) investigate the incident; (C) take reasonable actions to mitigate any future anticipated harm to 11Klear, the 11Klear Related Entities, Customers or Customers; and (D) regularly communicate the progress of its investigation to 11Klear and cooperate to provide 11Klear with any additional requested information in a timely manner.
                        </p>
                        <Row className="justify-content-center">
                            <Col md={11}>
                                <Card>
                                    <Card.Header>WHICH MEANS</Card.Header>
                                    <Card.Body>
                                        If Partner has access to Customer Data, Partner will only use it to provide services to the Customer, will not communicate with Customer’s customers unless explicitly allowed, only keep Customer Data for as long as necessary to provide its services to the Customer, use industry standard security measures to protect against security breaches and comply with all laws. If Partner suspects any breach of Customer Data, Partner will notify 11Klear immediately.
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <br />

                        <h5>9.15. Severability</h5>
                        <p>
                            If any provision of this Agreement is, for any reason, held to be invalid, illegal or unenforceable in any respect, then such invalidity, illegality or unenforceability will not affect any other provision of the Agreement, and the Agreement will be construed as if such invalid, illegal or unenforceable provision had never been contained within the Agreement.
                        </p>
                        <Row className="justify-content-center">
                            <Col md={11}>
                                <Card>
                                    <Card.Header>WHICH MEANS</Card.Header>
                                    <Card.Body>
                                        If a provision in this Agreement isn’t enforceable for any reason, the rest of the Agreement will still be enforceable.
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <br />

                        <h3 id="part-b">Part B - Additional Terms Applicable to Referral Partners</h3>
                        <h6>This Part B contains terms applicable to Referral Partners generally.</h6>
                        <br />

                        <h4>1. FTC Guidelines</h4>
                        <p>
                            1.	The Federal Trade Commission in the United States (“FTC”) has guidelines for governing endorsements and testimonials. These rules are aimed at increasing transparency between endorsers and consumers. As a member of the Partner Program with 11Klear, Partner receives compensation for the Referred Customer referrals made to 11Klear. This may establish a “material connection” according to FTC rules, which creates an obligation for Partner to provide disclosure to consumers.<br />
                            2.	Full compliance with these guidelines requires, among other things, that (a) Partner clearly and conspicuously disclose that Partner is being compensated for referring Referred Customers to 11Klear, and (b) Partner not engage in misleading or deceptive advertising. For further information Partner should refer to the statement released by the FTC regarding these guidelines.
                        </p>
                        <Row className="justify-content-center">
                            <Col md={11}>
                                <Card>
                                    <Card.Header>WHICH MEANS</Card.Header>
                                    <Card.Body>
                                        Partner should ensure that it follows all FTC requirements that apply to Partner and its activities as a Referral Partner.
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <br />

                        <h4>2. Revenue Sharing Plans for Referral Partners</h4>
                        <p>
                            1.	The “Reference Plan” is a revenue sharing plan applicable to Partners that introduce 11Klear to a Referred Customer through the Referral Partner’s Affiliate link associated with the Referral Partner’s Partner Dashboard. Under the Reference Plan, a Partner is entitled to twenty (20) percent of certain revenue received from such Referred Customer, with 11Edge being entitled to the remaining percentage. In order to continue receiving revenue share for the Customers referred under the Reference Plan, Partner must carry out at least one Revenue Generating Activity in each consecutive 12-month period. 11Klear reserves the right to revise the revenue share applicable to the Reference Plan, and any ongoing obligations to receive revenue share (including required Revenue Generating Activities) from time to time, at its sole discretion, upon reasonable prior notice to Partner. Such notice will be provided by email, posting a notice on the 11Klear blog, or in the Partner Dashboard.<br />
                            2.	For each Referral Customer, Partner will be entitled to receive Fees from the Reference Plan, unless other provisions are applied for the Partner. Where a Partner introduces 11Klear to a Referred Customer through the Referral Partner’s Affiliate Link, the Partner will be entitled to receive Fees in accordance with the Reference Plan.<br />
                            3.	11Klear reserves the right to (i) demand and receive information from Referral Partners about any Referred Customer, including but not limited to information with respect to traffic sources and methods used to acquire the Referred Customer, and (ii) assess the legitimacy and qualification of such Referred Customer for the purposes of determining whether a Fee is payable for such Referred Customer. 11Klear will not be responsible to pay any Fees for a Referred Customer owned in whole or in part by a Referral Partner or Referral Partner’s employer (whether full-time, part-time, term or any other employment type relationship).
                        </p>

                        <h4>3. Responsibilities</h4>
                        <p>
                            Referral Partners will use their best efforts to (i) promote and market 11Klear, and (ii) identify for 11Klear prospective Referred Customers.
                        </p>

                        <h4>4. Unauthorized and Prohibited Activities</h4>
                        <p>
                            1.	A Partner will not:<br />
                            &nbsp;&nbsp;&nbsp;&nbsp;1.	promote or advertise 11Klear or 11Klear Related Entities on coupon, deal, or discount sites or on GTP/Get Paid to Click or other incentivized sites, or display any offer code on a public-facing page or through the use of a “click to display” offer code;<br />
                            &nbsp;&nbsp;&nbsp;&nbsp;2.	use its Affiliate Link directly in any pay-per-click advertising;<br />
                            &nbsp;&nbsp;&nbsp;&nbsp;3.	purchase search engine or other pay-per-click keywords (such as Google Ads), trademarks or domain names that use the 11Klear Trademarks or the names or trademarks of any 11Klear Related Entities or any variations or misspellings thereof that may be deceptively or confusingly similar to the 11Klear Trademarks or the names or trademarks of any 11Klear Related Entity;<br />
                            &nbsp;&nbsp;&nbsp;&nbsp;4.	create or participate in any third party networks or sub-affiliate networks without the express written permission of 11Klear;<br />
                            &nbsp;&nbsp;&nbsp;&nbsp;5.	use or encourage any means of delivering fraudulent traffic, including but not limited to use of bots or toolbar traffic, cookie stuffing, or use of false or misleading links;<br />
                            &nbsp;&nbsp;&nbsp;&nbsp;6.	use direct linking to any page on any 11Klear website, without prior written permission from 11Klear; or<br />
                            &nbsp;&nbsp;&nbsp;&nbsp;7.	mask its referral sites or use deceptive redirecting links.<br />
                            2.	A Referred Customer cannot be an account opened or owned by a Partner and for which that Partner seeks compensation pursuant to this Agreement. A Partner will be deemed to have introduced only those Referred Customers that: (a) access 11Klear from that Partner’s Affiliate Link assigned by 11Klear; or (b) are added through 11Klear’s discretionary process to the Partner’s 11Klear Partner Dashboard.
                        </p>
                        <Row className="justify-content-center">
                            <Col md={11}>
                                <Card>
                                    <Card.Header>WHICH MEANS</Card.Header>
                                    <Card.Body>
                                        Referral Partners cannot buy search engine advertising, trademarks or domain names that mention or use “11Klear” or other 11Klear logos and cannot engage in any fraudulent, spam or low quality marketing activities.
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <br />

                        <h4>5. Relationship between VAP and Customer</h4>
                        <p>
                            The services provided by a VAP to a Customer are the responsibility of the VAP and not of 11Klear. It is the VAP’s responsibility to ensure that there is a Customer Agreement in place between the VAP and Customer for the services to be provided by the VAP to the Customer, and that the Customer Agreement includes appropriate remedies in the event of non-payment by Customer for services rendered by the VAP, provided that any such remedy that includes the withholding of the transfer of ownership of the Customer Account to the Customer will be enforced by 11Klear at its sole discretion. In the event of a dispute between the VAP and the Customer, 11Klear is not obligated to intervene in any such dispute. Under no circumstances will 11Klear be liable for any direct, indirect, incidental, special, consequential, punitive, extraordinary, exemplary or other damages whatsoever, that result from or relate to the transfer of ownership of the Customer Account to the Customer by 11Klear, or the VAP’s relationship with any Customer. These limitations will apply even if 11Klear has been advised of the possibility of such damages. The foregoing limitations will apply to the fullest extent permitted by applicable law.
                        </p>

                        <h3 id="part-c">Part C – Additional Terms Applicable to 11Klear Experts Program</h3>
                        <h6>In addition to the terms applicable to all Referral Partners, as set out in Part B, the following terms apply to 11Klear Experts, and Partners accepted into the 11Klear Experts Program.</h6>
                        <br />

                        <h4>1. 11Klear Experts</h4>
                        <p>
                            <b>Designation</b>. From time to time 11Klear may designate certain of its Partners as “<b>Experts</b>”, to be enrolled in the Experts Program and provided access to Premium Plan. Partners who meet the criteria may apply to become a 11Klear Expert at any time, however the granting of an Expert designation is entirely within 11Klear’s discretion.<br />
                            <b>Guidelines</b>. Experts must follow all 11Klear guidelines set out here, as well as <a href="https://11klear.freshdesk.com/a/solutions/articles/80000956796">Experts Guidelines</a> to continue to access the Experts resources.
                        </p>

                        <h4>2. Additional Revenue Sharing Plans for Experts</h4>
                        <p>
                            1.	The “<b>Premium Plan</b>” is a plan that pays Partner twenty (20) percent (“Premium”) of certain revenue received from such Referred Customer, with 11Edge being entitled to the remaining percentage, outlined in the Reference Plan when the Partner introduces 11Klear to a Referred Customer using the Partner Affiliate Link. Unless otherwise agreed to in writing by 11Klear, under the Premium Plan, when a Referred Customer is referred by a Partner to a 11Klear plan, Partner will receive monthly Premiums. In order to continue receiving revenue share for the Customers referred under the Premium Plan, Partner must carry out at least one Revenue Generating Activity in each consecutive 12-month period. 11Klear reserves the right to revise the revenue share applicable to the Premium Plan, and any ongoing obligations to receive revenue share (including required Revenue Generating Activities) from time to time, at its sole discretion, upon reasonable prior notice to Partner. Such notice will be provided by email, posting a notice on the 11Klear blog, or in the Partner Dashboard.<br />
                            2.	For each Referral Customer, Partner will be entitled to receive Fees from both the Reference Plan and the Premium Plan while the Partner maintains the Expert designation. Whether the Partner receives Fees in respect of a Referred Customer under either the Reference Plan or the Premium Plan is at 11Klear’s sole and absolute discretion.
                        </p>

                        <h4>3. Limited Special Privilege for Non-Experts</h4>
                        <p>
                            1.	If the sum of revenues received from newly Referred Customers in the immediately preceding 12-month period is more than one million dollars USD (USD $1,000,000.00), the Referral Partner is designated as “<b>Experts</b>” without a separate enrollment on the first day of the following calendar month for the 12-month period (“Boost Period”).
                            2.	During the Boost Period, the Referral Partner is entitled to receive the Premium Plan fees for the active revenues from all its Referred Customers.<br />
                            3.	The temporary designation expires after the Boost Period, and the Referral Partner will be required to qualify for the automatic designation upgrade at that time to keep the privilege.                        </p>

                        <h4>4. Removal of Designation</h4>
                        <p>
                            11Klear may remove the Expert designation, as applicable, at any time and for any reason, in its sole and absolute discretion, effective immediately upon notice to the relevant Expert. Upon notice of the revocation of such designation by 11Klear, 11Klear will remove Partner from the Experts Program resources, and Partner will immediately cease referring to itself as a 11Klear Expert (as applicable) on any Website or otherwise.</p>

                        <Row className="justify-content-center">
                            <Col md={11}>
                                <Card>
                                    <Card.Header>WHICH MEANS</Card.Header>
                                    <Card.Body>
                                        11Klear can designate Partner as an Expert eligible to be enrolled in the Expert program. These designations are at 11Klear’s discretion, and can be removed at any time and for any reason. If 11Klear removes the applicable designation, Partner must immediately stop referring to itself as a “11Klear Expert”. Any Referral Partners will enjoy the Experts’ privilege for a limited time without enrollment if qualified.
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <br />
                    </div>
                </Container>
            );
        } else if (name === 'consentRequired') {
            return (
                <Container fluid>
                    <div style={this.divStyle}>
                        <h2>Limited Access: Program Agreement</h2>
                        <i>To access the 11Klear Partner Program, please consent the agreement in your profile.</i><br />
                        <br />
                        <p>Continue to <a href="/account/profile/partners">My Account Profile</a> page</p>
                    </div>
                </Container>
            )
        } else {
            return (
                <Container fluid>

                    <br />
                    <Row className="justify-content-center align-items-center">
                        <Col md={10} align="center">
                            <JoinButton />
                        </Col>
                    </Row>
                    <br />
                    <br />

                    {/* <Row className="justify-content-center align-items-center">
                        <Col md={10} align="center">
                            <br />
                            <h1 style={{ color: colorPens.primary, fontWeight: 'bold' }}>
                                <img alt="11Klear" src={process.env.PUBLIC_URL + '/images/11Klear-Logo-192.png'} style={{ height: 64 }} /> 
                                11Klear® Partner Program</h1>
                        </Col>
                    </Row>
                    <br /><br /> */}

                    <Row className="justify-content-center align-items-center">
                        <Col md={10} align="center"><h3 style={{ color: colorPens.primary, fontWeight: 'bold' }}>ONE GREAT SOLUTION - MANY USE CASES</h3></Col>
                    </Row>
                    <br /><br />
                    <Row className="justify-content-center align-items-center">
                        {/* <Col align="center" md={1}><Image src={process.env.PUBLIC_URL + '/images/details/icon_greenPackage.svg'} style={{ width: 100 }} fluid /></Col> */}
                        <Col md={2} align="center">
                            <Image src={process.env.PUBLIC_URL + '/images/partners/online-sales.png'} style={{ width: '200px' }} fluid />
                            Online Sales
                        </Col>
                        <Col md={2} align="center">
                            <Image src={process.env.PUBLIC_URL + '/images/partners/takeout-delivery.png'} style={{ width: '200px' }} fluid />
                            Take Out Delivery
                        </Col>
                        <Col md={2} align="center">
                            <Image src={process.env.PUBLIC_URL + '/images/partners/grocery-delivery.png'} style={{ width: '200px' }} fluid />
                            Grocery Delivery
                        </Col>
                        <Col md={2} align="center">
                            <Image src={process.env.PUBLIC_URL + '/images/partners/legaldocument-delivery.png'} style={{ width: '200px' }} fluid />
                            Legal Document Delivery
                        </Col>
                    </Row>
                    <br />
                    <br />
                    <br />

                    {/* <Row className="justify-content-center align-items-center">
                        <Col md={10} align="center">
                            <h2 style={{ color: colorPens.primary, fontWeight: 'bold' }}>High Pay Out + Big Market = <Image src={process.env.PUBLIC_URL + '/images/partners/good-idea.png'} style={{ height: '240px', marginLeft: -90 }} fluid /></h2><br />
                            <h4>Solve many typical delivery problems!</h4>
                            <h3>Boost your earnings, 20-40% payout</h3>
                        </Col>
                    </Row>
                    <br /> */}

<Row className="justify-content-center align-items-center">
                        <Col md={10} align="center"><h3 style={{ color: colorPens.primary, fontWeight: 'bold' }}>HOW IT WORKS</h3></Col>
                    </Row>
                    <br />
                    <Row className="justify-content-center align-items-center">
                        {/* <Col align="center" md={1}><Image src={process.env.PUBLIC_URL + '/images/details/icon_greenPackage.svg'} style={{ width: 100 }} fluid /></Col> */}
                        <Col md={3} align="center">
                            <Image src={process.env.PUBLIC_URL + '/images/partners/works-register.png'} style={{ height: '150px' }} fluid />
                            <br /><br />
                            <h4 style={{ color: colorPens.primary, fontWeight: 'bold' }}>REGISTER</h4>
                            Sign-up in few easy steps!
                            Registering gives you a personal an 11Klear
                            link to share
                        </Col>
                        <Col md={3} align="center">
                            <Image src={process.env.PUBLIC_URL + '/images/partners/works-promote.png'} style={{ height: '150px' }} fluid />
                            <br /><br />
                            <h4 style={{ color: colorPens.primary, fontWeight: 'bold' }}>PROMOTE</h4>
                            Ask your favorites local/online stores about delivery confirmation and proof of delivery.
                            Let them sign up using your 11Klear link.
                        </Col>
                        <Col md={3} align="center">
                            <Image src={process.env.PUBLIC_URL + '/images/partners/works-earn.png'} style={{ height: '150px' }} fluid />
                            <br /><br />
                            <h4 style={{ color: colorPens.primary, fontWeight: 'bold' }}>REWARD</h4>
                            Earn 20-40% commission on 11Klear accounts opened with your link.
                            Online commerce is big business; your stake will grow fast.

                        </Col>
                    </Row>
                    <br />
                    <br />
                    <br />

                    {/* <Row className="justify-content-center align-items-center">
                        <Col>&nbsp;</Col>
                        <Col align="center" md={1}><Image src={process.env.PUBLIC_URL + '/images/details/icon_greenPackage.svg'} style={{ width: 100 }} fluid /></Col>
                        <Col md={2}>
                            <Image src={process.env.PUBLIC_URL + '/images/partners/puzzles.png'} fluid />
                        </Col>
                        <Col md={8} align="left">
                            <Row>
                                <Col>
                                    <h4><b>About                                 <img alt="11Klear" src={process.env.PUBLIC_URL + '/images/11Klear-Logo-192.png'} style={{ height: 64 }} /> 
 Partner Program</b></h4>
                                </Col>
                            </Row>
                            <Row>
                                <Col><CheckIcon /> The 11Klear Partnership Program is for individuals and organizations that understand the importance of a good customer experience.</Col>
                            </Row>
                            <Row>
                                <Col><CheckIcon /> A poor delivery experience is the No 1 reason a customer will not return!</Col>
                            </Row>
                        </Col>
                        <Col>&nbsp;</Col>
                    </Row>
                    <br /><br /> */}

                    <Row className="justify-content-center align-items-center">
                        <Col>&nbsp;</Col>
                        {/* <Col align="center" md={1}><Image src={process.env.PUBLIC_URL + '/images/details/icon_greenPackage.svg'} style={{ width: 100 }} fluid /></Col> */}
                        <Col md={2}>
                            <Image src={process.env.PUBLIC_URL + '/images/partners/solution.png'} fluid />
                        </Col>
                        <Col md={8} align="left">
                            <Row>
                                <Col>
                                    <h4><b>Solve Many Typical Delivery Problems </b></h4>
                                </Col>
                            </Row>
                            <Row>
                                {/* <Col md={1}></Col> */}
                                <Col><CheckIcon /> 11Klear is an easy-to-use and affordable solution that makes sense for just about any delivery.</Col>
                            </Row>
                            <Row>
                                {/* <Col md={1}></Col> */}
                                <Col><CheckIcon /> 11Klear automates delivery communications and makes it easy for merchants to provide a personalized and branded customer experience.</Col>
                            </Row>
                        </Col>
                        <Col>&nbsp;</Col>
                    </Row>
                    <br />

                    <Row className="justify-content-center align-items-center">
                        <Col>&nbsp;</Col>
                        {/* <Col align="center" md={1}><Image src={process.env.PUBLIC_URL + '/images/details/icon_greenPackage.svg'} style={{ width: 100 }} fluid /></Col> */}
                        <Col md={2}>
                            <Image src={process.env.PUBLIC_URL + '/images/partners/opportunity-ahead.png'} fluid />
                        </Col>
                        <Col md={8} align="left">
                            <Row>
                                <Col>
                                    <h4><b>The opportunity is now!</b></h4>
                                </Col>
                            </Row>
                            <Row>
                                {/* <Col md={1}></Col> */}
                                <Col><CheckIcon /> There are no upfront costs, and getting started as an 11Klear partner is straightforward.</Col>
                            </Row>
                            <Row>
                                {/* <Col md={1}></Col> */}
                                <Col><CheckIcon /> Make extra income on the side, or grow your 11Klear revenue into something bigger.</Col>
                            </Row>
                            {/* <br />
                            <Row>
                                <Col align="center">
                                    <h3 style={{ color: colorPens.primary, fontWeight: 'bold' }}>The choice is yours!</h3>
                                </Col>
                            </Row> */}
                        </Col>
                        <Col>&nbsp;</Col>
                    </Row>
                    <br /><br />
                    

                    <Row className="justify-content-center align-items-center">
                    <Col md={8} align="center">
                                    <h3 style={{ color: colorPens.primary, fontWeight: 'bold' }}>Two great Partner programs - The choice is yours</h3>
                                </Col>

                        </Row>
                        <br />
                        <br />

                    <Row className="justify-content-center align-items-center">
                        <Col>&nbsp;</Col>
                        {/* <Col align="center" md={1}><Image src={process.env.PUBLIC_URL + '/images/details/icon_greenPackage.svg'} style={{ width: 100 }} fluid /></Col> */}
                        <Col md={2}>
                            <Image src={process.env.PUBLIC_URL + '/images/partners/referral-program.png'} fluid />
                        </Col>
                        <Col md={8} align="left">
                            <Row>
                                <Col>
                                    <h4><b>11Klear Referral Partner</b></h4>
                                </Col>
                            </Row>
                            <Row>
                                {/* <Col md={1}></Col> */}
                                <Col><CheckIcon /> Use your creativity and skills to promote 11Klear with your own <span style={{ color: colorPens.primary, fontWeight: 'bold' }}>Referral Partner</span> Affiliate (RPA) link.</Col>
                            </Row>
                            <Row>
                                {/* <Col md={1}></Col> */}
                                <Col><CheckIcon /> Earn 20% of revenue generated with your RPA link.</Col>
                            </Row>
                        </Col>
                        <Col>&nbsp;</Col>
                    </Row>
                    <br /><br />

                    <Row className="justify-content-center align-items-center">
                        <Col>&nbsp;</Col>
                        {/* <Col align="center" md={1}><Image src={process.env.PUBLIC_URL + '/images/details/icon_greenPackage.svg'} style={{ width: 100 }} fluid /></Col> */}
                        <Col md={2}>
                            <Image src={process.env.PUBLIC_URL + '/images/partners/expert.png'} fluid />
                        </Col>
                        <Col md={8} align="left">
                            <Row>
                                <Col>
                                    <h4><b>11Klear Expert Partner</b></h4>
                                </Col>
                            </Row>
                            <Row>
                                {/* <Col md={1}></Col> */}
                                <Col><CheckIcon /> Think bigger, apply or grow into an <span style={{ color: colorPens.primary, fontWeight: 'bold' }}>11Klear Expert partner</span>.</Col>
                            </Row>
                            <Row>
                                {/* <Col md={1}></Col> */}
                                <Col><CheckIcon />  Experts partners earn 40% of revenue generated with their RPA link.</Col>
                            </Row>
                        </Col>
                        <Col>&nbsp;</Col>
                    </Row>
                    <br /><br />

                    <br />


                    <Row className="justify-content-center align-items-center">
                    <Col md={5} align="center">
                            <Image src={process.env.PUBLIC_URL + '/images/home/evolution_300.PNG'} style={{ height: '350px' }} fluid />
                        </Col>
                        <Col md={5} align="center">
                            <h2 style={{ color: colorPens.primary, fontWeight: 'bold' }}>JOIN THE EVOLUTION</h2><br />
                            <JoinButton />
                        </Col>
                    </Row>
                    <br />

                    {/* <Row className="justify-content-center align-items-center">
                        <Col md={10} align="center">
                            <JoinButton />
                        </Col>
                    </Row>
                    <br /><br /><br /> */}
                    
                </Container>
            )
        }
    }
}

Partner.contextType = UserContext;
export default Partner;