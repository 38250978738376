import React, { Component } from "react";
import { Container, Row, Col, Image } from 'react-bootstrap';
import CheckIcon from '@material-ui/icons/Check';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';import { Button } from '@material-ui/core';
import DeveloperModeIcon from '@material-ui/icons/DeveloperMode';
import DeveloperBoardIcon from '@material-ui/icons/DeveloperBoard';
import MoneyOffIcon from '@material-ui/icons/MoneyOff';
import BusinessIcon from '@material-ui/icons/Business';
import BusinessOutlinedIcon from '@material-ui/icons/BusinessOutlined';
import MoneyOffOutlinedIcon from '@material-ui/icons/MoneyOffOutlined';
import DeveloperBoardOutlinedIcon from '@material-ui/icons/DeveloperBoardOutlined';

import ContactMailOutlinedIcon from '@material-ui/icons/ContactMailOutlined';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import MouseOutlinedIcon from '@material-ui/icons/MouseOutlined';
import CodeOutlinedIcon from '@material-ui/icons/CodeOutlined';
import ArrowRightOutlinedIcon from '@material-ui/icons/ArrowRightOutlined';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';

import { colorPens } from './utils/colors';
import TryNow from './components/tryNow';

class Whitepaper extends Component {
  render() {
    return (
      <Container fluid>
        <p></p>
        <Row className="justify-content-center">
          <Col md={2} align="center">
            <CheckIcon style={{ color: colorPens.success, fontSize: 55 }} />
          </Col>
          <Col md={8}>
            <h5><b>The value of the delivery notification add-on</b></h5>
            <ArrowRightOutlinedIcon /> Online shoppers want to know when their orders are coming.<br />
          </Col>
        </Row>
        <br />
        <Row className="justify-content-center">
          <Col md={2} align="center">
            <LibraryBooksIcon style={{ color: colorPens.dark, fontSize: 55 }} />
          </Col>
          <Col md={8}>
            <h5><b>Contactless delivery confirmation is on the rise</b></h5>
            <ArrowRightOutlinedIcon /> Customers prefer not to have in-person signature requirement deliveries.<br />
          </Col>
        </Row>
        <br />
        {/* <br />
        <Row className="justify-content-center">
          <Col md={2} align="center">
            <Image src={process.env.PUBLIC_URL+'/images/details/icon_faq_pie.svg'} style={{ width: 100 }} fluid />
          </Col>
          <Col md={8}>
            <h4><b>E-merchants and their customers need and want better contactless solutions!</b></h4>
            <CheckBoxOutlineBlankIcon /> 70% of consumers want a contactless digital alternative to proof-of-delivery signatures for their higher-value packages<br />
            <CheckBoxOutlineBlankIcon /> 49% of adults are uncomfortable with personal interaction even after Covid vaccination <br />
            <br />
            <h5>Get the tools you need to provide excellent contactless delivery service.</h5>
          </Col>
        </Row> */}
        <br />
        <TryNow />
          <br />
      </Container>
    );
  }
}
 
export default Whitepaper;