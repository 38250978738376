import React, { Component } from "react";
// import banner from './Special_Delivery__Bird_preview.jpg';
import { Container, Row, Col, Image } from 'react-bootstrap';
// import birdImage from './images/Special_Delivery__Bird_preview.jpg';
import CheckIcon from '@material-ui/icons/Check';
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// import { Button } from '@material-ui/core';
// import * as Scroll from 'react-scroll';
import { Events, scrollSpy } from 'react-scroll';
// import ChevronRightIcon from '@material-ui/icons/ChevronRight';
// import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
// import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';

import { colorPens } from './utils/colors';
import TryNow from './components/tryNow';

class Products extends Component {
  state = {
    readMore: false
  };

  componentDidMount() {
    Events.scrollEvent.register('begin', function (to, element) {
      console.log('begin', arguments);
    });

    Events.scrollEvent.register('end', function (to, element) {
      console.log('end', arguments);
    });

    scrollSpy.update();
  }

  componentWillUnmount() {
    Events.scrollEvent.remove('begin');
    Events.scrollEvent.remove('end');
  }

  handleSetActive(to) {
    console.log(`handleSetActive ${to}`);
  }

  render() {
    return (
      <Container fluid>
        <p></p>
        {/* <Row className="justify-content-center">
          <Col md={6} align="center">
            <font color="#228B22">
              <h4><b><i>Contactless + Fast + Affordable</i></b></h4>
            </font></Col>
        </Row> */}
        <Row className="justify-content-center">
          <Col>
            <h2 align="center"><b>11Klear Tags, setting a new standard</b></h2>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col>
            <h5 align="center">
              <i>Depending on what you need to know, there is a tag for that!</i>
            </h5>
          </Col>
        </Row>
        <br /><br />
        <Row className="justify-content-center align-items-center">
          <Col>&nbsp;</Col>
          <Col md={10}>
            <Row>
              <Col style={{ color: colorPens.primary }}>
                <Row>
                  <Col align="center">
                    <h4><b>RT Tags</b></h4>
                    <b>Real-time Delivery Alert<br />
                    Secure Content Verification</b>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col align="center"><Image src={process.env.PUBLIC_URL + '/images/details/icon_rt_tag.svg'} style={{ height: 100 }} fluid /></Col>
                </Row>
                <Row>
                  <Col align="center">
                    <br />TELLS YOU:<br />
                    <CheckIcon /> WHAT was delivered?<br />
                    <CheckIcon /> WHERE was it delivered?<br />
                    <CheckIcon /> WHEN was it delivered?<br />
                  </Col>
                </Row>
              </Col>
              <Col style={{ color: colorPens.success }}>
              <Row>
                  <Col align="center">
                    <h4><b>RTV Tags</b></h4>
                    <b>Contactless Recipient Verification<br />
                    Digital Signature-equivalent<br />
                    Proof-of-Delivery</b>
                  </Col>
                </Row>
                <Row>
                  <Col align="center"><Image src={process.env.PUBLIC_URL + '/images/details/icon_rtv_tag.svg'} style={{ height: 100 }} fluid /></Col>
                </Row>
                <Row>
                  <Col align="center">
                    TELLS YOU:<br />
                    <CheckIcon /> WHAT was delivered?<br />
                    <CheckIcon /> WHERE was it delivered?<br />
                    <CheckIcon /> WHEN was it delivered?<br />
                    <CheckIcon /> WHO received/accepted the delivery?<br />
                  </Col>
                </Row>
              </Col>
            </Row>
            <br />
          </Col>
          <Col></Col>
        </Row>
        <br />
        <TryNow />
        <br />
        {/* <Row>
          <Col><Image src={process.env.PUBLIC_URL+'/images/banner_RTV_advantages.png'} fluid /></Col>
        </Row> */}
      </Container>
    );
  }
}

export default Products;