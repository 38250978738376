import React, { useState } from "react"
import { Formik } from "formik"
import * as yup from "yup"
import { Form, Button, Col, Row } from "react-bootstrap"

const schema = yup.object({
    email: yup
        .string()
        .email('Please enter a valid email address')
        .required('Email is required'),
    // refId: yup
    //     .string()
    //     .min(1)
    //     .max(64)
    //     .required('Reference ID cannot be empty'),
    // proximity: yup
    //     .bool(),
    // note: yup
    //     .string()
    //     .min(0)
    //     .max(256),
    terms: yup
        .bool()
        // .oneOf([true], 'Field must be checked')
        .oneOf([true], 'TOU must be consented')
    // .required(),
});

const UpdateDPODForm = (props) => {
    const [proximityRequired, setProximityRequired] = useState(true);

    const handleToggle = () => {
        proximityRequired ? setProximityRequired(false) : setProximityRequired(true);
    }

    return (
        <Formik
            validationSchema={schema}
            onSubmit={(values, actions) => {
                values["nid"] = props.nid;
                // if (e164Valid) {
                props.onSubmit(values);
                // } else {
                //     return false;
                // }
            }} 
            // onSubmit={props.value}
            initialValues={{ email: props.recipient, terms: false }}>
            {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                isValid,
                errors,
            }) => (
                <Form noValidate onSubmit={handleSubmit}>
                    <Form.Row>
                        <Form.Group as={Col} md="8" controlId="validationFormik101">
                            <Form.Label>Recipient's email address</Form.Label>
                            <Form.Control
                                type="text"
                                name="email"
                                value={values.email}
                                onChange={handleChange}
                                isValid={touched.email && !errors.email}
                                isInvalid={touched.email && errors.email}
                            />
                            <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                            {/* <Form.Control.Feedback tooltip>Looks good!</Form.Control.Feedback> */}
                        </Form.Group>
                    </Form.Row>
                    <Form.Group>
                        <Form.Check
                            required
                            name="terms"
                            label="Agree to terms and conditions; I confirm the provided email address is correct."
                            onChange={handleChange}
                            isInvalid={!!errors.terms}
                            feedback={errors.terms}
                            id="validationFormik106"
                            feedbackTooltip
                        />
                    </Form.Group>
                    <Row>
                        <Col>&nbsp;</Col>
                        <Col>&nbsp;</Col>
                        <Col><Button type="submit">Update</Button></Col>
                    </Row>
                </Form>
            )}
        </Formik>
    );
}

export default UpdateDPODForm;