import React, { useState } from "react"
import { Formik } from "formik"
import * as yup from "yup"
import { Form, Button, Col, Row } from "react-bootstrap"

const schema = yup.object({
    amount: yup
        .number('Amount must be in number')
        .required('Amount is required')
        .positive()
        .integer()
        .min(10, 'Amount must be larger than $10')
        .max(10000, 'Maximum amount for the single transaction is $10,000'),
    // refId: yup
    //     .string()
    //     .min(1)
    //     .max(64)
    //     .required('Reference ID cannot be empty'),
    // proximity: yup
    //     .bool(),
    // note: yup
    //     .string()
    //     .min(0)
    //     .max(256),
    // terms: yup
    //     .bool()
    //     // .oneOf([true], 'Field must be checked')
    //     .oneOf([true], 'TOU must be consented')
    // .required(),
});

const PaymentAmountForm = (props) => {
    const [isInteractive, setIsInteractive] = useState(true);
    
    return (
        <Formik
            validationSchema={schema}
            onSubmit={(values, actions) => {
                // values["nid"] = props.nid;
                // if (e164Valid) {
                setIsInteractive(false);
                props.onSubmit(values);
                // } else {
                //     return false;
                // }
            }} 
            // onSubmit={props.value}
            initialValues={{ amount: "" }}>
            {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                isValid,
                errors,
            }) => (
                <Form noValidate onSubmit={handleSubmit}>
                    <Form.Row>
                        <Col>
                            <Form.Label><h4>2. Service credit purchase amount</h4></Form.Label>
                        </Col>
                    </Form.Row>
                    <Form.Row className="align-items-center justify-content-center">
                        <Form.Group as={Col} md="1" align="right">
                            <h5>USD</h5>
                        </Form.Group>
                        <Form.Group as={Col} md="2" align="right" controlId="validationFormik101">
                            <Form.Control
                                type="text"
                                name="amount"
                                value={values.amount}
                                placeholder="The amount"
                                onChange={handleChange}
                                isValid={touched.amount && !errors.amount}
                                isInvalid={touched.amount && errors.amount}
                                disabled={!isInteractive}
                            />
                            <Form.Control.Feedback type="invalid">{errors.amount}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="1" align="left">
                            <Button type="submit" variant="success" size="md" disabled={!isInteractive}>Proceed</Button>
                        </Form.Group>
                    </Form.Row>
                </Form>
            )}
        </Formik>
    );
}

export default PaymentAmountForm;