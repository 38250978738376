import React, { Component } from "react";
import { Container, Row } from 'react-bootstrap';
import IFrame from 'react-iframe';

class Invite extends Component {
  render() {
    return (
      <Container fluid>
        <p></p>
        <Row className="justify-content-center">
          {/* <Col md={11} align="center"> */}
            <IFrame url="https://forms.office.com/Pages/ResponsePage.aspx?id=h9uTHORjgEe_H0tvBYoCSmSI0Y7YTidOppi30J6lrkBUNElMSDFEMUNTWUtMR0RHVFpaRUYzRjBSRSQlQCN0PWcu&embed=true"
              position="relative"
              // width="50%"
              height="2300px"
              // id="inviteForm"
              className="col-md-11"
              frameBorder="0"
              styles={{border: "none", maxWidth:"100%", maxHeight:"2500px"}}
              allowfullscreen
            />

            {/* <iframe width="100%" height= "100%" src= "https://forms.office.com/Pages/ResponsePage.aspx?id=h9uTHORjgEe_H0tvBYoCSmSI0Y7YTidOppi30J6lrkBUNElMSDFEMUNTWUtMR0RHVFpaRUYzRjBSRSQlQCN0PWcu&embed=true" frameborder= "0" marginwidth= "0" marginheight= "0" style= "border: none; max-width:100%; max-height:100vh" allowfullscreen webkitallowfullscreen mozallowfullscreen msallowfullscreen> </iframe> */}
          {/* </Col> */}
        </Row>
          <br />
      </Container>
    );
  }
}
 
export default Invite;