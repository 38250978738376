import { Row, Col, Image, Collapse } from 'react-bootstrap';
import { Button } from '@material-ui/core';
import { colorPens } from '../utils/colors';

const TryNow = (props) => {
    return (
        <Row>
            {/* <Col md={5}>&nbsp;</Col> */}
            <Col align="center">
                <Button
                    disabled={false}
                    style={{
                        fontSize: 18,
                        color: colorPens.main,
                        backgroundColor: colorPens.light
                      }}                      
                      variant="outlined"
                            onClick={() => { 
                        // window.location.href = '/invite';
                        window.location.href = `https://11klear.freshdesk.com/support/tickets/new`;
                    }}
                >REQUEST DEMO</Button>
            </Col>
        </Row>
    )
}

export default TryNow;