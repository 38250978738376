
const appConfig = {
    "region": process.env.REACT_APP_AWS_COGNITO_REGION,
    "userPool": process.env.REACT_APP_AWS_COGNITO_USER_POOL,
    "userPoolBaseUri": process.env.REACT_APP_AWS_COGNITO_USER_POOL_BASE_URI,
    "clientId": process.env.REACT_APP_AWS_COGNITO_CLIENT_ID,
    "callbackUri": process.env.REACT_APP_AWS_COGNITO_CALLBACK_URI,
    "signoutUri": process.env.REACT_APP_AWS_COGNITO_SIGNOUT_URI,
    "tokenScopes": [
        "openid",
        "email",
        "profile",
        "https://api.11klear.com/tag.create",
        "https://api.11klear.com/tag.subscribe",
        "https://api.11klear.com/tag.status",
        "https://api.11klear.com/tag.print"
    ],
    "apiUri": process.env.REACT_APP_AWS_COGNITO_API_URI
}

// https://docs.amplify.aws/lib/auth/start/q/platform/js#re-use-existing-authentication-resource
const getAmplifyConfig = () => {
    return {
        // s3: {
        //     REGION: "YOUR_S3_UPLOADS_BUCKET_REGION",
        //     BUCKET: "YOUR_S3_UPLOADS_BUCKET_NAME",
        // },
        // apiGateway: {
        //     REGION: "YOUR_API_GATEWAY_REGION",
        //     URL: "YOUR_API_GATEWAY_URL",
        // },
        cognito: {
            REGION: process.env.REACT_APP_AWS_COGNITO_REGION,
            USER_POOL_ID: process.env.REACT_APP_AWS_COGNITO_USER_POOL,
            APP_CLIENT_ID: process.env.REACT_APP_AWS_COGNITO_CLIENT_ID,
            // IDENTITY_POOL_ID: "YOUR_IDENTITY_POOL_ID",
        }
    }
}

const getCognitoSignInUri = () => {
    const signinUri = `${appConfig.userPoolBaseUri}/login?response_type=code&client_id=${appConfig.clientId}&redirect_uri=${appConfig.callbackUri}`
    return signinUri
}

const getCognitoSignUpUri = () => {
    const signinUri = `${appConfig.userPoolBaseUri}/signup?response_type=code&client_id=${appConfig.clientId}&redirect_uri=${appConfig.callbackUri}`
    return signinUri
}

const cognitoConfigs = {
    getAmplifyConfig,
    getCognitoSignInUri,
    getCognitoSignUpUri
}

export default cognitoConfigs;