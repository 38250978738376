import React, { Component } from "react";
// import banner from './Special_Delivery__Bird_preview.jpg';
import { Container, Row, Col, Button, Modal, Badge, Alert, Spinner } from 'react-bootstrap';

import DPODCard from './components/DPODCard'
// import UserCard from './components/userCard'
// import { userContext } from './components/userContext'

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
// check this out:
// https://www.freakyjolly.com/react-bootstrap-table-next-example-pagination-filter-sorting-export-to-excel/

// import { Formik, Form, Field, ErrorMessage } from "formik";
// import CreateDPODForm from './components/CreateDPODFormWrapper'
import CreateDPODForm from './components/CreateDPOD'
import UpdateDPODForm from './components/UpdateDPOD'

// const base62 = require('base-62');
import * as DPOD from './components/DPOD.js';
// const base62 = require('base62/lib/ascii');
import { UserContext } from './components/userContext';

import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

var Parse = require('parse');

class Dashboard extends Component {
    constructor(props) {
        super(props);

        this.currentUser = Parse.User.current();
        this.createDPOD = this.createDPOD.bind(this);
        // this.thisCreateDPOD = this.testCreateDPOD.bind(this)

        // this.displayDPoDCard = this.displayDPoDCard.bind(this)

        // this.products = [
        //   { id: 1, name: 'George', animal: 'Monkey' },
        //   { id: 2, name: 'Jeffrey', animal: 'Giraffe' },
        //   { id: 3, name: 'Alice', animal: 'Giraffe' },
        //   { id: 4, name: 'Alice', animal: 'Tiger' }
        // ];
        this.state = {
            reload: false,
            dpods: [],
            rawDPODs: {},
            showCreateDialog: false,
            currentDPoDCard: '',
            currentActionDialog: null,
            balance: 0,
            loadingSpinner: <>
                <Spinner animation="border" variant="secondary" size="sm" role="status">
                    <span className="sr-only">Loading...</span>
                </Spinner> </>
        }

        this.handleCloseCreateDialog = () => { this.setState({ showCreateDialog: false }) };
        this.handleShowCreateDialog = (enoughFund) => {
            if (enoughFund) {
                // When the balance is more than a RTV price, the window will open
                this.setState({ showCreateDialog: true });
            } else {
                alert(`Insufficient balance! Please add more fund to your account first.`);
            }
        };

        this.handleCreateDPODSubmit = (enteredData) => {
            // console.log('enteredData => ' + JSON.stringify(enteredData));

            this.createDPOD(this.currentUser.get("sessionToken"), enteredData.email, enteredData.refId, enteredData.proximity, enteredData.note);
            this.handleCloseCreateDialog();
        };

        this.handleCloseDPODCard = () => { }

        // this.handleCloseCreateDialog = this.this.handleCloseCreateDialog.bind(this);
        // this.handleShowCreateDialog = this.this.handleShowCreateDialog.bind(this);
    }

    async componentDidMount() {
        try {
            await Parse.Session.current();
            this.currentUser = await Parse.User.currentAsync();

            if (this.currentUser.get("consent") === true) {
                this.context.setLoginState(true, this.currentUser.get("consent_partner"));

                await this.listDPOD();
    
                if (this.state.balance === 0) {
                    await this.retrieveBalance();
                }    
            } else {
                this.setState({ reload: true });
            }
        } catch (error) {
            if (error.code === 209) {
                await Parse.User.logOut();
            }
            this.setState({ reload: true });
        }
    }

    createWithBackendAPI = async (data) => {
        let response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/v1/create`, {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json'
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify(data)
        });

        if (!response.ok) {
            throw Error(response);
        } else {
            return await response.json();
        }
    }

    async createDPOD(sessionToken, recipient, refId, proximity, note) {
        let data = {
            it: sessionToken,
            // un: this.currentUser.get("username")
            re: recipient,
            ri: refId,
            pr: proximity,
            nt: note
        }

        // console.log('this.currentUser => '+ JSON.stringify(this.currentUser));
        // console.log('request-body => '+ JSON.stringify(data));
        try {
            await this.createWithBackendAPI(data);
            await this.listDPOD();
            await this.retrieveBalance();
        } catch (error) {
            alert(`Failed to create a new 11Klear tag => ${JSON.stringify(error)}`);
        }
    }

    async handleUpdateDPODSubmit(enteredData) {
        // console.log('enteredData => ' + JSON.stringify(enteredData));

        this.updateDPOD(this.currentUser.get("sessionToken"), enteredData.nid, enteredData.email);
        this.hideUpdateDPoDForm();
    };

    async updateDPOD(sessionToken, nid, recipient) {
        // console.log(` ... updateDPOD (${nid}) is called with a new email address (${recipient})`);

        let data = {
            it: sessionToken,
            di: nid,
            re: recipient,
        }

        try {
            let updateResponse = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/v1/update`, {
                method: 'POST',
                mode: 'cors',
                cache: 'no-cache',
                credentials: 'same-origin',
                headers: {
                    'Content-Type': 'application/json'
                },
                redirect: 'follow',
                referrerPolicy: 'no-referrer',
                body: JSON.stringify(data)
            });

            let updateResult = await updateResponse.json();
            if (!updateResponse.ok) {
                throw Error(updateResult['message']);
            }

            // 11Tag detail is updated!
            this.listDPOD();
        } catch (error) {
            console.log('error caught while updating the requested DPOD with the backend API => ' + JSON.stringify(error));

            alert(`Failed to update the requested 11Tag detail. (${error})`);
        }
    }

    async retrieveBalance() {
        // Update the displayed balance
        const kontostand = Parse.Object.extend("Kontostand");
        let queryBalance = new Parse.Query(kontostand);
        queryBalance.descending("createdAt");
        queryBalance.notEqualTo("balance", null);

        try {
            let balanceResult = await queryBalance.first();
            let balance = balanceResult.get("balance");
            if (balance === undefined) {
                balance = 0;
            }
            this.setState({ balance: balance, loadingSpinner: null });
        } catch (error) {
            console.log('error while retrieveing the balance; '+ error.message);
            this.setState({ balance: "NA" });
        }

        // let queryBalance = new Parse.Query(kontostand);
        // try {
        //     let endBalance = 0;
        //     let results = await queryBalance.find();
        //     for (let i=0; i < results.length; i++) {
        //         endBalance += results[i].get("amount");
        //     }
        //     this.setState({ balance: endBalance });
        // } catch (error) {
        //     console.log('error while retrieveing the balance; '+ JSON.stringify(error));
        //     this.setState({ balance: "NA" })
        // }
    }

    async listDPOD() {
        const dpod = Parse.Object.extend("DPOD");
        const query = new Parse.Query(dpod);
        query.addDescending(["updatedAt", "createdAt"]);
        query.limit(1000);
        // Ref: https://community.parseplatform.org/t/i-can-not-query-all-records-in-my-class/1165
        // query.limit(24);  // for debugging
        try {
            const results = await query.find();
            // console.log("successfully retrieved " + results.length + " DPODs.");
            let retrievedDPODs = [];
            let retrievedRawDPODs = {};
            for (let i = 0; i < results.length; i++) {
                let object = results[i];
                // console.log(JSON.stringify(object));
                // retrievedDPODs.push(JSON.stringify(object));
                // let newObject = JSON.stringify(object)

                // let createdAtStringRaw = object.get("createdAt").toString()
                // let createdAtDate = new Date(Date.parse(createdAtStringRaw))
                let createdAtDate = object.get("createdAt")
                // console.log(createdAtDate);
                let createdAtString = createdAtDate.getFullYear() + '-' + (createdAtDate.getMonth() + 1) + '-' + createdAtDate.getDate() + ' ' + createdAtDate.toLocaleTimeString('en-US')

                // let did = base62.decode(object.id.toString())
                // let bid = base62.encode(did)
                let nid = DPOD.asc2dec(object.id.toString());

                let miniDPOD = {
                    nid: nid,
                    proximity: object.get("proximity"),
                    status: object.get("status"),
                    refId: object.get("refId"),
                    note: object.get("note"),
                    dLock: object.get("dLock"),
                    recipient: object.get("recipient")
                }

                let pr = null;
                if (miniDPOD.proximity) {
                    pr = <><Badge variant="warning">RTV</Badge></>;
                }
                // let sid = DPOD.dec2asc(nid)
                let action = <>
                    <Button variant="outline-primary" size="sm" onClick={() => this.displayDPoDCard(nid)}>View</Button>
                </>

                let status = miniDPOD.status;
                status = DPOD.status(status);

                let recipient = miniDPOD.recipient;
                let recipientValid = object.get("recipientValid");
                if (recipientValid === false) {
                    recipient = <><Button variant="outline-danger" size="sm" onClick={() => this.displayUpdateDPoDForm(nid)}><ErrorOutlineIcon /> {recipient}</Button></>;
                }

                retrievedDPODs.push({ action: action, did: nid, pr: pr, recipient: recipient, createdAt: createdAtString, status: status });
                
                retrievedRawDPODs[nid] = miniDPOD;
            }
            // console.log(retrievedDPODs);

            this.setState({ dpods: retrievedDPODs, rawDPODs: retrievedRawDPODs });
        } catch (error) {
            console.log('error inside listDPOD; ' + JSON.stringify(error));
            return error;
        }
    }

    async displayUpdateDPoDForm(nid) {
        // TODO: complete this with the working update e-mail address form!

        let miniDPOD = this.state.rawDPODs[nid];

        let modalUpdateDPoDForm = (
            <Modal show={() => { return this.currentActionDialog !== null }} onHide={() => this.hideUpdateDPoDForm()} backdrop="static" keyboard={false} centered size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Update 11Tag details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row>
                            <Col>
                                <UpdateDPODForm nid={nid} recipient={miniDPOD.recipient} onSubmit={(enteredData) => this.handleUpdateDPODSubmit(enteredData)} />
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>
        )

        // let modalUpdateDPoDForm = (
        //     <DPODCard 
        //         nid={nid} 
        //         dpod={this.state.rawDPODs[nid]} 
        //         handleHide={() => this.hideUpdateDPoDForm()} 
        //         dPODHistory={ [] }
        //     />
        // )
        this.setState({ currentActionDialog: modalUpdateDPoDForm });
    }

    hideUpdateDPoDForm() {
        this.setState({ currentActionDialog: null });
    }

    async displayDPoDCard(nid) {
        // console.log('clicked on ' + nid);
        // let dpodId = DPOD.dec2asc(nid);
        // console.log('... collecting info for DPOD(' + dpodId +')');

        let data = {
            di: nid,
            it: this.currentUser.get("sessionToken")
        }

        let response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/v1/track`, {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json'
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify(data)
        });
        // console.log(response);
        let result = await response.json();

        let modalDPoDCard = (
            <DPODCard 
                nid={nid} 
                dpod={this.state.rawDPODs[nid]} 
                handleHide={() => this.hideDPoDCard(nid)} 
                dPODHistory={ result['result'] }
            />
        )
        this.setState({ currentDPoDCard: modalDPoDCard });
    }

    hideDPoDCard(nid) {
        // console.log('hide dpod# ' + nid);
        // let thisDPoDCardState = this.state.showDPODCards;
        this.setState({ currentDPoDCard: '' });
    }

    getCurrentBalance() {
        return this.state.balance;
    }

    render() {
        let children;

        let thisDPODColumns = [
            { dataField: 'action', text: 'Action', headerStyle: { width: '90px' } },
            { dataField: 'did', text: '11Tag #', style: { wordWrap: 'break-word' } },
            { dataField: 'pr', text: 'Type', headerStyle: { width: '60px' } },
            // { dataField: 'did', text: 'Id' },
            // { dataField: 'bid', text: 'BId' },
            // { dataField: 'nid', text: 'NId' },
            // { dataField: 'sid', text: 'SId' },
            { dataField: 'recipient', text: 'Recipient', style: { wordWrap: 'break-word' } },
            {
                dataField: 'createdAt', text: 'Created', style: { wordWrap: 'break-word' }, sort: true,
                sortFunc: (a, b, order, dataField, rowA, rowB) => {
                    if (order === 'asc') {
                        return Date.parse(a) - Date.parse(b)
                    } else if (order === 'desc') {
                        return Date.parse(b) - Date.parse(a)
                    }
                }
            },
            { dataField: 'status', text: 'Status', style: { wordWrap: 'break-word' }, sort: true }
        ];

        // var currentUser = 
        // if (this.currentUser && this.currentUser.get("consent") === true) {
        if (!this.state.reload) {
            // let userContextUpdater = 
            // <UserContext.Consumer>
            //     {(context) => {
            //         // console.log(` ... setting the user context login state to TRUE`);
            //         context.setLoginState(true);
            //         return null;
            //     }}
            // </UserContext.Consumer>;

            // userCard = JSON.stringify(currentUser);
            children = <Container fluid>
                {/* {userContextUpdater} */}
                <Row>
                { (this.state.balance > 0) ? 
                    <>
                    <Col md="3">
                        <Button variant='primary' onClick={() => this.handleShowCreateDialog(true)}>Create a new 11Tag</Button>
                    </Col>
                    <Col md="9" className="justify-content-end">
                        <div align="right">
                            {this.state.loadingSpinner}
                            <Button variant="light" onClick={() => {window.location.href='/usage'}}>Service Credit: ${this.state.balance}</Button>
                        </div>
                    </Col>
                    </>
                    :
                    <>
                    <Col md="3">
                        <Button variant='secondary' disabled={false} onClick={() => this.handleShowCreateDialog(false)}>Create a new 11Tag</Button>
                    </Col>
                    <Col md="9" className="justify-content-end">
                        <div align="right">
                            {this.state.loadingSpinner}
                            <Button variant="warning" onClick={() => {window.location.href='/usage'}}>Service Credit: ${this.state.balance}</Button>
                        </div>
                    </Col>
                    </>
                }
                </Row>
                <Row>
                    <Col>&nbsp;</Col>
                </Row>
                {/* <Row>
          <Col><Button variant='warning' onClick={() => this.listDPOD()}>Get the list of DPODs</Button></Col>
        </Row> */}
                <Row>
                    <Col>
                        <BootstrapTable bootstrap4 keyField='did' data={this.state.dpods} defaultSorted={[{ dataField: 'createdAt', order: 'desc' }]} striped columns={thisDPODColumns} pagination={paginationFactory()} />
                    </Col>
                </Row>

                <Modal show={this.state.showCreateDialog} onHide={() => this.handleCloseCreateDialog()} backdrop="static" keyboard={false} centered size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title>Create a new Proof of Delivery</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Container>
                            <Row>
                                <Col>
                                    <CreateDPODForm value={(enteredData) => this.handleCreateDPODSubmit(enteredData)} />
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Body>
                    {/* <Modal.Footer>
            <Button variant="secondary" onClick={() => this.handleCloseCreateDialog()}>
              Cancel
          </Button>
            <Button variant="primary"  onClick={() => this.handleCloseCreateDialog()}>Understood</Button>
          </Modal.Footer> */}
                </Modal>

                {this.state.currentDPoDCard}

                {this.state.currentActionDialog}

            </Container>

        } else {
            this.props.history.push({ pathname: '/account/profile', state: { target: this.props.location } });
            children = <Container fluid>
                <Row>
                    <Col>
                        <Alert variant="primary">Loading...</Alert>
                    </Col>
                </Row>
            </Container>;
        }

        // Parse.User.currentAsync().then(function(user) {
        //   // do stuff with your user
        //   userCard = JSON.stringify(user);
        //   console.log('current parseUser is '+ userCard);
        // });

        // if (this.context.logged && this.context.user) {
        //   userCard = <UserCard user={this.context.user} logout={this.logout} />
        // } else {
        //   userCard = 'You\'re not logged in...'
        // }

        return children
    }
}

Dashboard.contextType = UserContext;
export default Dashboard;