import React, { useEffect, useState } from "react";
// import { Redirect } from 'react-router-dom';
import { Card, Alert, Image, Container, Row, Col } from 'react-bootstrap';
import { Button } from '@material-ui/core';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
// import WarningIcon from '@material-ui/icons/Warning';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
// import ThumbUpIcon from '@material-ui/icons/ThumbUp';
// import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import LockOpenIcon from '@material-ui/icons/LockOpen';

// Test with
// http://localhost:3000/promotion/ottawa/101/abcd-1234-defg-5678-0012-abab-5432
// http://localhost:3000/account/signup/ottawa/101/abcd-1234-defg-5678-0012-abab-5432

// http://localhost:3000/promotion/ottawa/101/7099019c-7fd1-454a-be3e-8e3a6f3db6a3
// http://localhost:3000/account/signup/ottawa/101/7099019c-7fd1-454a-be3e-8e3a6f3db6a3

import { colorPens } from './utils/colors';

function Promotion(props) {
    // const [state, setState] = useState({ isValidAction: false });
    const [loading, setLoading] = useState(true);
    const [validAction, setValidAction] = useState(false);
    const [resultMessage, setResultMessage] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [buttonStyle, setButtonStyle] = useState({
        fontSize: 18,
        color: colorPens.dark,
        backgroundColor: colorPens.light
    });
    const [buttonIcon, setButtonIcon] = useState(<HourglassEmptyIcon />);
    const [buttonText, setButtonText] = useState('Processing...');
    const [buttonTarget, setButtonTarget] = useState(null);
    const [isComplete, setIsComplete] = useState(false);

    const { campaign, ref1, ref2 } = props.match.params;
    // var requestedParams = props.match.params['0'].split('/');
    // let action = requestedParams[0];
    // let dpodId = requestedParams[1];
    // let reference = requestedParams[2];
    // let reference = requestedParams[3];

    // let isValidAction = false;
    // let title = '';
    // if (action === 'ConfirmDelivery') {
    //     title = 'Delivery Feedback';
    // } else {
    //     title = 'Invalid Request';
    // }

    useEffect(() => {
        if (isComplete) {
            return true;
        }

        async function processCanadaDelivery() {
            setResultMessage(<>
                <br />
                <Container fluid>
                    <Row className="justify-content-center align-items-center">
                        <Image md={5} src={process.env.PUBLIC_URL + '/images/token_link_sliced/1ScreenShots.svg'} fluid />
                        <Col md={1}>&nbsp;</Col>
                        <Image md={5} src={process.env.PUBLIC_URL + '/images/token_link_sliced/1WelcomeTitle.svg'} fluid />
                    </Row>
                    <br />
                    <br />
                    <br />
                    <br />
                    <Row className="justify-content-center align-items-center">
                        <Col md={3}>&nbsp;</Col>
                        <Col md={5}><Image src={process.env.PUBLIC_URL + '/images/token_link_sliced/2ExtraRevenueTitle.svg'} fluid />
                        </Col>
                        <Col md={3}>&nbsp;</Col>
                    </Row>
                    <br />
                    <br />
                    <Row className="justify-content-center align-items-center">
                        {/* <Col md={1}>&nbsp;</Col> */}
                        <Image md={3} src={process.env.PUBLIC_URL + '/images/token_link_sliced/2AmazingPrices.svg'} fluid />
                        <Col md={1}>&nbsp;</Col>
                        <Image md={3} src={process.env.PUBLIC_URL + '/images/token_link_sliced/2NotJustBetter.svg'} fluid />
                        <Col md={1}>&nbsp;</Col>
                        <Image md={3} src={process.env.PUBLIC_URL + '/images/token_link_sliced/2RevenueFirst.svg'} fluid />
                        {/* <Col md={1}>&nbsp;</Col> */}
                    </Row>
                    <br />
                    <br />
                    <br />
                    <Row className="justify-content-center align-items-center">
                        <Col md={3}>&nbsp;</Col>
                        <Col md={5}><Image src={process.env.PUBLIC_URL + '/images/token_link_sliced/3-SaveTimeTitle.svg'} fluid /></Col>
                        <Col md={3}>&nbsp;</Col>
                    </Row>
                    <br />
                    <br />
                    <Row className="justify-content-center align-items-center">
                        {/* <Col md={1}>&nbsp;</Col> */}
                        <Image md={3} src={process.env.PUBLIC_URL + '/images/token_link_sliced/3-SpendSeconds.svg'} fluid />
                        <Col md={1}>&nbsp;</Col>
                        <Image md={3} src={process.env.PUBLIC_URL + '/images/token_link_sliced/3-automation.svg'} fluid />
                        <Col md={1}>&nbsp;</Col>
                        <Image md={3} src={process.env.PUBLIC_URL + '/images/token_link_sliced/3-onescan.svg'} fluid />
                        {/* <Col md={1}>&nbsp;</Col> */}
                    </Row>
                    <br /><br />
                </Container>
            </>);

            // setResultMessage(<><Image src={process.env.PUBLIC_URL + '/images/token_link/initial_1.svg'} fluid />
            // <Image src={process.env.PUBLIC_URL + '/images/token_link/initial_2.svg'} fluid />
            // <Image src={process.env.PUBLIC_URL + '/images/token_link/initial_3.svg'} fluid /></>);

            setButtonStyle({ fontSize: 18, color: '#fff', backgroundColor: colorPens.main });
            setButtonIcon(<LockOpenIcon />);
            setButtonText('Sign up for the trial (NO credit card required)');
            setButtonTarget("/account/signup/" + campaign + "/" + ref1 + "/" + ref2);

            setValidAction(true);
            setLoading(false);

            // setIsComplete(true);
        }

        try {
            if (campaign === 'canada-delivery' && ref1 !== undefined && ref2 !== undefined) {
                processCanadaDelivery();
            } else {
                // Invalid requests
                setValidAction(false);
                setLoading(false);
            }
        } catch (error) {
            // Something went wrong
            setValidAction(false);
            setLoading(false);
        }

        return function cleanup() {
            // 
        };
    }, []); // Runs this only ONCE
    // }, [props, action, id, ref1, ref2]); // Runs once at the initial, and at every rendering WHEN `prop` or `state` changes

    if (loading) {
        return (
            <div>
                {/* <h3><img alt="11Klear" src={process.env.PUBLIC_URL + '/images/11Klear-Logo-192.png'} style={{ height: 36 }} />11Klear</h3> */}
                <Card border="secondary" bg="light" className="text-center">
                    <Card.Header as="h5">
                        <img alt="11Klear" src={process.env.PUBLIC_URL + '/images/11Klear-Logo-192.png'} style={{ height: 32 }} /> <i>Invitation-only Special Offer for {campaign.toUpperCase()}</i>
                    </Card.Header>
                    <Card.Body>
                        <Button
                            disabled={true}
                            // color={colorPens.secondary} 
                            variant="outlined"
                            // size="small"
                            // labelStyle={{ fontSize: 28 }} 
                            style={buttonStyle}
                            startIcon={buttonIcon}
                        // onClick={() => { setLoading(false); }}
                        >{buttonText}</Button>
                    </Card.Body>
                </Card>
                {/* <h3>External Link:{JSON.stringify(props)}</h3> */}
            </div>
        );
    } else if (validAction) {
        return (
            <div>
                {/* <h3><img alt="11Klear" src={process.env.PUBLIC_URL + '/images/11Klear-Logo-192.png'} style={{ height: 36 }} />11Klear</h3> */}
                <Card border="primary" bg="light" className="text-center">
                    <Card.Header as="h5">
                        <img alt="11Klear" src={process.env.PUBLIC_URL + '/images/11Klear-Logo-192.png'} style={{ height: 32 }} /> <i>Invitation-only Special Offer for {campaign.toUpperCase()}</i>
                    </Card.Header>
                    <Card.Body style={{ backgroundColor: '#fff' }}>
                        {resultMessage ?
                            // <Alert variant="info">
                            <> {resultMessage}</>
                            // </Alert>
                            :
                            null}
                        {errorMessage ?
                            <Alert variant="danger">
                                {errorMessage}
                            </Alert>
                            :
                            null}
                        <Button
                            // color={colorPens.secondary} 
                            disabled={buttonTarget === null}
                            variant="outlined"
                            // size="small"
                            // labelStyle={{ fontSize: 28 }} 
                            style={buttonStyle}
                            startIcon={buttonIcon}
                            onClick={() => { window.location.href = buttonTarget }}
                        // onClick={() => { setButtonStyle({ fontSize: 18, color: colorPens.dark, backgroundColor: colorPens.light }); setValidAction(false); setLoading(true); }}
                        >{buttonText}</Button>
                        <br /><br />
                    </Card.Body>
                </Card>
                {/* <h3>External Link:{JSON.stringify(props)}</h3> */}
            </div>
        );
    } else {
        // return <Redirect to={"/"} />;
        return (
            <div>
                <Card border="danger" bg="light" text={'black'} className="text-center">
                    <Card.Header as="h5">
                        <img alt="11Klear" src={process.env.PUBLIC_URL + '/images/11Klear-Logo-192.png'} style={{ height: 32 }} /> <i>Limited Campaign for {campaign.toUpperCase()}</i>
                    </Card.Header>
                    <Card.Body>
                        <h5>Error-501: <i>Invalid Campaign</i></h5>
                        <div align="left" width="80%">
                            <h6>[VA] <i>{campaign}</i></h6>
                            <h6>[VB] <i>{ref1}</i></h6>
                            <h6>[VC] <i>{ref2}</i></h6>
                        </div>
                        <br />
                        <Button
                            // color={colorPens.danger} 
                            disabled={true}
                            variant="contained"
                            style={{ fontSize: 18, color: '#fff', backgroundColor: '#dc3545' }}
                            // labelStyle={{ fontSize: 18 }} 
                            startIcon={<ReportProblemOutlinedIcon />}
                        // onClick={() => { setButtonStyle({ fontSize: 18, color: '#fff', backgroundColor: colorPens.success }); setValidAction(true); }}
                        >Oops!</Button>

                    </Card.Body>
                </Card>
                {/* <h3>External Link:{JSON.stringify(props)}</h3> */}
            </div>
        );
    }
}

export default Promotion;