// import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react';

// import COLORS from '../utils/colors'
// import { card as cardStyle } from '../utils/styles'

import { Formik } from "formik";
import * as yup from "yup";
import { Form, Button, Row, Col, Alert } from 'react-bootstrap';

import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CheckIcon from '@material-ui/icons/Check';
import PhoneIcon from '@material-ui/icons/Phone';

import { colorPens } from '../utils/colors';

const schema = yup.object({
    username: yup
        .string()
        .email("Username must be a valid email address")
        .required("E-mail address is a required field"),
    confirmationCode: yup
        .string()
        .matches(/^[0-9]+$/, "Must be only digits")
        .min(6, "Must be 6 or more characters")
        .max(12, "Must be 12 or less characters")
        .required("Please enter the verification code"),
})

const ConfirmSignUpForm = (props) => {
    let { campaignIdentifier, wipFormData } = props;

    return (
        <Formik
            validationSchema={schema}
            onSubmit={(values, actions) => {
                props.onSubmit(values);
            }}
            initialValues={{
                username: wipFormData.username !== undefined ? wipFormData.username : '',
                confirmationCode: ''
            }}>
            {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                isValid,
                errors,
            }) => (
                <Row className="justify-content-center">
                    <Col md={8} style={{ backgroundColor: colorPens.light, border: '1px solid ' + colorPens.main, borderRadius: '5px' }}>
                        <Form noValidate onSubmit={handleSubmit}>
                            <Row className="justify-content-center">
                                <Col md={11}>
                                    <br />
                                    <h4>New Account Registration</h4>
                                </Col>
                            </Row>
                            <Row className="justify-content-center">
                                <Col md={11}>
                                    <h5>Step 2/2 - Please confirm your e-mail address using the verification code we just sent to you.</h5><br />
                                </Col>
                            </Row>
                            <Form.Row className="justify-content-center">
                                <Form.Group as={Col} md="11">
                                    <Form.Label>Invitation Reference</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="campaign_identifier"
                                        value={campaignIdentifier}
                                        readOnly
                                    />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row className="justify-content-center">
                                <Form.Group as={Col} md="11" controlId="validationFormik101">
                                    <Form.Label>E-mail address</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="username"
                                        value={values.username}
                                        onChange={handleChange}
                                        isValid={touched.username && !errors.username}
                                        isInvalid={touched.username && errors.username}
                                        placeholder="Enter your email address"
                                        readOnly
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.username}</Form.Control.Feedback>
                                </Form.Group>
                            </Form.Row>
                            <Form.Row className="justify-content-center">
                                <Form.Group as={Col} md="11" controlId="validationFormik101">
                                    <Form.Label>Verification code *</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="confirmationCode"
                                        value={values.confirmationCode}
                                        onChange={handleChange}
                                        isValid={touched.confirmationCode && !errors.confirmationCode}
                                        isInvalid={touched.confirmationCode && errors.confirmationCode}
                                        placeholder="Enter the verification code"
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.confirmationCode}</Form.Control.Feedback>
                                </Form.Group>
                            </Form.Row>
                            <br />
                            <Row className="justify-content-center">
                                <Col md={8}>&nbsp;</Col>
                                <Col md={2}><Button variant="primary" type="submit">Confirm</Button></Col>
                            </Row>
                            <br />
                        </Form>

                    </Col>
                </Row>
            )}
        </Formik>
    );
}

export default ConfirmSignUpForm;