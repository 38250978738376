import React, { Component } from "react";
import { Container, Row, Col, Button, Spinner } from "react-bootstrap";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import * as DPOD from './components/DPOD.js';

var Parse = require('parse');

class Usage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            balance: 0,
            transactions: [],
            loadingSpinner: <>
                <Spinner animation="border" variant="secondary" size="sm" role="status">
                    <span className="sr-only">Loading...</span>
                </Spinner> </>,
            demoUser: true
        }
    }

    async retrieveBalance() {
        // Update the displayed balance
        let kontostand = Parse.Object.extend("Kontostand");
        let queryBalance = new Parse.Query(kontostand);
        queryBalance.descending("createdAt");
        queryBalance.notEqualTo("balance", null);

        try {
            let balanceResult = await queryBalance.first();
            let balance = balanceResult.get("balance");
            if (balance === undefined) {
                balance = 0;
            }
            this.setState({ balance: balance, loadingSpinner: null });
        } catch (error) {
            console.log('error while retrieveing the balance; '+ JSON.stringify(error));
            this.setState({ balance: "NA" });
        }
    }

    async retrieveTransactions() {
        // TODO: fully implement this feature
        // Update the current balance

        let kontostand = Parse.Object.extend("Kontostand");
        let queryBalance = new Parse.Query(kontostand);
        queryBalance.notEqualTo("hidden", true);
        queryBalance.limit(100);

        try {
            // let endBalance = 0;
            let retrievedKontostands = [];

            let results = await queryBalance.find();
            for (let i=0; i < results.length; i++) {
                let object = results[i];
                // endBalance += object.get("amount");

                // console.log(` +++ processing #${i} +++`);

                let createdAtDate = object.get("createdAt");
                let createdAtString = createdAtDate.getFullYear() + '-' + (createdAtDate.getMonth() + 1) + '-' + createdAtDate.getDate() + ' ' + createdAtDate.toLocaleTimeString('en-US');

                let miniKontostand = {
                    tid: `pt_${DPOD.asc2dec(object.id)}`,
                    createdAt: createdAtString,
                    amount: object.get("amount"),
                    note: object.get("note"),
                }
                // did: object.get("dpod"),
                let did = object.get("dpod");
                if (did === undefined) {
                    miniKontostand['nid'] = 'NA';
                } else {
                    miniKontostand['nid'] = DPOD.asc2dec(did.id.toString());
                }
                // console.log(` ... retrievedMiniKontostand= ${JSON.stringify(miniKontostand)}`);
                retrievedKontostands.push(miniKontostand);
            }
            // this.setState({ balance: endBalance });
            // let currentBalance = await this.retrieveBalance();
            // console.log(`currentBalance=${currentBalance}`);

            // this.setState({ balance: currentBalance, transactions: retrievedKontostands });
            this.setState({ transactions: retrievedKontostands });
        } catch (error) {
            console.log('error while retrieveing the balance; '+ JSON.stringify(error));
            if (error.code === 209) {
                await Parse.User.logOut();
            }
            this.setState({ balance: "NA" })
        }
    }

    async componentDidMount() {
        let currentUser = Parse.User.current();
        if (currentUser != null) {
            await this.retrieveTransactions();
            await this.retrieveBalance();

            let demoUsers = [
                // This will include demo user (App testers) emails so that 'Add Funds' will be disabled
                // 'billsshin+test@gmail.com'
                'elvademo.apple@11klear.com',
                'elvademo.google@11klear.com'
            ]
            if (!demoUsers.includes(currentUser.getEmail())) {
                this.setState({ demoUser: false });
            }
        } else {
            window.location.href = '/';
        }
    }

    render() {
        let thisTransactionColumns = [
            // { dataField: 'action', text: 'Action', headerStyle: { width: '90px' } },
            // { dataField: 'pr', text: 'Type', headerStyle: { width: '60px' } },
            // { dataField: 'bid', text: 'BId' },
            // { dataField: 'nid', text: 'NId' },
            // { dataField: 'sid', text: 'SId' },
            // { dataField: 'recipient', text: 'Recipient', style: { wordWrap: 'break-word' } },
            { dataField: 'tid', text: 'Transaction ID', headerStyle: { width: 'auto' } },
            {
                dataField: 'createdAt', text: 'Created', style: { wordWrap: 'break-word' }, sort: true,
                sortFunc: (a, b, order, dataField, rowA, rowB) => {
                    if (order === 'asc') {
                        return Date.parse(a) - Date.parse(b)
                    } else if (order === 'desc') {
                        return Date.parse(b) - Date.parse(a)
                    }
                }
            },
            { dataField: 'nid', text: '11Tag #', style: { wordWrap: 'break-word' } },
            { dataField: 'amount', text: 'Amount', headerStyle: { width: '120px' }, style: { wordWrap: 'break-word' } },
            { dataField: 'note', text: 'Note', style: { wordWrap: 'break-word' } }
            // { dataField: 'status', text: 'Status', style: { wordWrap: 'break-word' }, sort: true }
        ];

        return (
            <Container fluid>
                <Row>
                    <Col md={3} align="right">
                        <h4>Service Credit: ${this.state.balance}</h4> {this.state.loadingSpinner}
                    </Col>
                    <Col md={7} align="left">
                        { !this.state.demoUser ?
                            <Button variant="outline-primary" size="sm" onClick={() => { window.location.href = '/payment'; }}>Add Funds</Button>
                            :
                            <></>
                        }
                    </Col>
                    <Col md={2} align="right">
                        <Button variant="outline-primary" size="sm" onClick={() => { window.location.href = '/dashboard'; }}>Back to Dashboard</Button>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} align="right">
                        <i>*Latest 100 records</i>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <BootstrapTable bootstrap4 keyField='tid' data={this.state.transactions} defaultSorted={[{ dataField: 'createdAt', order: 'desc' }]} striped columns={thisTransactionColumns} pagination={paginationFactory()} />
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default Usage;